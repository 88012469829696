import { Box, Button, Popover, PopoverProps, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import varified from 'src/Assets/Icons/verified.svg'
// import { notificationsData } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import styles from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ViewNotificationList } from 'src/Redux/Slices/notificationListSlice'
import { setApplicationId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'

interface NotificationPopupProps {
  anchor: React.MutableRefObject<undefined>
  open: boolean
  onClose: () => void
}

const NotificationPopup: React.FunctionComponent<NotificationPopupProps> = ({
  anchor,
  open,
  onClose,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const redirectToNotification = () => {
    navigate(ROUTES.NOTIFICATION)
    onClose()
  }

  const popoverProps: PopoverProps = {
    open,
    onClose,
    anchorEl: anchor.current,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    slotProps: {
      paper: {
        className: styles.paper,
      },
    },
  }

  // const length = 8
  useEffect(() => {
    dispatch(ViewNotificationList())
  }, [])

  const notificationList = useAppSelector(
    (state) => state?.notificationList?.data?.notificationList,
  )

  const convertDate = (dateString: string) => {
    const dateObj = new Date(dateString)
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return dateObj.toLocaleDateString('en-US', options)
  }

  const getPadding = (count: number) => {
    if (count > 1000) {
      return '15px 25px'
    } else if (count > 100) {
      return '15px 20px'
    } else if (count > 10) {
      return '15px 15px'
    } else if (count > 0) {
      return '4px 4px'
    }
    return '0'
  }

  const redirectToAplication = (notification: any) => {
    if (notification.applicationId) {
      dispatch(setApplicationId(notification.applicationId))
      navigate(ROUTES.APPLICATION_DETAIL)
    }
    onClose()
  }

  return (
    <Popover {...popoverProps}>
      <Box
        className={styles.notificationPopup}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Box className={styles.title}>
          <Typography className={styles.textWrapper}>Notification</Typography>
          <Box
            className={styles.notificationCount}
            sx={{ padding: getPadding(notificationList?.length) }}
          >
            <Typography className={styles.div}>
              {notificationList?.length}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.divider} />
        {notificationList?.length ? (
          notificationList
            ?.slice(0, 8)
            ?.map((notification: any, index: any) => {
              return (
                <>
                  <Box
                    className={styles.notification}
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation()
                      redirectToAplication(notification)
                    }}
                  >
                    <Box className={styles.icon}>
                      <img src={varified} alt='message' />
                    </Box>
                    <Box className={styles.notiContent}>
                      <Box className={styles.head}>
                        <Typography className={styles.p}>
                          {notification?.title}
                        </Typography>
                        <Typography className={styles.date}>
                          {convertDate(notification?.createdAt)}
                        </Typography>
                      </Box>
                      <Typography className={styles.subTitle}>
                        {notification?.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={styles.divider} />
                </>
              )
            })
        ) : (
          <Typography sx={{ textAlign: 'center', my: 2 }}>
            No notifications available.
          </Typography>
        )}
        <Box className={styles.buttonWrapper}>
          <Button className={styles.button} onClick={redirectToNotification}>
            View All Notifications
          </Button>
        </Box>
      </Box>
    </Popover>
  )
}

export default NotificationPopup
