/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  styled,
  TableCellProps,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material'
import Styles from './tabs.module.scss'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import { useEffect, useRef, useState } from 'react'
import Delete from 'src/Assets/Icons/delete.svg'
import Edit from 'src/Assets/Icons/edit.svg'
import TextInput from 'src/Components/Application/CustomInputs/TextInput'
import Style from '../../Add/AddOrganizationSteps/stepper.module.scss'

import {
  AddOrganizationContactInfo,
  EditOrganizationOverview,
  SetSelectedOrganization,
  SetSelectedOrganizationValue,
  getOrganizationOverview,
} from 'src/Redux/Slices/orgSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { OrganizationListObj } from 'src/Redux/Services/Types/orgType'

interface StyledTableCellProps extends TableCellProps {
  required?: boolean // Optional boolean prop to control asterisk sign visibility
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({ variant, required }) => ({
    [`&.${tableCellClasses.root}`]: {
      fontSize: '16px',
      position: 'relative',
      color: '#252F4A',
      // Ensure relative positioning for pseudo-element
      // Add additional styles based on the variant prop
      ...(variant === 'head' && {
        color: '#99A1B7',
        // Apply styles specific to the 'head' variant
        width: '30%',
      }),
      // Define styles for the pseudo-element
      '&::after': {
        content: variant === 'head' && required ? '" *"' : '""', // Show star after text for head variant
        position: 'absolute',
        color: 'red', // Adjust color as needed
      },
    },
  }),
)

const Overview = () => {
  const [editMode, setEditMode] = useState(true)
  const dispatch = useAppDispatch()
  const { selectedOrganization } = useAppSelector((state) => state.organization)
  const [overviewDetail, setOverviewDetail] = useState({
    bussinessName: '',
    bussinessDescription: '',
    bussinessStreetAddress: '',
    bussinessCountry: '',
    bussinessCity: '',
    bussinessState: '',
    bussinessPostalCode: '',
    bussinessType: '',
    bussinessSpecialization: '',
    bussinessTrackingName: '',
    bussinessProfileUrl: null,
    bussinessPhoneNumber: '',
    bussinessSecondaryPhoneNumber: '',
    bussinessSecondaryEmailId: '',
    bussinessEmailId: '',
  })
  const [errors, setErrors] = useState({
    bussinessName: '',
    bussinessDescription: '',
    bussinessStreetAddress: '',
    bussinessCountry: '',
    bussinessCity: '',
    bussinessState: '',
    bussinessPostalCode: '',
    bussinessType: '',
    bussinessSpecialization: '',
    bussinessTrackingName: '',
    bussinessProfileUrl: '',
    bussinessPhoneNumber: '',
    bussinessSecondaryPhoneNumber: '',
    bussinessSecondaryEmailId: '',
    bussinessEmailId: '',
  })

  const [removeProfileURL, setRemoveProfileURL] = useState(false)
  const [selectedFile, setSelectedFile] = useState(
    selectedOrganization?.bussinessProfileUrl || null,
  )
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    selectedOrganization?.bussinessProfileUrl || null,
  )

  const itemStyle = {
    xs: 12,
    sm: 12,
    md: 12,
  }

  const marginStyle = {
    mr: { md: 2, xs: 2 },
  }
  const initialObj = {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  }
  interface Place {
    id: string
    description: string
  }
  const inputRef = useRef()
  const fileInputRef = useRef(null) // Reference to the input element

  useEffect(() => {
    if (!selectedOrganization?.bussinessProfileUrl?.length) {
      setPreviewUrl(null)
    }
  }, [selectedOrganization.bussinessProfileUrl])

  const [formValues, setFormValues] = useState(initialObj)
  const [predictions, setPredictions] = useState<Place[]>([])
  const [disableButton, setdisableButton] = useState<Boolean>(true)

  useEffect(() => {
    if (selectedOrganization?.uuid) {
      setOverviewDetail({
        bussinessName: selectedOrganization.bussinessName || '',
        bussinessDescription: selectedOrganization.bussinessDescription || '',
        bussinessStreetAddress:
          selectedOrganization.bussinessStreetAddress || '',
        bussinessCountry: selectedOrganization.bussinessCountry || '',
        bussinessCity: selectedOrganization.bussinessCity || '',
        bussinessState: selectedOrganization.bussinessState || '',
        bussinessPostalCode: selectedOrganization.bussinessPostalCode || '',
        bussinessType: selectedOrganization.bussinessType || '',
        bussinessSpecialization:
          selectedOrganization.bussinessSpecialization || '',
        bussinessTrackingName: selectedOrganization.bussinessTrackingName || '',
        bussinessProfileUrl: selectedOrganization.bussinessProfileUrl || '',
        bussinessPhoneNumber: selectedOrganization.bussinessPhoneNumber || '',
        bussinessSecondaryPhoneNumber:
          selectedOrganization.bussinessSecondaryPhoneNumber || '',
        bussinessSecondaryEmailId:
          selectedOrganization.bussinessSecondaryEmailId || '',
        bussinessEmailId: selectedOrganization.bussinessEmailId || '',
      })
      setFormValues({
        ...formValues,
        streetAddress: selectedOrganization.bussinessStreetAddress || '',
      })
      dispatch(getOrganizationOverview(selectedOrganization?.uuid))
    }
  }, [])
  useEffect(() => {
    setOverviewDetail({
      bussinessName: selectedOrganization.bussinessName || '',
      bussinessDescription: selectedOrganization.bussinessDescription || '',
      bussinessStreetAddress: selectedOrganization.bussinessStreetAddress || '',
      bussinessCountry: selectedOrganization.bussinessCountry || '',
      bussinessCity: selectedOrganization.bussinessCity || '',
      bussinessState: selectedOrganization.bussinessState || '',
      bussinessPostalCode: selectedOrganization.bussinessPostalCode || '',
      bussinessType: selectedOrganization.bussinessType || '',
      bussinessSpecialization:
        selectedOrganization.bussinessSpecialization || '',
      bussinessTrackingName: selectedOrganization.bussinessTrackingName || '',
      bussinessProfileUrl: selectedOrganization.bussinessProfileUrl || '',
      bussinessPhoneNumber: selectedOrganization.bussinessPhoneNumber || '',
      bussinessSecondaryPhoneNumber:
        selectedOrganization.bussinessSecondaryPhoneNumber || '',
      bussinessSecondaryEmailId:
        selectedOrganization.bussinessSecondaryEmailId || '',
      bussinessEmailId: selectedOrganization.bussinessEmailId || '',
    })
    setFormValues({
      ...formValues,
      streetAddress: selectedOrganization.bussinessStreetAddress || '',
    })
  }, [selectedOrganization])

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdisableButton(false)
    const file = event.target.files[0]
    if (file) {
      setRemoveProfileURL(false)
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // Function to handle removing the selected photo
  const handleRemovePhoto = () => {
    setdisableButton(true)
    setSelectedFile(null)
    setPreviewUrl(null)
    setRemoveProfileURL(true)
  }

  const handleValidation = () => {
    const errorsObj = { ...errors }

    // Validating business name, description, street address, state, type, specialization, and tracking name
    errorsObj.bussinessName = overviewDetail?.bussinessName
      ? ''
      : 'Business name is required'
    errorsObj.bussinessDescription = overviewDetail?.bussinessDescription
      ? ''
      : 'Business description is required'
    errorsObj.bussinessStreetAddress = overviewDetail?.bussinessStreetAddress
      ? ''
      : 'Business street address is required'
    errorsObj.bussinessState = overviewDetail?.bussinessState
      ? ''
      : 'Business state is required'
    errorsObj.bussinessCountry = overviewDetail?.bussinessCountry
      ? ''
      : 'Business conutry is required'
    errorsObj.bussinessType = overviewDetail?.bussinessType
      ? ''
      : 'Business type is required'
    errorsObj.bussinessSpecialization = overviewDetail?.bussinessSpecialization
      ? ''
      : 'Business specialization is required'
    // errorsObj.bussinessTrackingName = overviewDetail?.bussinessTrackingName
    //   ? ''
    //   : 'Business tracking name is required'

    errorsObj.bussinessEmailId = overviewDetail?.bussinessEmailId
      ? ''
      : 'Business Admin email Id is required'

    // Validating business postal code
    if (!overviewDetail?.bussinessPostalCode) {
      errorsObj.bussinessPostalCode = 'Postal code is required'
    }
    // else if (
    //   !/^\d{6}(?:[-\s]\d{4})?$/.test(overviewDetail?.bussinessPostalCode)
    // ) {
    //   errorsObj.bussinessPostalCode = 'Invalid postal code'
    // }
    else {
      errorsObj.bussinessPostalCode = ''
    }
    // Validating business email IDs

    if (!overviewDetail?.bussinessSecondaryEmailId) {
      errorsObj.bussinessSecondaryEmailId = 'Email ID is required'
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        overviewDetail?.bussinessSecondaryEmailId,
      )
    ) {
      errorsObj.bussinessSecondaryEmailId = 'Invalid email ID'
    } else {
      errorsObj.bussinessSecondaryEmailId = ''
    }

    // Validating business Phone numbers
    if (!overviewDetail?.bussinessPhoneNumber) {
      errorsObj.bussinessPhoneNumber = 'Phone number is required'
    } else if (!/^\d{10}$/.test(overviewDetail?.bussinessPhoneNumber)) {
      errorsObj.bussinessPhoneNumber = 'Invalid Phone number'
    } else {
      errorsObj.bussinessPhoneNumber = ''
    }

    if (!overviewDetail?.bussinessSecondaryPhoneNumber) {
      errorsObj.bussinessSecondaryPhoneNumber = 'Phone number is required'
    } else if (
      !/^\d{10}$/.test(overviewDetail?.bussinessSecondaryPhoneNumber)
    ) {
      errorsObj.bussinessSecondaryPhoneNumber = 'Invalid Phone number'
    } else {
      errorsObj.bussinessSecondaryPhoneNumber = ''
    }
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      errorsObj.bussinessProfileUrl = 'Please select a file under 5MB in size'
    } else {
      errorsObj.bussinessProfileUrl = ''
    }

    setErrors(errorsObj)
    return errorsObj
  }

  const handleEditToggle = () => {
    setdisableButton(true)
    setEditMode(!editMode)
  }

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  function fetchPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div'),
    )
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // Access address components
        const addressComponents = place.address_components

        if (addressComponents && addressComponents.length > 0) {
          if (place?.address_components) {
            const addressComponents = place.address_components
            const updatedFormValues = { ...overviewDetail }

            updatedFormValues.bussinessStreetAddress = ''
            addressComponents.forEach(
              (component: { types; long_name: string }) => {
                const types = component.types
                if (types.includes('street_number')) {
                  updatedFormValues.bussinessStreetAddress = component.long_name
                } else if (
                  types.includes('route') ||
                  types.includes('sublocality')
                ) {
                  updatedFormValues.bussinessStreetAddress += ` ${component.long_name}`
                } else if (types.includes('locality')) {
                  updatedFormValues.bussinessCity = component.long_name
                } else if (types.includes('administrative_area_level_1')) {
                  updatedFormValues.bussinessState = component.long_name
                } else if (types.includes('postal_code')) {
                  updatedFormValues.bussinessPostalCode = component.long_name
                } else if (types.includes('country')) {
                  updatedFormValues.bussinessCountry = component.long_name
                }
              },
            )

            setFormValues({
              ...formValues,
              streetAddress: updatedFormValues.bussinessStreetAddress,
            })
            setOverviewDetail(updatedFormValues)
            if (overviewDetail.bussinessStreetAddress.length) {
              setPredictions([])
            }
          } else {
            console.error('Invalid place or missing address components')
          }
        }
      }
    })
  }

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      setFormValues({
        ...formValues,
        [name]: value,
      })
      autocomplete(value)
    } else {
      setFormValues(initialObj)
      setPredictions([])
    }
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(prediction.id)
    // You can do additional things here, like setting the selected prediction as the input value
  }
  useEffect(() => {
    if (overviewDetail?.bussinessStreetAddress?.length) {
      setPredictions([])
    }
    const obj = {
      bussinessStreetAddress: formValues.streetAddress,
      bussinessCity: formValues.city,
      bussinessCountry: formValues.country,
      bussinessState: formValues.state,
      bussinessPostalCode: formValues.zipCode,
    }
    // dispatch(SetSelectedOrganization(obj))
  }, [formValues])

  useEffect(() => {}, [overviewDetail])

  const areAllErrorsEmpty = (formErrors: {
    [x: string]: string
    bussinessName: string
    bussinessDescription: string
    bussinessStreetAddress: string
    bussinessCountry: string
    bussinessCity: string
    bussinessState: string
    bussinessPostalCode: string
    bussinessType: string
    bussinessSpecialization: string
    bussinessTrackingName: string
    bussinessProfileUrl: string
    bussinessPhoneNumber: string
    bussinessSecondaryPhoneNumber: string
    bussinessSecondaryEmailId: string
    bussinessEmailId: string
  }) => {
    for (const key in formErrors) {
      if (
        Object.prototype.hasOwnProperty.call(formErrors, key) &&
        formErrors[key] !== ''
      ) {
        return false // If any key has a non-empty value, return false
      }
    }
    return true // If all keys have empty values, return true
  }
  const handleSubmit = () => {
    const formErrors = handleValidation()
    const allErrorsEmpty = areAllErrorsEmpty(formErrors)
    dispatch(SetSelectedOrganization(overviewDetail))

    setdisableButton(true)

    if (allErrorsEmpty) {
      if (selectedFile) {
        const obj = {
          ...overviewDetail,
          bussinessProfileUrl: selectedFile,
        }
        dispatch(SetSelectedOrganization(obj))
      }

      const obj = {
        ...overviewDetail,
        bussinessProfileUrl: selectedFile,
        removeProfile: removeProfileURL,
      }
      dispatch(
        EditOrganizationOverview({
          ...obj,
          uuid: selectedOrganization.uuid,
        }),
      )

      handleEditToggle()
      dispatch(getOrganizationOverview(selectedOrganization?.uuid))
    }
  }
  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setdisableButton(false)
    const { id, value } = event.target
    // const key = id
    setOverviewDetail({ ...overviewDetail, [id]: value })
    // dispatch(
    //   SetSelectedOrganizationValue({
    //     key,
    //     value,
    //   }),
    // )
  }

  const handleImageClick = () => {
    if (fileInputRef?.current) fileInputRef.current?.click()
  }
  useEffect(() => {
    if (!selectedOrganization?.bussinessProfileUrl?.length) {
      setPreviewUrl(null)
    } else {
      setPreviewUrl(selectedOrganization.bussinessProfileUrl)
    }
  }, [selectedOrganization.bussinessProfileUrl])

  return (
    <>
      <>
        {editMode ? (
          <>
            <Grid container m={2}>
              <Grid lg={10} sx={{ alignItems: 'center', display: 'flex' }}>
                <Typography
                  variant='h1'
                  sx={{ fontWeight: 'bold', color: '#252F4A' }}
                >
                  Overview
                </Typography>
              </Grid>
              <Grid lg={2} sx={{ float: 'right' }}>
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#6f737a',
                    padding: '6px 15px',
                    fontSize: '16px',
                    lineHeight: 1.5,
                  }}
                >
                  <Button
                    className={Styles.EditBtnStyle}
                    onClick={handleEditToggle}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
              <Divider sx={{ width: '97%' }} />
            </Grid>
            <Grid container m={2}>
              <TableContainer>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                    },
                    //   width: '50%',
                  }}
                >
                  <TableRow>
                    <StyledTableCell variant='head'>
                      Business Name
                    </StyledTableCell>
                    <StyledTableCell>
                      {selectedOrganization?.bussinessName}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Business Phone number
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessPhoneNumber}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Street Address
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessStreetAddress}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Country
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessCountry}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      State
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessState}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Zip Code
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessPostalCode}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Organization Description
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessDescription}
                    </StyledTableCell>
                  </TableRow>
                  {/* <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Tracking Name
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessTrackingName}
                    </StyledTableCell>
                  </TableRow> */}
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Type
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessType}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Specialization
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessSpecialization}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Contact Number
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessSecondaryPhoneNumber}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      E- mail Address of Admin
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessEmailId}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      className={Styles.grayFonts}
                    >
                      Alternate Email Address
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      {selectedOrganization?.bussinessSecondaryEmailId}
                    </StyledTableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid>
          </>
        ) : (
          <>
            <Grid m={2}>
              <Grid container m={2}>
                <Grid lg={10} className={Styles.alignItemCenter} display='flex'>
                  <Typography variant='h1' className={Styles.FontBold}>
                    Overview
                  </Typography>
                </Grid>
                <Divider
                  sx={{ width: '95%', marginTop: '8px', marginBottom: '8px' }}
                />
              </Grid>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                      },
                      //   width: '50%',
                    }}
                  >
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Logo
                      </StyledTableCell>
                      <Box component='div' className={Styles.Element} p={2}>
                        <Box component='div' className={Styles.Ans}>
                          <Box
                            component='div'
                            className={Styles.EditContactImage}
                          >
                            <Box
                              component='div'
                              className={Styles.EditContactLogo}
                            >
                              <Box
                                component='div'
                                className={Styles.overlapGroup}
                              >
                                <img
                                  className={Styles.Rectangle}
                                  src={previewUrl ?? ProfilePhoto}
                                  alt='Rectangle'
                                />
                                <Box
                                  component='div'
                                  className={Styles.IconEditWrapper}
                                >
                                  <input
                                    onChange={handleFileSelect}
                                    id='profile-photo-upload'
                                    type='file'
                                    ref={fileInputRef}
                                    accept='image/jpeg, image/png'
                                    className={Styles.inputFile}
                                  />
                                  <img
                                    src={Edit}
                                    className={Styles.EditIcon}
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleImageClick}
                                    alt='Rectangle'
                                  />
                                </Box>
                                <Box
                                  component='div'
                                  className={Styles.IconDeleteWrapper}
                                >
                                  <img
                                    src={Delete}
                                    onClick={handleRemovePhoto}
                                    alt='Rectangle'
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box component='div' className={Styles.Description}>
                              <Typography className={Styles.DescriptionP}>
                                Allowed file types: png, jpg, jpeg.
                              </Typography>
                            </Box>
                            <FormHelperText
                              style={{ padding: '8px', fontSize: '14px' }}
                            >
                              {errors.bussinessProfileUrl}
                            </FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Business Name
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          placeholder={'Business Name'}
                          id='bussinessName'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessName}
                          error={!!errors.bussinessName}
                          helperText={
                            errors.bussinessName ? errors.bussinessName : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Business Phone number
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessPhoneNumber'
                          onChange={handleTextChange}
                          inputProps={{ maxLength: 10 }} // Set maximum length
                          value={overviewDetail?.bussinessPhoneNumber}
                          placeholder={'Business Phone number'}
                          error={!!errors.bussinessPhoneNumber}
                          helperText={
                            errors.bussinessPhoneNumber
                              ? errors.bussinessPhoneNumber
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Street Address
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          name='streetAddress'
                          id='bussinessStreetAddress'
                          value={formValues?.streetAddress}
                          onChange={handleAddressChange}
                          // value={overviewDetail?.bussinessStreetAddress}
                          placeholder={'Street Address'}
                          inputRef={inputRef}
                          error={!!errors.bussinessStreetAddress}
                          helperText={
                            errors.bussinessStreetAddress
                              ? errors.bussinessStreetAddress
                              : ''
                          }
                        />

                        {predictions.length ? (
                          <Grid item md={12} xs={12} className='form-row-item'>
                            <Grid
                              item
                              {...itemStyle}
                              {...marginStyle}
                              className={Style.listBox}
                            >
                              {predictions.map((prediction, index) => (
                                <>
                                  <div
                                    key={index}
                                    className={Style.listItem}
                                    onClick={() => {
                                      handlePredictionClick(prediction)
                                    }}
                                  >
                                    {prediction.description}
                                  </div>
                                  <Divider />
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Country
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessCountry'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessCountry}
                          placeholder={'Country'}
                          error={!!errors.bussinessCountry}
                          helperText={
                            errors.bussinessCountry
                              ? errors.bussinessCountry
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        State
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessState'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessState}
                          placeholder={'State'}
                          error={!!errors.bussinessState}
                          helperText={
                            errors.bussinessState ? errors.bussinessState : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Zip Code
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessPostalCode'
                          inputProps={{ maxLength: 6 }} // Set maximum length
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessPostalCode}
                          placeholder={'Zip Code'}
                          error={!!errors.bussinessPostalCode}
                          helperText={
                            errors.bussinessPostalCode
                              ? errors.bussinessPostalCode
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Organization Description
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessDescription'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessDescription}
                          placeholder={'Organization Description'}
                          error={!!errors.bussinessDescription}
                          helperText={
                            errors.bussinessDescription
                              ? errors.bussinessDescription
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    {/* <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Tracking Name
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessTrackingName'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessTrackingName}
                          placeholder={'Tracking Name'}
                          error={!!errors.bussinessTrackingName}
                          helperText={
                            errors.bussinessTrackingName
                              ? errors.bussinessTrackingName
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow> */}
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Type
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessType'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessType}
                          placeholder={'Type'}
                          error={!!errors.bussinessType}
                          helperText={
                            errors.bussinessType ? errors.bussinessType : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Specialization
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessSpecialization'
                          onChange={handleTextChange}
                          value={overviewDetail?.bussinessSpecialization}
                          placeholder={'Specialization'}
                          error={!!errors.bussinessSpecialization}
                          helperText={
                            errors.bussinessSpecialization
                              ? errors.bussinessSpecialization
                              : ''
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Contact Number
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessSecondaryPhoneNumber'
                          onChange={handleTextChange}
                          inputProps={{ maxLength: 10 }} // Set maximum length
                          value={overviewDetail?.bussinessSecondaryPhoneNumber}
                          error={!!errors.bussinessSecondaryPhoneNumber}
                          helperText={
                            errors.bussinessSecondaryPhoneNumber
                              ? errors.bussinessSecondaryPhoneNumber
                              : ''
                          }
                          placeholder={'Contact Number'}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        E- mail Address of Admin
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessEmailId'
                          onChange={handleTextChange}
                          error={!!errors.bussinessEmailId}
                          helperText={
                            errors.bussinessEmailId
                              ? errors.bussinessEmailId
                              : ''
                          }
                          value={overviewDetail?.bussinessEmailId}
                          placeholder={'E- mail Address of Admin'}
                        />
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        required={true}
                        className={Styles.grayFonts}
                      >
                        Alternate Email Address
                      </StyledTableCell>
                      <StyledTableCell className={Styles.MediumBlackFonts}>
                        <TextInput
                          required={false}
                          id='bussinessSecondaryEmailId'
                          onChange={handleTextChange}
                          error={!!errors.bussinessSecondaryEmailId}
                          helperText={
                            errors.bussinessSecondaryEmailId
                              ? errors.bussinessSecondaryEmailId
                              : ''
                          }
                          value={overviewDetail?.bussinessSecondaryEmailId}
                          placeholder={'Alternate Email Address'}
                        />
                      </StyledTableCell>
                    </TableRow>
                    {/* Other table rows */}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item className={Styles.Action}>
                <Button
                  variant='contained'
                  className={Styles.ActionButton}
                  onClick={handleEditToggle}
                  disabled={disableButton}
                >
                  <Typography className={Styles.TextWrapper4}>
                    Discard
                  </Typography>
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  className={Styles.DivWrapper}
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={disableButton}
                >
                  <Typography className={Styles.TextWrapper4}>
                    Save Changes
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  )
}
export default Overview
