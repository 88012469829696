import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import { addAdditionalInformation } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setAdditionalInformation } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'

const AdditionalInformation = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()
  const AdditionalInformationState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.AdditionalInformation,
  )

  const medicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const [formValues, setFormValues] = useState<any>({
    medicalDetailId,
    additionalInformation: '',
  })

  useEffect(() => {
    setFormValues({
      ...AdditionalInformationState,
      medicalDetailId,
    })
  }, [])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (!formValues.additionalInformation?.trim()) {
      newErrors.additionalInformation = 'Please enter additional information'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(addAdditionalInformation(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setAdditionalInformation(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Please provide any additional information or remarks that you
              would like to send with this application.
            </Typography>
          </Box>

          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                Additional Information
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='additionalInformation'
                name='additionalInformation'
                multiline
                rows={12}
                className={Style.AnsTxt}
                placeholder='Additional Information'
                value={formValues?.additionalInformation}
                onChange={handleFieldChange}
                error={!!errors.additionalInformation}
                helperText={errors.additionalInformation}
              />
            </Grid>
          </Grid>

          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' />
            <Typography>
              <i>
                Congratulations on completing the application questions! We
                understand this process can be demanding, but you're almost
                there. Just a couple more steps: please upload the applicant's
                W2 form(s) and signed consent forms to ensure all information is
                stored in one convenient location. Once everything is saved,
                BenefitsBoost will carefully review the application and offer
                recommendations to enhance comprehension, accuracy, and
                consistency. We encourage you to review each notification to
                maximize this case’s potential for success.
              </i>
            </Typography>
          </Box>

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default AdditionalInformation
