/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

interface ContactInfo {
  question: string
  answer: any
}
interface Address {
  city: string
  state: string
  country: string
  postalCode: string
  streetAddress: string
}
const BasicDetailsTable: React.FC = ({ question }: { question: any }) => {
  // combines address object into a string
  const combineAddress = (address: Address): string => {
    return `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode}, ${address.country}`
  }

  // Function to handle boolean checkbox display
  const renderBooleanCheckbox = (answer: boolean) => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={answer === true}
              disabled
              style={{ color: 'black' }}
            />
          }
          label={<Typography style={{ color: 'black' }}>Yes</Typography>}
          style={{ color: 'black' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={answer === false}
              disabled
              style={{ color: 'black' }}
            />
          }
          label={<Typography style={{ color: 'black' }}>No</Typography>}
          style={{ color: 'black' }}
        />
      </FormGroup>
    )
  }

  // Common function to format dates from 'YYYY-MM-DD' to 'DD MMMM YYYY'
  const formatDate = (dateString: string): string => {
    if (dateString === ' ') {
      return 'N/A'
    }

    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
    const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
  }

  // Helper function to format address object into a string
  const formatAddress = (address: any) => {
    const { streetAddress, city, state, postalCode, country } = address
    return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
  }

  const renderAnswer = (item: any) => {
    const { answer, iterable } = item
    if (typeof answer === 'boolean') {
      return renderBooleanCheckbox(answer)
    }

    // Check if the answer is an empty string
    if (answer === '') {
      return 'N/A'
    }

    const datePattern = /^\d{4}-\d{2}-\d{2}$/
    if (typeof answer === 'string' && datePattern.test(answer)) {
      if (answer === ' ') {
        return 'N/A'
      }
      // Convert the date string into a Date object
      const date = new Date(answer)

      // Format the date as 'DD MMMM YYYY' (e.g., '04 July 2018')
      const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
      const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
      const year = date.getFullYear()

      return `${day} ${month} ${year}` // Return the formatted date
    }

    if (Array.isArray(answer) && iterable) {
      // Handle iterable answers (like MilitaryServiceInformation)
      return renderIterableAnswer(answer, iterable)
    }

    if (Array.isArray(answer) && typeof answer[0] === 'string') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((item, index) => (
                <TableRow key={index}>
                  {/* <TableCell>{index + 1}</TableCell> */}
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (
      typeof answer === 'object' &&
      answer?.streetAddress &&
      answer?.city &&
      answer?.state &&
      answer?.postalCode &&
      answer?.country
    ) {
      // If it's an address object, format it into a string
      return formatAddress(answer)
    }

    if (typeof answer === 'object' && answer.firstName) {
      return `${answer.firstName || ''} ${answer.middleName || ''} ${answer.lastName || ''}`.trim()
    }

    if (typeof answer === 'object') {
      return JSON.stringify(answer, null, 2)
    }

    return answer
  }

  const renderTable = (info: ContactInfo[]) => (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {info.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '30%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px' }}>
                    {item.question || item.iterable}
                  </span>
                </td>
                <td style={{ width: '70%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px', fontWeight: 500 }}>
                    {renderAnswer(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  // Function to render military service information or any iterable answer in tabular format
  const renderIterableAnswer = (answer: any[], iterable: string) => {
    if (iterable === 'MilitaryServiceInformation') {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type of Duty</TableCell>
                <TableCell>Branch of Service</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>{service.typeOfDuty}</TableCell>
                  <TableCell>{service.branchOfService}</TableCell>
                  <TableCell>{formatDate(service.startDate)}</TableCell>
                  <TableCell>
                    {service.endDate === ''
                      ? 'Presently working'
                      : formatDate(service.endDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    if (iterable === 'benefitAppliedSSNDetail') {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Suffix</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Monthly Benefit Amount</TableCell>
                <TableCell>Social Security Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer.map((service, index) => (
                <TableRow key={index}>
                  <TableCell>{service.suffix}</TableCell>
                  <TableCell>
                    {service.firstName +
                      ' ' +
                      service.middleName +
                      ' ' +
                      service.lastName}
                  </TableCell>
                  <TableCell>{service.monthlyBenefitAmount}</TableCell>
                  <TableCell>{service.socialSecurityNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    if (iterable === 'participateProgramDetails') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each organization's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Organization Details {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each organization */}
                  <TableRow>
                    <TableCell width={0}>Organization Name</TableCell>
                    <TableCell>{service.organizationName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Contact Name</TableCell>
                    <TableCell>{service.contactName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Phone</TableCell>
                    <TableCell>{service.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell width={0} style={{ wordWrap: 'break-word' }}>
                      {combineAddress(service.address)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Estimated Date</TableCell>
                    <TableCell>{formatDate(service.estimatedDate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Description</TableCell>
                    <TableCell>{service.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Still Participated</TableCell>
                    <TableCell>
                      {renderBooleanCheckbox(
                        service.stillParticipatedInProgram,
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    // Add logic here for future iterable arrays if needed
    return null // Return null if no matching iterable
  }

  return (
    // <Paper elevation={3} style={{ padding: '16px' }}>
    <Box p={'16px'}>
      <Box mb={4}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Typography
            fontSize={'22px'}
            textAlign={'center'}
            fontWeight={'bold'}
            gutterBottom
          >
            Basic Details
          </Typography>
        </Grid>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Contact Information
          </Typography>
        </Box>
        {renderTable(question?.contactInformation)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Communication
          </Typography>
        </Box>
        {renderTable(question?.communication)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Citizenship
          </Typography>
        </Box>
        {renderTable(question?.citizenship)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Military Service
          </Typography>
        </Box>
        {renderTable(question?.Military_Service)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Past Application
          </Typography>
        </Box>
        {renderTable(question?.Pass_Application)}
      </Box>
    </Box>
    // </Paper>
  )
}

export default BasicDetailsTable
