import { Button, InputLabel, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import star from 'src/Assets/Icons/star.svg'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { AddFinalSubmit } from 'src/Redux/Slices/ApplicationSlices/FinalSubmitFormsSlice'
import { setFinalSubmit } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import Style from './index.module.scss'

const FinalSubmit = () => {
  const dispatch = useAppDispatch()
  const applicationId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )

  const disableButton = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.finalSubmit,
  )
  const handleSubmit = () => {
    dispatch(AddFinalSubmit({ applicationId })).then((res) => {
      if (res?.payload?.success) {
        dispatch(setFinalSubmit(true))
      }
    })
  }

  return (
    <Box className={Style.GridBox}>
      <Box
        my={'20px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <InputLabel className={Style.HeadTxt}>Way to go!</InputLabel>
      </Box>

      <>
        <Box
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography className={Style.label}>
            Well done on successfully completing the initial disability benefits
            application! We encourage you to download the full application to
            seamlessly initiate the submission process with the SSA and advance
            your client’s case with confidence.
          </Typography>
        </Box>

        {/* <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-start'}
          sx={{
            p: 2,
            my: 4,
            backgroundColor: '#ECF6FF',
            borderRadius: '10px',
          }}
        >
          <img src={star} className={Style.star} alt='' />
          <Typography>
            <i>
              Please reach out to your representative should you have any
              questions beyond this point. Thank you!
            </i>
          </Typography>
        </Box> */}

        <Box className={Style.AddButton}>
          <Button
            variant='contained'
            type='submit'
            className={Style.ButtonTwo}
            onClick={handleSubmit}
            disabled={disableButton}
          >
            Submit
          </Button>
        </Box>
      </>
    </Box>
  )
}
export default FinalSubmit
