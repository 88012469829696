import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material'

// Helper function to format address object into a string
const combineAddress = (address: any) => {
  const { streetAddress, city, state, postalCode, country } = address
  return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
}

// Common function to format dates from 'YYYY-MM-DD' to 'DD MMMM YYYY'
const formatDate = (dateString: string): string => {
  const date = new Date(dateString)

  const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
  const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

// Reusable Table function
export const CommonMedicalTable = (answer: any[], title: string) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {answer.map((service: any, index: number) => (
            <React.Fragment key={index}>
              {/* Title row for each organization's details */}
              <TableRow>
                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#f5f5f5',
                      padding: '10px',
                    }}
                  >
                    {title} {index + 1}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* Data rows for each organization */}
              <TableRow>
                <TableCell width={0}>Hospital Name</TableCell>
                <TableCell>{service.hospitalName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell width={0} style={{ wordWrap: 'break-word' }}>
                  {combineAddress(service.address)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Phone Number</TableCell>
                <TableCell>{service.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Arrived Date</TableCell>
                <TableCell>{formatDate(service.arrivedDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Left Date</TableCell>
                <TableCell>{formatDate(service.leftDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Treatment Type</TableCell>
                <TableCell>
                  {service.treatmentType === '' ? 'N/A' : service.treatmentType}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Other Treatment</TableCell>
                <TableCell>
                  {service.otherTreatment === ''
                    ? 'N/A'
                    : service.otherTreatment}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Type of Therapy Doctor Provided</TableCell>
                <TableCell>
                  {service.typeOfTherapyDoctorProvided.length === 0
                    ? 'N/A'
                    : service.typeOfTherapyDoctorProvided}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Other Type of Therapy Details</TableCell>
                <TableCell>
                  {service.otherTypeOfTherapyDetails === ''
                    ? 'N/A'
                    : service.otherTypeOfTherapyDetails}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Special Procedure Detail</TableCell>
                <TableCell>
                  {service.specialProcedureDetail === ''
                    ? 'N/A'
                    : service.specialProcedureDetail}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const CommonOrgTable = (answer: any[], title: string) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {answer.map((service: any, index: number) => (
            <React.Fragment key={index}>
              {/* Title row for each organization's details */}
              <TableRow>
                <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#f5f5f5',
                      padding: '10px',
                    }}
                  >
                    {title} {index + 1}
                  </Typography>
                </TableCell>
              </TableRow>

              {/* Data rows for each organization */}
              <TableRow>
                <TableCell width={0}>Organization Name</TableCell>
                <TableCell>{service.organizationName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Contact Person Name</TableCell>
                <TableCell>
                  {service.contactPersonFirstName +
                    ' ' +
                    service.contactPersonLastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Contact Phone Number</TableCell>
                <TableCell>{service.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell width={0} style={{ wordWrap: 'break-word' }}>
                  {combineAddress(service.address)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Arrived Date</TableCell>
                <TableCell>{formatDate(service.arrivedDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={0}>Left Date</TableCell>
                <TableCell>{formatDate(service.leftDate)}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
