import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Name } from 'src/Components/Fields'
import { useAppDispatch } from 'src/Redux/hooks'
import { getAllApplicationList } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import { GenApplication } from 'src/Redux/Slices/ApplicationSlices/applicationSlice'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'

const GenAppDialogue = ({
  openDialog,
  setOpenDialog,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const handleClose = () => {
    setOpenDialog(false)
  }

  const dispatch = useAppDispatch()

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleFieldChange = ({
    name,
    value,
  }: {
    name: string
    value: string | number
  }) => {
    setFormValues({ ...formValues, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.firstName.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    }
    if (!formValues.lastName.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    }
    if (!formValues.emailId.trim()) {
      newErrors.emailId = 'Email is required'
      valid = false
    } else if (
      formValues.emailId.trim() &&
      !/\S+@\S+\.\S+/.test(formValues.emailId)
    ) {
      newErrors.emailId = 'Invalid email address'
      valid = false
    }
    if (!formValues.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(GenApplication(formValues)).then((res) => {
        if (res.payload?.success) {
          dispatch(getAllApplicationList({}))
          setOpenDialog(false)
        }
      })
    }
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Generate New Application
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit} className={Style.Form}>
                <Box display={'flex'} gap={'20px'}>
                  <Name
                    name='firstName'
                    title='First name of applicant'
                    value={formValues?.firstName}
                    handleChange={handleFieldChange}
                    error={errors.firstName}
                  />
                  <Name
                    name='lastName'
                    title='Last name of applicant'
                    value={formValues?.lastName}
                    handleChange={handleFieldChange}
                    error={errors.lastName}
                  />
                </Box>
                <Name
                  number
                  name='phoneNumber'
                  title='Phone number of applicant'
                  value={formValues?.phoneNumber}
                  handleChange={handleFieldChange}
                  error={errors.phoneNumber}
                />
                <Name
                  email
                  name='emailId'
                  title='Email address of applicant'
                  value={formValues?.emailId}
                  handleChange={handleFieldChange}
                  error={errors.emailId}
                />
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Generate
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default GenAppDialogue
