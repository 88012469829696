/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  styled,
  MenuItem,
  TableCellProps,
} from '@mui/material'
import Styles from './tabs.module.scss'
import { useEffect, useState } from 'react'

import TextInput from 'src/Components/Application/CustomInputs/TextInput'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  EditOrganizationContactInfo,
  getOrganizationContactInfo,
  SetSelectedOrganization,
} from 'src/Redux/Slices/orgSlice'

interface StyledTableCellProps extends TableCellProps {
  required?: boolean // Optional boolean prop to control asterisk sign visibility
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({ variant, required }) => ({
    [`&.${tableCellClasses.root}`]: {
      fontSize: '16px',
      position: 'relative', // Ensure relative positioning for pseudo-element
      // Add additional styles based on the variant prop
      ...(variant === 'head' && {
        // Apply styles specific to the 'head' variant
        width: '30%',
      }),
      // Define styles for the pseudo-element
      '&::after': {
        content: variant === 'head' && required ? '" *"' : '""', // Show star after text for head variant
        position: 'absolute',
        color: 'red', // Adjust color as needed
      },
    },
  }),
)

const Contact = () => {
  const [editMode, setEditMode] = useState(true)
  const [optionSelected, setOptionSelected] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const [contactDetail, setContactDetail] = useState({
    contactFirstName: '',
    contactLastName: '',
    contactEmailId: '',
    contactUuid: '',
  })
  const { selectedOrganization } = useAppSelector((state) => state.organization)
  const [errors, setErrors] = useState({
    contactFirstName: '',
    contactLastName: '',
    contactEmailId: '',
  })

  useEffect(() => {
    if (selectedOrganization?.uuid) {
      dispatch(getOrganizationContactInfo(selectedOrganization?.uuid))
    }
  }, [])

  useEffect(() => {
    setContactDetail({
      contactFirstName: selectedOrganization.contactFirstName || '',
      contactLastName: selectedOrganization.contactLastName || '',
      contactEmailId: selectedOrganization.contactEmailId || '',
      contactUuid: selectedOrganization.contactUuid || '',
    })
  }, [selectedOrganization])

  const handleEditToggle = () => {
    setEditMode(!editMode)
  }

  const handleValidation = () => {
    const errorsObj = { ...errors }

    // Validating business name, description, street address, state, type, specialization, and tracking name
    errorsObj.contactFirstName = contactDetail.contactFirstName
      ? ''
      : 'contactFirstName  is required'
    errorsObj.contactLastName = contactDetail.contactLastName
      ? ''
      : 'contactLastName is required'

    if (!contactDetail.contactEmailId) {
      errorsObj.contactEmailId = 'Email ID is required'
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactDetail.contactEmailId)
    ) {
      errorsObj.contactEmailId = 'Invalid email ID'
    } else {
      errorsObj.contactEmailId = ''
    }

    // Validating business Phone numbers

    setErrors(errorsObj)
    return errorsObj
  }
  const areAllErrorsEmpty = (formErrors: {
    [x: string]: string
    contactFirstName: string
    contactLastName: string
    contactEmailId: string
  }) => {
    for (const key in formErrors) {
      if (
        Object.prototype.hasOwnProperty.call(formErrors, key) &&
        formErrors[key] !== ''
      ) {
        return false // If any key has a non-empty value, return false
      }
    }
    return true // If all keys have empty values, return true
  }
  const handleSubmit = () => {
    const formErrors = handleValidation()
    const allErrorsEmpty = areAllErrorsEmpty(formErrors)
    if (allErrorsEmpty) {
      dispatch(SetSelectedOrganization(contactDetail))
      dispatch(
        EditOrganizationContactInfo({
          ...contactDetail,
          uuid: selectedOrganization.uuid,
        }),
      )
      handleEditToggle()
    }
  }

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = event.target
    const key = id
    setContactDetail({ ...contactDetail, [id]: value })
  }
  return (
    <>
      {editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className={Styles.alignItemCenter} display='flex'>
              <Typography variant='h1' className={Styles.FontBold}>
                Contact Information
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className={Styles.EditBtnStyle}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          <Grid container m={2}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                  },
                  //   width: '50%',
                }}
              >
                <TableRow>
                  <StyledTableCell variant='head' className={Styles.grayFonts}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell className={Styles.MediumBlackFonts}>
                    {selectedOrganization.contactFirstName}{' '}
                    {selectedOrganization.contactLastName}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell variant='head' className={Styles.grayFonts}>
                    E- mail Address
                  </StyledTableCell>
                  <StyledTableCell className={Styles.MediumBlackFonts}>
                    {selectedOrganization.contactEmailId}
                  </StyledTableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className={Styles.alignItemCenter} display='flex'>
                <Typography variant='h1' className={Styles.FontBold}>
                  Contact Detail
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '95%', marginTop: '8px', marginBottom: '8px' }}
              />
            </Grid>
            <Grid container m={2}>
              <TableContainer>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                    },
                    //   width: '50%',
                  }}
                >
                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      required={true}
                      className={Styles.grayFonts}
                    >
                      Name
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextInput
                            placeholder={'First Name'}
                            style={{ width: '95%' }}
                            id='contactFirstName'
                            onChange={handleTextChange}
                            value={contactDetail.contactFirstName}
                            error={!!errors.contactFirstName}
                            helperText={
                              errors.contactFirstName
                                ? errors.contactFirstName
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextInput
                            placeholder={'Last Name'}
                            style={{ width: '100%' }}
                            id='contactLastName'
                            onChange={handleTextChange}
                            value={contactDetail.contactLastName}
                            error={!!errors.contactLastName}
                            helperText={
                              errors.contactLastName
                                ? errors.contactLastName
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell
                      variant='head'
                      required={true}
                      className={Styles.grayFonts}
                    >
                      E- mail Address
                    </StyledTableCell>
                    <StyledTableCell className={Styles.MediumBlackFonts}>
                      <TextInput
                        required={false}
                        placeholder={'E- mail Address'}
                        id='contactEmailId'
                        onChange={handleTextChange}
                        value={contactDetail.contactEmailId}
                        error={!!errors.contactEmailId}
                        helperText={
                          errors.contactEmailId ? errors.contactEmailId : ''
                        }
                      />
                    </StyledTableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item className={Styles.Action}>
              <Button
                variant='contained'
                className={Styles.ActionButton}
                onClick={handleEditToggle}
              >
                <Typography className={Styles.TextWrapper3}>Discard</Typography>
              </Button>
              <Button
                type='submit'
                variant='contained'
                className={Styles.DivWrapper}
                onClick={() => {
                  handleSubmit()
                }}
              >
                <Typography className={Styles.TextWrapper4}>
                  Save Changes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
export default Contact
