import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import WorkTaxDetails from '../EditForms/WorkTaxDetails'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'

const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const workTaxRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    workTaxRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.WorkTaxDetails,
  )
  const workTaxDetailsSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Work Tax Details',
      )?.sectionId,
  )
  return (
    <div id={workTaxDetailsSectionId} ref={workTaxRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Work Tax Details
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Total earnings include special payments paid during one
                        year but earned in another
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.didPaymentPaidAnotherYear ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Worked a job in which U.S. Social Security taxes were
                        not deducted or withheld
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {' '}
                        {data?.haveWorkedWhereTaxesNotDeducted ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.haveWorkedWhereTaxesNotDeducted ? (
                    <>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Receiving a pension or annuity based on this work
                            for which taxes were not deducted or withheld
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {' '}
                            {data?.isReceivingPensionOrAnnuity ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            The pension or annuity based on government
                            employment
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {' '}
                            {data?.isPensionOrAnnuityBasedOnGovernmentEmployment
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            The pension or annuity based on Rail road work
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.pensionAnnuityRailroadWork ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Expect to receive a pension or annuity in the future
                            for this work, for which taxes were not deducted or
                            withheld
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {' '}
                            {data?.isExpectPensionOrAnnuityInFuture
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            The pension or annuity based on Future Goverment
                            Work
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.pensionAnnuityFutureGovtWork ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            The pension or annuity expected to begin
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {convertISOToDate(
                              data?.pensionAnnuityFutureStartDate,
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Receive a lump sum payment of any kind instead of a
                            pension or annuity for this work for which taxes
                            were not deducted or withheld
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {' '}
                            {data?.lumpSumNoTaxes ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Work Tax Details
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <WorkTaxDetails handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
