import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  tableCellClasses,
  TableRow,
  Paper,
  TableCell,
  styled,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CommunicationPreference from '../../EditForms/BasicDetails/CommunicationPreference/index'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
import { setStepCompleted } from 'src/Redux/Slices/stepSlice'
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const Communication: React.FC = () => {
  const [editMode, setEditMode] = useState(false)
  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const communicationRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    // You can perform actions based on the scroll position here
    // For example, trigger steps or animations

    // Check if the section grid is in view
    if (scrollableContainerRef.current) {
      const rect = scrollableContainerRef.current.getBoundingClientRect()

      const isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)

      // If section grid is in view, execute your method
      if (isInView) {
        // Execute your method here
        console.log('Section grid is in view!')
      }
    }
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.CommunicationPreference,
  )
  const dispatch = useAppDispatch()
  const handleEditToggle = () => {
    setEditMode(!editMode)
    communicationRef?.current?.scrollIntoView()
  }
  useEffect(() => {
    if (data) {
      dispatch(
        setStepCompleted({
          section: 'BasicApplicationSteps',
          label: 'Communication',
        }),
      )
    }
  }, [data])

  const communicationSectionId = useAppSelector(
    (state) =>
      state?.steps?.BasicApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Communication',
      )?.sectionId,
  )
  return (
    <div id={communicationSectionId} ref={communicationRef}>
      {!editMode ? (
        <>
          <Grid
            container
            m={2}
            // sx={{ overflowY: 'scroll' }}
            ref={scrollableContainerRef}
            onScroll={handleScroll}
          >
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Communication Preferences
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          {!hasEmpty(data) ? (
            <>
              {' '}
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell
                        variant='head'
                        width={'50%'}
                        className='grayFonts'
                      >
                        Your preferred language
                        <br /> for reading, writing, and <br /> speaking is
                        English
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isPreferredLanguageEnglish ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid m={2}>
                <Grid container m={2}>
                  <Grid lg={12} pr={6}>
                    <Typography variant='h3' className='FontBold' mb={2}>
                      Communication
                    </Typography>
                    <Paper
                      sx={{
                        p: 2,
                        bgcolor: 'transparent',
                        border: '1px solid #E1E4EB',
                        boxShadow: 0,
                      }}
                    >
                      <Grid container>
                        <Grid py={1} container>
                          <Grid lg={4}>
                            <Typography variant='h3' className='grayFonts'>
                              Preferred language for reading
                            </Typography>
                            <Typography variant='h3'>
                              {data.preferredReadingLanguage
                                ? data.preferredReadingLanguage
                                : 'English'}
                            </Typography>
                          </Grid>
                          <Grid lg={4}>
                            <Typography variant='h3' className='grayFonts'>
                              Preferred language for speaking
                            </Typography>
                            <Typography variant='h3'>
                              {data.preferredSpeakingLanguage
                                ? data.preferredSpeakingLanguage
                                : 'English'}
                            </Typography>
                          </Grid>
                          <Grid lg={4}>
                            <Typography variant='h3' className='grayFonts'>
                              Preferred language for writing
                            </Typography>
                            <Typography variant='h3'>
                              {data.preferredWritingLanguage
                                ? data.preferredWritingLanguage
                                : 'English'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Able to read English
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isPreferredLanguageEnglish
                          ? 'yes'
                          : data?.canReadEnglish
                            ? 'yes'
                            : 'no'}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Able to speak English
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isPreferredLanguageEnglish
                          ? 'yes'
                          : data?.canWriteEnglish
                            ? 'Yes'
                            : 'No'}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Able to write in English
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isPreferredLanguageEnglish
                          ? 'yes'
                          : data?.canSpeakEnglish
                            ? 'Yes'
                            : 'No'}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Able to read and write more than your name
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isAbleToReadWriteMoreThenName ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <Grid m={2}>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Communication Preferences
              </Typography>
            </Grid>
            <Divider
              sx={{ width: '95%', marginTop: '8px', marginBottom: '8px' }}
            />
          </Grid>
          <CommunicationPreference handleEditToggle={handleEditToggle} />
        </Grid>
      )}
    </div>
  )
}

export default Communication
