import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Filter from 'src/Assets/Icons/Filter.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import Plus from 'src/Assets/Icons/Plus.svg'
import Search from 'src/Assets/Icons/Search.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import AddEmployee from 'src/Components/Employees/AddEmployee'
import { EmployeesList } from 'src/Components/Employees/EmployeeList'
import { employeeType } from 'src/Redux/Services/Types/employeeType'
import {
  EmployeeListRequest,
  OrganizationListRequest,
} from 'src/Redux/Slices/employeeSlice'
import { ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { DynamicObj } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'

const Employees: React.FunctionComponent = () => {
  const employeeData = useAppSelector((state) => state.employee?.data)
  const dispatch = useAppDispatch()
  const rolelist = useAppSelector((state) => state.roles?.roleList?.data)
  const organizationList = useAppSelector(
    (state) => state.employee?.organizationList?.data,
  )
  const [openAdd, setOpenAdd] = useState(false)
  const [q, setQ] = React.useState('')

  useEffect(() => {
    dispatch(EmployeeListRequest({}))
  }, [])

  const handleAddEmployee = () => {
    setOpenAdd(true)
  }

  function find(items: []) {
    const filteredEmployees = items?.map((item: DynamicObj) => {
      if (item.organizationName?.toLowerCase().includes(q.toLowerCase())) {
        return item
      }
      const employees = item.employeedata?.filter((employee: DynamicObj) => {
        return [
          'firstName',
          'jobTitle',
          'jobType',
          'phoneNumber',
          'emailId',
        ].some((newItem) => {
          if (employee[newItem]?.toLowerCase().includes(q.toLowerCase()))
            return true
          return false
        })
      })
      return { ...item, employeedata: employees }
    })
    return filteredEmployees?.filter((item) => {
      return item?.employeedata?.length !== 0
    })
  }

  useEffect(() => {
    dispatch(OrganizationListRequest())
    dispatch(ViewRoleRequest())
  }, [])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const ActionOpen = Boolean(anchorEl)
  const [filter, setFilter] = React.useState<{
    scope?: string
    roleId?: string
    organizationId?: string
  }>({})

  const handleFormChange = (event: SelectChangeEvent<string | number>) => {
    event.stopPropagation()
    const { name, value } = event.target
    setFilter({
      ...filter,
      [name]: value,
    })
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleFilter = (e: { stopPropagation: () => void }, reset?: string) => {
    handleClose(e)
    if (reset === 'reset') dispatch(EmployeeListRequest({}))
    else dispatch(EmployeeListRequest(filter))
  }
  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          <Breadcrumb
            title='Manage Employees'
            Menus={[
              { name: 'Employees', route: ROUTES.ADMIN_MANAGE_EMPLOYEES },
            ]}
          />
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search employee'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
            <Box className={Style.Right}>
              <Box className={Style.Box1}>
                <Box
                  className={Style.Main2}
                  onClick={(e) => {
                    handleClick(
                      e as unknown as React.MouseEvent<
                        HTMLButtonElement,
                        MouseEvent
                      >,
                    )
                  }}
                  aria-controls={ActionOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={ActionOpen ? 'true' : undefined}
                >
                  <img src={Filter} alt='Filter' />
                  <Typography className={Style.MidText}>Filter</Typography>
                </Box>
                <Menu
                  className={Style.filterMenu}
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={ActionOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className={Style.titleWrapper}>
                    <Typography className={Style.textFieldWrapper}>
                      Filter Option
                    </Typography>
                  </MenuItem>
                  <MenuItem
                  // onClick={(event) => {
                  //   event.stopPropagation()
                  //   // handleDetails(employeeData.uuid, false)
                  // }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>
                        Scope:
                      </Typography>
                      <Select
                        className={Style.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='scope'
                        value={filter?.scope ?? 0}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        <MenuItem value={0}>Select Scope</MenuItem>
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                        <MenuItem value={'Legal'}>Legal</MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem
                  // onClick={(event) => {
                  //   event.stopPropagation()
                  //   // handleDetails(employeeData.uuid, true)
                  // }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>Role:</Typography>
                      {rolelist?.length && (
                        <Select
                          className={Style.inField}
                          IconComponent={(props) => (
                            <IconButton
                              {...props}
                              style={{ padding: '4px 10px' }}
                            >
                              <img
                                src={IconChevronDown}
                                alt='IconChevronDown'
                              />
                            </IconButton>
                          )}
                          size='small'
                          name='roleId'
                          value={filter?.roleId ?? 0}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={handleFormChange}
                        >
                          <MenuItem value={0}>Select Role</MenuItem>
                          {rolelist?.length &&
                            rolelist?.map(
                              (role: { uuid: string; name: string }) => (
                                <MenuItem key={role?.uuid} value={role?.uuid}>
                                  {role?.name}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                      )}
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation()
                      handleClose(event)
                      // dispatch(
                      //   DeleteEmployeeRequest({
                      //     uuid: employeeData?.uuid,
                      //     data: { isActive: !employeeData?.isActive },
                      //   }),
                      // ).then(() => {
                      //   dispatch(EmployeeListRequest())
                      // })
                    }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>
                        Organization:
                      </Typography>
                      {organizationList?.length && (
                        <Select
                          className={Style.inField}
                          IconComponent={(props) => (
                            <IconButton
                              {...props}
                              style={{ padding: '4px 10px' }}
                            >
                              <img
                                src={IconChevronDown}
                                alt='IconChevronDown'
                              />
                            </IconButton>
                          )}
                          size='small'
                          name='organizationId'
                          value={filter?.organizationId ?? 0}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={handleFormChange}
                        >
                          <MenuItem value={0}>Select Organization</MenuItem>
                          {organizationList?.length &&
                            organizationList?.map(
                              (organization: {
                                uuid: string
                                name: string
                              }) => (
                                <MenuItem
                                  key={organization?.uuid}
                                  value={organization?.uuid}
                                >
                                  {organization?.name}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                      )}
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box className={Style.Buttons}>
                      <Box
                        className={Style.Add2}
                        onClick={(e) => {
                          setFilter({})
                          handleFilter(e, 'reset')
                        }}
                      >
                        Reset
                      </Box>
                      <Button
                        className={Style.Add}
                        variant='contained'
                        onClick={handleFilter}
                      >
                        Apply
                      </Button>
                    </Box>
                  </MenuItem>
                </Menu>
                {/* <img src={IconChevronDown} alt='IconChevronDown' /> */}
              </Box>
              <Button
                className={Style.Add}
                variant='contained'
                onClick={handleAddEmployee}
              >
                Add Employee
                <img src={Plus} alt='Plus' style={{ marginRight: '8px' }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid className={Style.ListWrapper}>
          <Grid className={Style.ListGridMain}>
            {!employeeData?.length ? (
              <></>
            ) : (
              find(employeeData)?.map(
                (
                  item: {
                    organizationName?: string
                    count?: number
                    employeedata?: employeeType[]
                  },
                  index: number,
                ) => {
                  return (
                    <>
                      <Typography key={index} className={Style.ListText}>
                        {item.organizationName} ({item.count})
                      </Typography>
                      <Grid className={Style.ListGrid}>
                        {item.employeedata?.length &&
                          item.employeedata?.map(
                            (emp: employeeType, i: number) => {
                              return (
                                <Box key={i} className={Style.List}>
                                  <EmployeesList employeeData={emp} />
                                </Box>
                              )
                            },
                          )}
                      </Grid>
                    </>
                  )
                },
              )
            )}
          </Grid>
        </Grid>
      </Box>
      {openAdd && <AddEmployee admin open={openAdd} setOpen={setOpenAdd} />}
    </>
  )
}

export default Employees
