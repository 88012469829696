import { Switch, SwitchProps, styled } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { COLORS, Fonts } from 'src/Utils/Constants'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 500,
      sm: 900,
      md: 1100,
      lg: 1500,
      xl: 1900,
    },
  },
  palette: {
    primary: {
      dark: COLORS.COLOR_DARK_GRAY,
      main: COLORS.COLOR_DARKER_GRAY,
    },

    text: {
      primary: COLORS.COLOR_BLACK,
      secondary: COLORS.COLOR_BLUE,
    },
    background: {
      paper: COLORS.COLOR_WHITE_VARIANT_2,
      default: COLORS.COLOR_WHITE,
    },
  },

  typography: {
    fontFamily: ['SFProDisplay'].join(','),
    h1: {
      fontSize: 20,
      '@media (max-width:1200px)': {
        fontSize: 18,
      },
    },
    h2: {
      fontSize: 18,
      '@media (max-width:1200px)': {
        fontSize: 16,
      },
    },
    h3: {
      fontSize: 16,
      '@media (max-width:1200px)': {
        fontSize: 14,
      },
    },
    h4: {
      fontSize: 14,
      '@media (max-width:1200px)': {
        fontSize: 12,
      },
    },
    h5: {
      fontSize: 28,
      '@media (max-width:1200px)': {
        fontSize: 26,
      },
    },
    h6: {},
    body1: {
      fontSize: 18,
      '@media (max-width:1200px)': {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      '@media (max-width:1200px)': {
        fontSize: 12,
      },
    },
    button: {
      textTransform: 'capitalize',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '@media (min-width: 1500px)': {
            maxWidth: '100%',
          },
        },
      },
    },
    MuiButton: {
      // styleOverrides: {
      //   root: {
      //     pointer: 'cursor',
      //   },
      // },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: COLORS.COLOR_WHITE,
            textTransform: 'none',
            marginBottom: '10px',
            marginTop: '16px',
            borderRadius: '15px',
            height: '50px',
            width: '100%',
            cursor: 'pointer',
            backgroundColor: COLORS.COLOR_BLACK,
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: COLORS.COLOR_BLACK,

            borderRadius: '15px',
            height: '50px',
            display: 'flex',
            margin: 'auto',
            cursor: 'pointer',
            backgroundColor: COLORS.COLOR_WHITE,
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLORS.COLOR_BLACK,
          fontSize: '16px',
          fontWeight: 810,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: COLORS.COLOR_DARK_RED, // Set text color to red
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& label': {
              '& span': {
                color: COLORS.COLOR_BLACK,
              },
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: COLORS.COLOR_GRAY,
              borderColor: COLORS.COLOR_DARK_GRAY,
              fontSize: '14px',
              borderRadius: '10px',
            },
          },
        },
        {
          props: { variant: 'outlined', disabled: true }, // Variant for disabled state
          style: {
            '& .MuiOutlinedInput-root': {
              cursor: 'not-allowed,', // Set background color to red for disabled state
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SFProDisplay';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-medium;

          src: url(${Fonts.SFProDisplayRegular}) format('truetype');
        }
        @font-face {
          font-family: 'SFProDisplaySemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-regular;
          src:  url(${Fonts.SFProDisplaySemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'SFProDisplayMedium';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-regular;
          src:  url(${Fonts.SFProDisplayMedium}) format('truetype');
        }
        @font-face {
          font-family: 'SFProDisplayLight';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-regular;
          src:  url(${Fonts.SFProDisplayLight}) format('truetype');
        }
        @font-face {
          font-family: 'SFUiDisplay';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-medium;

          src: url(${Fonts.SFUiDisplayRegular}) format('truetype');
        }
        @font-face {
          font-family: 'SFUiDisplaySemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-regular;
          src:  url(${Fonts.SFUiDisplaySemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'SFUiDisplayBold';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-medium;

          src: url(${Fonts.SFUiDisplayBold}) format('truetype');
        }
        @font-face {
          font-family: 'SFUiDisplayMedium';
          font-style: normal;
          font-display: swap;
          font-weight: $fw-regular;
          src:  url(${Fonts.SFUiDisplayMedium}) format('truetype');
        }
      `,
    },
  },
})

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#7752FE',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export default theme
