import {
  Avatar,
  Box,
  CardContent,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material'
import { ChangeEvent, DragEvent, useRef } from 'react'
import Delete from 'src/Assets/Icons/delete.svg'
import uploadIcon from 'src/Assets/Icons/upload.svg'
import Style from '../index.module.scss'

const UploadField = ({
  title,
  upload,
  setUpload,
  error,
}: {
  title: string
  upload: Array<File | string>
  setUpload: (upload: Array<File | string>) => void
  error?: string
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const handleUploadFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles)
      setUpload([...upload, ...newFiles])
    }
  }
  const handleUploadDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const droppedFiles = event.dataTransfer.files
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles)
      setUpload([...upload, ...newFiles])
    }
  }
  const handleUploadRemoveFile = (index: number) => {
    setUpload(upload.filter((_, i) => i !== index))
  }
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.subLabel}>
        {title}
        <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <Box display='flex' flexDirection='column'>
        <CardContent
          className={Style.cardContent}
          onClick={() => inputRef.current?.click()}
          onDrop={(event) => {
            handleUploadDrop(event)
          }}
          onDragOver={(event) => {
            event.preventDefault()
          }}
        >
          <input
            ref={inputRef}
            type='file'
            hidden
            id='browse'
            onChange={(event) => {
              handleUploadFileChange(event)
            }}
            accept='.pdf,.docx,.png,.jpg,.jpeg'
            multiple
          />
          <Box className={Style.cardBox}>
            <img src={uploadIcon} alt='upload' />
            <Box className={Style.cardDetails}>
              <Typography>
                Drag and drop your file here or <strong>Browse</strong>
              </Typography>
              <Typography variant='caption'>
                Accepted file types: JPEG, Doc, PDF, PNG (Max 10MB each)
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <FormHelperText>{error}</FormHelperText>
        <Box className={Style.ChipBox}>
          {upload.length > 0 &&
            upload.map((value: File | string, index: number) => {
              return (
                <Box key={index} component='div' className={Style.overlapGroup}>
                  <Avatar
                    className={Style.Rectangle}
                    src={
                      upload[index] && typeof upload[index] === 'string'
                        ? upload[index]
                        : URL.createObjectURL(upload[index] as File)
                    }
                    alt='Rectangle'
                  />
                  {typeof value === 'string' ? (
                    <></>
                  ) : (
                    <Typography className={Style.nameWrapper}>
                      {value.name}
                    </Typography>
                  )}
                  <Box component='div' className={Style.IconDeleteWrapper}>
                    <img
                      src={Delete}
                      onClick={() => {
                        handleUploadRemoveFile(index)
                      }}
                      alt='Rectangle'
                    />
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Box>
    </Grid>
  )
}

export default UploadField
