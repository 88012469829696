import {
  TableCell,
  tableCellClasses,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  TableRow,
} from '@mui/material'
import '../index.scss'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty, convertISOToDate } from 'src/Utils/Constants'
import CurrentSignificant from '../EditForms/FamilyDetails/CurrentSignificant/index'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const CurrenySignificant: React.FC = () => {
  const [editMode, setEditMode] = useState(false)
  const currentRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    currentRef?.current?.scrollIntoView()
  }
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.FamilyDetails?.CurrentSignificant,
  )
  const currentSignificantOtherSectionId = useAppSelector(
    (state) =>
      state?.steps?.FamilyApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Current Significant Other',
      )?.sectionId,
  )
  const ErrorImage = styled('img')({
    marginRight: '8px', // Adjust margin as needed
    marginBottom: '-8px', // Adjust margin as needed
    paddingRight: '10px',
    paddingLeft: '10px',
    // position: 'absolute',
  })

  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.FamilyDetails?.familyFlagData
        ?.CurrentSignificant,
  )
  return (
    <div id={currentSignificantOtherSectionId} ref={currentRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Current Significant Other
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>

          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Currently legally married
                      </StyledTableCell>
                      <StyledTableCell
                        className={
                          flagData?.isLegallyMarried?.length > 0
                            ? 'redFlagFonts'
                            : 'MediumBlackFonts'
                        }
                      >
                        {data?.isLegallyMarried ? 'Yes' : 'No'}
                        {flagData?.isLegallyMarried?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.isLegallyMarried}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {data?.isLegallyMarried ? (
                <>
                  <Grid container m={2}>
                    <Typography variant='h2' className='FontBold' m={2}>
                      Marriage Documentation
                    </Typography>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                            width: '50%',
                          },
                        }}
                      >
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Current Spouse Name
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.spouseFirstName?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {`${data?.spouseFirstName ?? ''} ${data?.spouseLastName ?? ''}`}
                            {flagData?.spouseFirstName?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.spouseFirstName}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Current Spouse Date of Birth
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.spouseDateOfBirth?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {convertISOToDate(data?.spouseDateOfBirth)}
                            {flagData?.spouseDateOfBirth?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.spouseDateOfBirth}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Current Spouse Social Security Number
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.spouseSSN?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.spouseSSN}
                            {flagData?.spouseSSN?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.spouseSSN}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Type of Marriage
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.typeOfMarriage?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.typeOfMarriage}
                            {flagData?.typeOfMarriage?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.typeOfMarriage}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Date of Marriage
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.dateOfMarriage?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {convertISOToDate(data?.dateOfMarriage)}
                            {flagData?.dateOfMarriage?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.dateOfMarriage}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid container m={2}>
                    <Typography variant='h2' className='FontBold' m={2}>
                      Place of Marriage
                    </Typography>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                            width: '50%',
                          },
                        }}
                      >
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            City
                          </StyledTableCell>

                          <StyledTableCell
                            className={
                              flagData?.placeOfMarriage?.city?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.placeOfMarriage?.city}
                            {flagData?.placeOfMarriage?.city?.length > 0 ? (
                              <>
                                <Tooltip
                                  title={flagData?.placeOfMarriage?.city}
                                >
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            State/Territory
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.placeOfMarriage?.state?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.placeOfMarriage?.state}
                            {flagData?.placeOfMarriage?.state?.length > 0 ? (
                              <>
                                <Tooltip
                                  title={flagData?.placeOfMarriage?.state}
                                >
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Country
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData.placeOfMarriage?.country?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.placeOfMarriage?.country}
                            {flagData?.placeOfMarriage?.country?.length > 0 ? (
                              <>
                                <Tooltip
                                  title={flagData?.placeOfMarriage?.country}
                                >
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Other
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.placeOfMarriage?.other?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.placeOfMarriage?.other}
                            {flagData?.placeOfMarriage?.other?.length > 0 ? (
                              <>
                                <Tooltip
                                  title={flagData?.placeOfMarriage?.other}
                                >
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : null}
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Current Significant Other
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <CurrentSignificant handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
export default CurrenySignificant
