import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  tableCellClasses,
  TableRow,
  TableCell,
  styled,
  Chip,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import Citizenship from '../../EditForms/BasicDetails/Citizenship'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))
const ErrorImage = styled('img')({
  marginRight: '8px', // Adjust margin as needed
  marginBottom: '-8px', // Adjust margin as needed
  paddingRight: '10px',
  paddingLeft: '10px',
  // position: 'absolute',
})

const Component: React.FC = () => {
  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.Citizenship,
  )
  const [editMode, setEditMode] = useState(false)
  const citizenshipRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    citizenshipRef?.current?.scrollIntoView()
  }
  const citizenshipSectionId = useAppSelector(
    (state) =>
      state?.steps?.BasicApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Citizenship',
      )?.sectionId,
  )

  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.basicFlagData?.Citizenship,
  )
  return (
    <div id={citizenshipSectionId} ref={citizenshipRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Citizenship
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Born in the U.S. or a U.S. territory
                      </StyledTableCell>
                      <StyledTableCell
                        className={
                          flagData?.wereBornInUSA?.length > 0
                            ? 'redFlagFonts'
                            : 'MediumBlackFonts'
                        }
                      >
                        {data?.wereBornInUSA ? 'Yes' : 'No'}
                        {flagData?.wereBornInUSA?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.wereBornInUSA}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                    {data?.wereBornInUSA ? (
                      <>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            City where you were born
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.bornUSACityName?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.bornUSACityName}
                            {flagData?.bornUSACityName?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.bornUSACityName}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            State where you were born
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.bornUSAStateName?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.bornUSAStateName}
                            {flagData?.bornUSAStateName?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.bornUSAStateName}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Country where you were born
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.bornCountry?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.bornCountry}
                            {flagData?.bornCountry?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.bornCountry}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Are you a U.S. citizen?
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              flagData?.confirmUSACitizen?.length > 0
                                ? 'redFlagFonts'
                                : 'MediumBlackFonts'
                            }
                          >
                            {data?.confirmUSACitizen}
                            {flagData?.confirmUSACitizen?.length > 0 ? (
                              <>
                                <Tooltip title={flagData?.confirmUSACitizen}>
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </StyledTableCell>
                        </TableRow>
                        {data?.confirmUSACitizen ===
                        'Yes, I am a U.S.citizen' ? (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                How you qualified for U.S. citizenship.
                              </StyledTableCell>
                              <StyledTableCell
                                className={
                                  flagData?.qualifiedUSACitizenship?.length > 0
                                    ? 'redFlagFonts'
                                    : 'MediumBlackFonts'
                                }
                              >
                                {data?.qualifiedUSACitizenship}
                                {flagData?.qualifiedUSACitizenship?.length >
                                0 ? (
                                  <>
                                    <Tooltip
                                      title={flagData?.qualifiedUSACitizenship}
                                    >
                                      <ErrorImage src={error} alt='Error' />
                                    </Tooltip>
                                  </>
                                ) : null}
                              </StyledTableCell>
                            </TableRow>
                            {data?.qualifiedUSACitizenship ===
                            'Naturalized Citizen' ? (
                              <>
                                <TableRow>
                                  <StyledTableCell
                                    variant='head'
                                    className='grayFonts'
                                  >
                                    Your date of citizenship
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={
                                      flagData?.dateOfCitizenship?.length > 0
                                        ? 'redFlagFonts'
                                        : 'MediumBlackFonts'
                                    }
                                  >
                                    {convertISOToDate(data?.dateOfCitizenship)}
                                    {flagData?.dateOfCitizenship?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={flagData?.dateOfCitizenship}
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </StyledTableCell>
                                </TableRow>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Country of Citizenship
                              </StyledTableCell>
                              <TableCell
                                className={
                                  flagData?.citizenshipCountryName?.length > 0
                                    ? 'redFlagFonts'
                                    : 'MediumBlackFonts'
                                }
                                sx={{ display: 'inline-flex', gap: '10px' }}
                              >
                                {data?.citizenshipCountryName?.map(
                                  (
                                    country:
                                      | string
                                      | number
                                      | boolean
                                      | React.ReactElement<
                                          any,
                                          | string
                                          | React.JSXElementConstructor<any>
                                        >
                                      | Iterable<React.ReactNode>
                                      | React.ReactPortal
                                      | null
                                      | undefined,
                                    index: React.Key | null | undefined,
                                  ) => (
                                    <Chip
                                      key={index}
                                      label={country}
                                      size='small'
                                    />
                                  ),
                                )}
                                {flagData?.citizenshipCountryName?.length >
                                0 ? (
                                  <>
                                    <Tooltip
                                      title={flagData?.citizenshipCountryName}
                                    >
                                      <ErrorImage src={error} alt='Error' />
                                    </Tooltip>
                                  </>
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Actively going through the process of becoming a
                                U.S. citizen
                              </StyledTableCell>
                              <StyledTableCell
                                className={
                                  flagData
                                    ?.isGoingThroughProcessOfUSACitizenship
                                    ?.length > 0
                                    ? 'redFlagFonts'
                                    : 'MediumBlackFonts'
                                }
                              >
                                {data?.isGoingThroughProcessOfUSACitizenship
                                  ? 'Yes'
                                  : 'No'}
                                {flagData?.isGoingThroughProcessOfUSACitizenship
                                  ?.length > 0 ? (
                                  <>
                                    <Tooltip
                                      title={
                                        flagData?.isGoingThroughProcessOfUSACitizenship
                                      }
                                    >
                                      <ErrorImage src={error} alt='Error' />
                                    </Tooltip>
                                  </>
                                ) : null}
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Legal resident of the U.S.
                              </StyledTableCell>
                              <StyledTableCell
                                className={
                                  flagData?.isLegalResidentOfUSA?.length > 0
                                    ? 'redFlagFonts'
                                    : 'MediumBlackFonts'
                                }
                              >
                                {data?.isLegalResidentOfUSA ? 'Yes' : 'No'}
                                {flagData?.isLegalResidentOfUSA?.length > 0 ? (
                                  <>
                                    <Tooltip
                                      title={flagData?.isLegalResidentOfUSA}
                                    >
                                      <ErrorImage src={error} alt='Error' />
                                    </Tooltip>
                                  </>
                                ) : null}
                              </StyledTableCell>
                            </TableRow>
                            {data?.isLegalResidentOfUSA === true ? (
                              <>
                                <TableRow>
                                  <StyledTableCell
                                    variant='head'
                                    className='grayFonts'
                                  >
                                    Resident Card number
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={
                                      flagData?.residentCardNumber?.length > 0
                                        ? 'redFlagFonts'
                                        : 'MediumBlackFonts'
                                    }
                                  >
                                    {data?.residentCardNumber}
                                    {flagData?.residentCardNumber?.length >
                                    0 ? (
                                      <>
                                        <Tooltip
                                          title={flagData?.residentCardNumber}
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </StyledTableCell>
                                </TableRow>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <Grid m={2}>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Citizenship
              </Typography>
            </Grid>
            <Divider
              sx={{ width: '95%', marginTop: '16px', marginBottom: '16px' }}
            />
          </Grid>
          <Citizenship handleEditToggle={handleEditToggle} />
        </Grid>
      )}
    </div>
  )
}

export default Component
