import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import ForeignWork from '../EditForms/ForeignWork'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { currentYear, hasEmpty, previousYear } from 'src/Utils/Constants'

const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)

  const foreignWorkRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    foreignWorkRef?.current?.scrollIntoView()
  }
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.ForeignWork,
  )

  const foreignWorkSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Foreign Work',
      )?.sectionId,
  )

  return (
    <div id={foreignWorkSectionId} ref={foreignWorkRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Foreign Work
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Worked outside of the U.S.
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.haveWorkedOutSideUSA ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.haveWorkedOutSideUSA ? (
                    <Box className={Styles.element2}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.textWrapper3}>
                          Work outside of the U.S. for salary, wages, or
                          self-employment in {currentYear}
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {' '}
                          {data?.isSalaryWageOrCurrentYearSelfEmployement
                            ? 'Yes'
                            : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
                {data?.isSalaryWageOrCurrentYearSelfEmployement ? (
                  <>
                    <Box className={Styles.cardDiv}>
                      {/* row-1 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Wages and tips earned in {currentYear}
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper3}
                            >
                              {`$${data?.totalEarnedAmountCurrentYear}`}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Earnings shown on your Social Security statement
                              accurate in {currentYear}
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper3}
                            >
                              {data?.earningShowInSocialSecutiryStatementCurrentYear
                                ? 'Yes'
                                : 'No'}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* row-2 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              All of your earnings subject to U.S. Social
                              Security tax in {currentYear}
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper3}
                            >
                              {data?.earningShowInSocialSecutiryStatementCurrentYear
                                ? 'Yes'
                                : 'No'}
                            </Typography>
                          </Box>
                        </Grid>
                        {data?.earningShowInSocialSecutiryStatementCurrentYear ===
                          false && (
                          <Grid item xs={6} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Months in which 45 hours or more were worked.
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              {data?.usaTaxesNotTakenOutCurrentYearMonths?.map(
                                (item: string) => (
                                  <>
                                    <Typography className={Styles.textWrapper4}>
                                      {item}
                                    </Typography>
                                  </>
                                ),
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Box className={Styles.div2}>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.p}>
                            Work outside of the U.S. for salary, wages, or
                            self-employment during {previousYear}?
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {' '}
                            {data?.isSalaryWageOrLastYearSelfEmployement
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {data?.isSalaryWageOrLastYearSelfEmployement ? (
                      <>
                        <Box className={Styles.cardDiv}>
                          {/* row-1 */}
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Wages and tips earned in {previousYear}
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper3}
                                >
                                  {`$${data?.totalEarnedAmountLastYear}`}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Earnings shown on your Social Security
                                  statement accurate in {previousYear}
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper3}
                                >
                                  {data?.earningShowInSocialSecutiryStatementLastYear
                                    ? 'Yes'
                                    : 'No'}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {/* row-2 */}
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  All of your earnings subject to U.S. Social
                                  Security tax in {previousYear}
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper3}
                                >
                                  {data?.earningShowInSocialSecutiryStatementLastYear
                                    ? 'Yes'
                                    : 'No'}
                                </Typography>
                              </Box>
                            </Grid>
                            {data?.earningShowInSocialSecutiryStatementLastYear ===
                              false && (
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Months in which 45 hours or more were
                                    worked.
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  {data?.usaTaxesNotTakenOutPastYearMonths?.map(
                                    (item: string) => (
                                      <>
                                        <Typography
                                          className={Styles.textWrapper4}
                                        >
                                          {item}
                                        </Typography>
                                      </>
                                    ),
                                  )}
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Eligible for benefits under a foreign Social Security
                        system
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {' '}
                        {data?.eligibleForBenefitsUnderForeignSocialSecuritySystem
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Foreign Work
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <ForeignWork handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
