import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox } from 'src/Components/Fields'
import { PassSignificantType } from 'src/Redux/Services/Types/applicationType'
import { AddPastSignificant } from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { setPassSignificant } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import ProfileBox from './ProfileBox'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const PastSignificant = ({
  handleNext,
  handleBack,
  chatForm,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.PassSignificant,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const [formValues, setFormValues] = useState<PassSignificantType>({
    familyId,
    havePriorMarriages: undefined,
    pastMarriage10Years: undefined,
    isPriorMarriageEndDuetoSpouseDeath: undefined,
    pastSignificantOthers: [
      {
        spouseFirstName: '',
        spouseLastName: '',
        spouseDateOfBirth: '',
        spouseSSN: '',
        typeOfMarriage: '',
        dateOfMarriage: '',
        marriageEndDate: '',
        howMarriageEnded: '',
        placeOfMarriage: {
          city: '',
          state: '',
          other: '',
          country: '',
        },
        placeOfMarriageEnded: {
          city: '',
          state: '',
          other: '',
          country: '',
        },
        pastSOEndInDeath: '',
        pastSODeathDate: '',
      },
    ],
  })
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, familyId })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: detailId,
          data: {
            ...formValues,
            havePriorMarriages:
              formValues?.havePriorMarriages === undefined
                ? 'Boolean'
                : formValues?.havePriorMarriages,
            pastMarriage10Years:
              formValues?.pastMarriage10Years === undefined
                ? 'Boolean'
                : formValues?.pastMarriage10Years,
            isPriorMarriageEndDuetoSpouseDeath:
              formValues?.isPriorMarriageEndDuetoSpouseDeath === undefined
                ? 'Boolean'
                : formValues?.isPriorMarriageEndDuetoSpouseDeath,
          },
        }),
      )
  }, [isBotOpen, detailId])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  const [errors, setErrors] = useState<{
    havePriorMarriages?: string
    pastSignificantOthers: Array<Record<string, string>>
  }>({ havePriorMarriages: '', pastSignificantOthers: [{}] })

  const handleFieldChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    setErrors({ havePriorMarriages: '', pastSignificantOthers: [{}] })
    const { name, value } = data
    let newFormValues = [...formValues?.pastSignificantOthers]
    newFormValues = newFormValues.map((item, i) => {
      if (index === i) return { ...item, [name]: value }
      return item
    })
    setFormValues({ ...formValues, pastSignificantOthers: newFormValues })
    // You may perform validation here and update errors state accordingly
  }
  const handleFieldAddressChange = (
    index: number,
    data: {
      parent: 'placeOfMarriage' | 'placeOfMarriageEnded'
      name: string
      value: string
    },
  ) => {
    setErrors({ havePriorMarriages: '', pastSignificantOthers: [{}] })
    const { name, value, parent } = data
    let newFormValues = [...formValues?.pastSignificantOthers]
    newFormValues = newFormValues.map((item, i) => {
      if (index === i)
        return { ...item, [parent]: { ...item[parent], [name]: value } }
      return item
    })
    setFormValues({ ...formValues, pastSignificantOthers: newFormValues })
  }
  const validateForm = () => {
    let valid = true
    const allErrors: {
      havePriorMarriages?: string | undefined
      pastMarriage10Years?: string | undefined
      isPriorMarriageEndDuetoSpouseDeath?: string | undefined
      pastSignificantOthers: Array<Record<string, string>>
    } = { pastSignificantOthers: [] }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.havePriorMarriages === undefined) {
      allErrors.havePriorMarriages = 'Please select an option'
      valid = false
    }
    if (formValues?.havePriorMarriages) {
      if (formValues?.pastMarriage10Years === undefined) {
        allErrors.pastMarriage10Years = 'Please select an option'
        valid = false
      }
      if (formValues?.isPriorMarriageEndDuetoSpouseDeath === undefined) {
        allErrors.isPriorMarriageEndDuetoSpouseDeath = 'Please select an option'
        valid = false
      }
      for (let i = 0; i < formValues?.pastSignificantOthers?.length; i++) {
        const newErrors: Record<string, string> = {}
        // Validation logic for Full Name
        if (!formValues?.pastSignificantOthers[i]?.spouseFirstName.trim()) {
          newErrors.spouseFirstName = 'First Name is required'
          valid = false
        } else if (
          !/^[A-Za-z ]+$/.test(
            formValues?.pastSignificantOthers[i]?.spouseFirstName.trim(),
          )
        ) {
          newErrors.spouseFirstName = 'First Name must contain only letters'
          valid = false
        }
        if (!formValues?.pastSignificantOthers[i]?.spouseLastName.trim()) {
          newErrors.spouseLastName = 'Last Name is required'
          valid = false
        } else if (
          !/^[A-Za-z ]+$/.test(
            formValues?.pastSignificantOthers[i]?.spouseLastName.trim(),
          )
        ) {
          newErrors.spouseLastName = 'Last Name must contain only letters'
          valid = false
        }
        if (!formValues?.pastSignificantOthers[i]?.spouseSSN.trim()) {
          newErrors.spouseSSN = 'Social Security Number is required'
          valid = false
        }
        if (!formValues?.pastSignificantOthers[i]?.typeOfMarriage.trim()) {
          newErrors.typeOfMarriage = 'Type of Marriage is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[i]?.placeOfMarriage.city.trim()
        ) {
          newErrors.city = 'City is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[
            i
          ]?.placeOfMarriageEnded.city.trim()
        ) {
          newErrors.city2 = 'City is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[i]?.placeOfMarriage.state.trim()
        ) {
          newErrors.state = 'State is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[
            i
          ]?.placeOfMarriageEnded.state.trim()
        ) {
          newErrors.state2 = 'State is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[i]?.placeOfMarriage.country.trim()
        ) {
          newErrors.country = 'Country is required'
          valid = false
        }
        if (
          !formValues?.pastSignificantOthers[
            i
          ]?.placeOfMarriageEnded.country.trim()
        ) {
          newErrors.country2 = 'Country is required'
          valid = false
        }
        if (!formValues?.pastSignificantOthers[i]?.marriageEndDate.trim()) {
          newErrors.marriageEndDate = 'Date of Marriage End is required'
          valid = false
        }
        if (
          formValues?.pastSignificantOthers[i]?.marriageEndDate > currentDate
        ) {
          newErrors.marriageEndDate = 'Date cannot be in the future'
          valid = false
        }
        if (!formValues?.pastSignificantOthers[i]?.howMarriageEnded.trim()) {
          newErrors.howMarriageEnded = 'How Marriage Ended is required'
          valid = false
        }

        allErrors.pastSignificantOthers.push(newErrors)
      }
    }
    setErrors(allErrors)
    return valid
  }

  const handleAdd = () => {
    setErrors({ havePriorMarriages: '', pastSignificantOthers: [{}] })
    if (formValues?.pastSignificantOthers) {
      setFormValues({
        ...formValues,
        pastSignificantOthers: [
          ...formValues?.pastSignificantOthers,
          {
            spouseFirstName: '',
            spouseLastName: '',
            spouseDateOfBirth: '',
            spouseSSN: '',
            typeOfMarriage: '',
            dateOfMarriage: '',
            marriageEndDate: '',
            howMarriageEnded: '',
            placeOfMarriage: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            placeOfMarriageEnded: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            pastSOEndInDeath: '',
            pastSODeathDate: '',
          },
        ],
      })
    } else {
      setFormValues({
        ...formValues,
        pastSignificantOthers: [
          {
            spouseFirstName: '',
            spouseLastName: '',
            spouseDateOfBirth: '',
            spouseSSN: '',
            typeOfMarriage: '',
            dateOfMarriage: '',
            marriageEndDate: '',
            howMarriageEnded: '',
            placeOfMarriage: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            placeOfMarriageEnded: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            pastSOEndInDeath: '',
            pastSODeathDate: '',
          },
        ],
      })
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setPassSignificant(formValues))
      // alert('Form submitted successfully')
      let formValuesToSubmit =
        formValues.havePriorMarriages === true
          ? formValues
          : {
              familyId,
              havePriorMarriages: formValues?.havePriorMarriages,
            }
      if (
        !(
          formValues?.pastMarriage10Years ??
          formValues?.isPriorMarriageEndDuetoSpouseDeath
        )
      ) {
        formValuesToSubmit = {
          ...formValuesToSubmit,
          pastSignificantOthers: [],
        }
      }
      dispatch(AddPastSignificant(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
          }
        },
      )
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading='Now, let’s look at questions related to past significant others.' />

          <CommonRadioBox
            title='Do you have any prior marriages?'
            commonState={formValues?.havePriorMarriages}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                havePriorMarriages: value,
              })
            }}
            YesTitle='I have had prior marriages'
            NoTitle='I do not have any prior marriages'
            error={errors?.havePriorMarriages}
          />

          {formValues?.havePriorMarriages && (
            <>
              <CommonRadioBox
                title='Did any of your prior marriages last 10 years or more?'
                commonState={formValues?.pastMarriage10Years}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    pastMarriage10Years: value,
                  })
                }}
                YesTitle='One of my prior marriages lasted 10 years or more'
                NoTitle='None of my prior marriages lasted 10 years or more'
                error={errors.pastMarriage10Years}
              />

              <CommonRadioBox
                title={`Did any of your prior marriages end due to your spouse's death?`}
                commonState={formValues?.isPriorMarriageEndDuetoSpouseDeath}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    isPriorMarriageEndDuetoSpouseDeath: value,
                  })
                }}
                YesTitle={`One of my prior marriages ended due to my spouse's death`}
                NoTitle={`None of my prior marriages ended due to my spouse's death`}
                error={errors.isPriorMarriageEndDuetoSpouseDeath}
              />
            </>
          )}

          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          {(formValues?.pastMarriage10Years ||
            formValues?.isPriorMarriageEndDuetoSpouseDeath) &&
            formValues?.havePriorMarriages && (
              <>
                <Box gap={'20px'} display={'flex'} flexDirection={'column'}>
                  <Typography className={Style.label}>
                    Spouse details
                  </Typography>
                  {/* address */}
                  {formValues?.pastSignificantOthers?.map((form, index) => (
                    <ProfileBox
                      errors={errors.pastSignificantOthers[index]}
                      key={index}
                      addressHandler={(data: {
                        parent: 'placeOfMarriage' | 'placeOfMarriageEnded'
                        name: string
                        value: string
                      }) => {
                        handleFieldAddressChange(index, data)
                      }}
                      changeHandler={(data) => {
                        handleFieldChange(index, data)
                      }}
                      formValues={form}
                      handleRemove={() => {
                        setFormValues({
                          ...formValues,
                          pastSignificantOthers:
                            formValues?.pastSignificantOthers?.filter(
                              (_, i) => i !== index,
                            ),
                        })
                      }}
                      index={index}
                      setFormValues={(data) => {
                        handleFieldChange(index, data)
                      }}
                    />
                  ))}

                  <AddButton
                    title='Provide more information for each of your spouse'
                    subTitle='For comprehensive representation of your situation, It is important that all
                      spouses be shown here'
                    button='Add Spouse'
                    onClick={handleAdd}
                  />
                </Box>
              </>
            )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default PastSignificant
