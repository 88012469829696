/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from '@mui/material'
import React from 'react'

interface ContactInfo {
  question: string
  answer: any
}
interface Address {
  city: string
  state: string
  country: string
  postalCode: string
  streetAddress: string
}
const AssistanceDetails: React.FC = ({ question }: { question: any }) => {
  // Helper function to format address object into a string
  const formatAddress = (address: any) => {
    const { streetAddress, city, state, postalCode, country } = address
    return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
  }

  const renderAnswer = (item: any) => {
    const { answer, iterable } = item

    if (
      typeof answer === 'object' &&
      answer?.streetAddress &&
      answer?.city &&
      answer?.state &&
      answer?.postalCode &&
      answer?.country
    ) {
      // If it's an address object, format it into a string
      return formatAddress(answer)
    }

    if (typeof answer === 'object' && answer.firstName && answer.lastName) {
      return `${answer.firstName} ${answer.lastName}`
    } else if (
      typeof answer === 'object' &&
      answer.firstName === null &&
      answer.lastName === null
    ) {
      return 'N/A'
    }

    if (typeof answer === 'object') {
      return JSON.stringify(answer, null, 2)
    }

    return answer
  }

  const renderTable = (info: ContactInfo[]) => (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {info.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '30%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px' }}>
                    {item.question || item.iterable}
                  </span>
                </td>
                <td style={{ width: '70%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px', fontWeight: 500 }}>
                    {renderAnswer(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    // <Paper elevation={3} style={{ padding: '16px' }}>
    <Box p={'16px'}>
      <Box
        height={'30px'}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      />
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Assistance Details
          </Typography>
        </Box>
        {renderTable(question?.Assistance_Programs)}
      </Box>
    </Box>
    // </Paper>
  )
}

export default AssistanceDetails
