import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import {
  Button,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
  Name,
  StarText,
} from 'src/Components/Fields'
import Address from 'src/Components/Fields/Address'
import { CurrentSignificantType } from 'src/Redux/Services/Types/applicationType'
import {
  AddCurrentSignificant,
  EditCurrentSignificant,
} from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { setFamilyId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setCurrentSignificant } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { DynamicObj } from 'src/Utils/Constants'

const CurrentSignificant = ({
  handleNext,
  chatForm,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.CurrentSignificant,
  )

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const [SSN, setSSN] = useState<string[]>(['', '', ''])

  const handleSSNChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.match(/^\d*$/)) {
        const newSSN = [...SSN]
        newSSN[index] = event.target.value
        setSSN(newSSN)
      }
    }

  const [formValues, setFormValues] = useState<CurrentSignificantType>({
    havePriorMarriages: undefined,
    applicationId,
    isLegallyMarried: storeValues.isLegallyMarried ?? undefined,
    spouseFirstName: storeValues.spouseFirstName ?? '',
    spouseLastName: storeValues.spouseLastName ?? '',
    spouseDateOfBirth: storeValues.spouseDateOfBirth ?? '',
    spouseSSN: SSN.join(''),
    typeOfMarriage: storeValues.typeOfMarriage ?? '',
    dateOfMarriage: storeValues.dateOfMarriage ?? '',
    placeOfMarriage: {
      city: storeValues.placeOfMarriage.city ?? '',
      state: storeValues.placeOfMarriage.state ?? '',
      country: storeValues.placeOfMarriage.country ?? '',
      other: storeValues.placeOfMarriage.other ?? '',
    },
  })
  const [addressValues, setAddressValues] = useState<DynamicObj>({
    city: storeValues.placeOfMarriage.city ?? '',
    state: storeValues.placeOfMarriage.state ?? '',
    country: storeValues.placeOfMarriage.country ?? '',
  })

  useEffect(() => {
    setSSN([
      storeValues.spouseSSN.slice(0, 3),
      storeValues.spouseSSN.slice(3, 5),
      storeValues.spouseSSN.slice(5),
    ])
  }, [])

  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, applicationId })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: detailId,
          data: {
            ...formValues,
            havePriorMarriages:
              formValues?.havePriorMarriages === undefined
                ? 'Boolean'
                : formValues?.havePriorMarriages,
            isLegallyMarried:
              formValues?.isLegallyMarried === undefined
                ? 'Boolean'
                : formValues?.isLegallyMarried,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm) {
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
      setSSN([
        chatForm?.spouseSSN?.slice(0, 3),
        chatForm?.spouseSSN?.slice(3, 5),
        chatForm?.spouseSSN?.slice(5),
      ])
    }
  }, [chatForm])

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      spouseSSN: SSN.join(''),
    }))
  }, [applicationId, SSN])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = ({ name, value }: { name: string; value: any }) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleChange = (data: { name: string; value?: string | number }) => {
    setAddressValues({ ...addressValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      placeOfMarriage: {
        state: addressValues.state,
        city: addressValues.city,
        country: addressValues.country,
        other: formValues?.placeOfMarriage?.other ?? '',
      },
    })
  }, [addressValues])
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isLegallyMarried === undefined) {
      newErrors.isLegallyMarried = 'Please select an option'
      valid = false
    }

    if (formValues?.isLegallyMarried) {
      if (!formValues?.spouseFirstName?.trim()) {
        newErrors.spouseFirstName = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.spouseFirstName?.trim())) {
        newErrors.spouseFirstName = 'First Name must contain only letters'
        valid = false
      }

      if (!formValues?.spouseLastName?.trim()) {
        newErrors.spouseLastName = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.spouseLastName?.trim())) {
        newErrors.spouseLastName = 'Last Name must contain only letters'
        valid = false
      }

      if (!formValues.spouseDateOfBirth) {
        newErrors.spouseDateOfBirth = 'Date is required'
        valid = false
      }
      if (formValues.spouseDateOfBirth > currentDate) {
        newErrors.spouseDateOfBirth = 'Date cannot be in the future'
        valid = false
      }

      if (!formValues?.spouseSSN?.trim()) {
        newErrors.spouseSSN = 'Social Security Number is required'
        valid = false
      } else if (formValues?.spouseSSN.length < 9) {
        newErrors.spouseSSN = 'Social Security Number must be 9 digits'
        valid = false
      }

      if (
        formValues?.typeOfMarriage &&
        typeof formValues.typeOfMarriage === 'string' &&
        !formValues.typeOfMarriage.trim()
      ) {
        newErrors.typeOfMarriage = 'Type is required'
        valid = false
      }

      if (!formValues?.placeOfMarriage?.city?.trim()) {
        newErrors.city = 'city is required'
        valid = false
      }
      if (!formValues?.placeOfMarriage?.state?.trim()) {
        newErrors.state = 'state is required'
        valid = false
      }

      if (!formValues?.placeOfMarriage.country?.trim()) {
        newErrors.country = 'country is required'
        valid = false
      }

      if (!formValues.dateOfMarriage) {
        newErrors.dateOfMarriage = 'Date is required'
        valid = false
      }

      if (formValues.dateOfMarriage > currentDate) {
        newErrors.dateOfMarriage = 'Date cannot be in the future'
        valid = false
      }

      if (!formValues?.placeOfMarriage.other?.trim()) {
        newErrors.other = 'Description is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setCurrentSignificant(formValues))
      if (!familyId) {
        const formValuesToSubmit = formValues.isLegallyMarried
          ? formValues
          : {
              applicationId,
              isLegallyMarried: formValues.isLegallyMarried,
            }
        dispatch(AddCurrentSignificant(formValuesToSubmit)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              dispatch(setFamilyId(data?.familyId))
              handleNext()
            }
          },
        )
      } else {
        const formValuesToSubmit = formValues.isLegallyMarried
          ? formValues
          : {
              familyId,
              isLegallyMarried: formValues.isLegallyMarried,
            }
        dispatch(
          EditCurrentSignificant({ ...formValuesToSubmit, familyId }),
        ).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            if (res?.payload?.success) {
              handleNext()
            }
          },
        )
      }
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="Hello again! In this section, we'll work through questions related
              to current and past marriages, children, and other dependents who
              you may be supporting, such as parents."
          />

          <InputLabel className={Style.label}>
            Marriage Documentation<span style={{ color: 'red' }}>*</span>
          </InputLabel>

          <CommonRadioBox
            error={errors?.isLegallyMarried}
            title='Are you currently legally married?'
            commonState={formValues?.isLegallyMarried}
            setcommonState={(value: boolean) => {
              setFormValues((prevState) => ({
                ...prevState,
                isLegallyMarried: value,
              }))
            }}
            YesTitle='Yes, I am'
            NoTitle='No, I am not'
          />

          {formValues?.isLegallyMarried && (
            <>
              <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                <Name
                  name='spouseFirstName'
                  value={formValues?.spouseFirstName}
                  title="Current Spouse's First Name"
                  handleChange={handleFieldChange}
                  error={errors.spouseFirstName}
                />
                <Name
                  name='spouseLastName'
                  value={formValues?.spouseLastName}
                  title="Current Spouse's Last Name"
                  handleChange={handleFieldChange}
                  error={errors.spouseLastName}
                />
              </Grid>
              <Calander
                name='spouseDateOfBirth'
                value={formValues?.spouseDateOfBirth}
                title="Current Spouse's Date of Birth (estimate if unknown)"
                handleChange={handleFieldChange}
                error={errors?.spouseDateOfBirth}
              />
              <StarText
                text='This information will not be shared and will be safeguarded
                    24/7.'
              />
              <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                <Grid width={'100%'}>
                  <InputLabel className={Style.label}>
                    Current Spouse's Social Security Number (SSN)
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Grid gap={1} display={'flex'} flexDirection={'row'}>
                    <TextField
                      fullWidth
                      className={Style.AnsTxt}
                      value={SSN[0]}
                      onChange={handleSSNChange(0)}
                      placeholder='###'
                      inputProps={{ maxLength: 3 }}
                      error={!!errors.spouseSSN}
                    />
                    <TextField
                      fullWidth
                      className={Style.AnsTxt}
                      value={SSN[1]}
                      onChange={handleSSNChange(1)}
                      placeholder='##'
                      inputProps={{ maxLength: 2 }}
                      error={!!errors.spouseSSN}
                    />
                    <TextField
                      fullWidth
                      className={Style.AnsTxt}
                      value={SSN[2]}
                      onChange={handleSSNChange(2)}
                      placeholder='####'
                      inputProps={{ maxLength: 4 }}
                      error={!!errors.spouseSSN}
                    />
                  </Grid>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.spouseSSN}
                  </FormHelperText>
                </Grid>
                <CommonDropdown
                  name='typeOfMarriage'
                  value={formValues.typeOfMarriage}
                  label='Type of Marriage'
                  menulist={[
                    {
                      name: 'Marriage by clergy or public official',
                      value: 'Marriage by clergy or public official',
                    },
                    {
                      name: 'Other ceremonial marriage',
                      value: 'Other ceremonial marriage',
                    },
                    {
                      name: 'Common law marriage',
                      value: 'Common law marriage',
                    },
                    {
                      name: 'I do not know, or this information is not  available',
                      value:
                        'I do not know, or this information is not available',
                    },
                  ]}
                  handleChange={handleFieldChange}
                  error={errors.typeOfMarriage}
                />
              </Grid>
              <Calander
                name='dateOfMarriage'
                value={formValues?.dateOfMarriage}
                title='Date of Marriage'
                handleChange={handleFieldChange}
                error={errors?.dateOfMarriage}
              />
              <Typography className={Style.label}>Place of Marriage</Typography>
              {/* <Address
                errors={errors}
                formValues={formValues.placeOfMarriage}
                setFormValues={(data) => {
                  setFormValues({
                    ...formValues,
                    placeOfMarriage: {
                      ...formValues.placeOfMarriage,
                      ...data,
                    },
                  })
                }}
                handleChange={handleFieldChange}
              /> */}

              <Address
                errors={errors}
                formValues={addressValues}
                setFormValues={setAddressValues}
                handleChange={handleChange}
              />

              <Box height={'10px'} width={'100%'} />

              <Name
                name='other'
                value={formValues?.placeOfMarriage.other}
                title='Other'
                handleChange={(data) => {
                  setFormValues({
                    ...formValues,
                    placeOfMarriage: {
                      ...formValues.placeOfMarriage,
                      other: data.value as string,
                    },
                  })
                }}
                error={errors.other}
              />
            </>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              sx={{ visibility: 'hidden' }}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default CurrentSignificant
