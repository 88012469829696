import { Box, Button, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { ApplicationIntroBox, Calander, StarText } from 'src/Components/Fields'
import { WorkTaxDetailsType } from 'src/Redux/Services/Types/applicationType'
import { EditWorkTaxDetails } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setWorkTaxDetails } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../../index.module.scss'

const WorkTaxDetails = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement?.WorkTaxDetails,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<WorkTaxDetailsType>({
    educationId,
    didPaymentPaidAnotherYear: undefined,
    haveWorkedWhereTaxesNotDeducted: undefined,
    isReceivingPensionOrAnnuity: undefined,
    isPensionOrAnnuityBasedOnGovernmentEmployment: undefined,
    isExpectPensionOrAnnuityInFuture: undefined,
    pensionAnnuityRailroadWork: undefined,
    pensionAnnuityFutureGovtWork: undefined,
    pensionAnnuityFutureStartDate: '',
    lumpSumNoTaxes: undefined,
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setErrors({})
  }, [])

  useEffect(() => {
    if (formValues?.haveWorkedWhereTaxesNotDeducted === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        isPensionOrAnnuityBasedOnGovernmentEmployment: undefined,
        isExpectPensionOrAnnuityInFuture: undefined,
        pensionAnnuityRailroadWork: undefined,
        pensionAnnuityFutureGovtWork: undefined,
        pensionAnnuityFutureStartDate: '',
        lumpSumNoTaxes: undefined,
      }))
    }
  }, [formValues?.haveWorkedWhereTaxesNotDeducted])
  // useEffect(() => {
  //   setErrors({ ...errors, state: '', country: '' })
  // }, [formValues.state, formValues.country])

  const handleChange = (data: { name: string; value?: string | boolean }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (formValues.didPaymentPaidAnotherYear === undefined) {
      newErrors.didPaymentPaidAnotherYear = 'Please select an option'
      isValid = false
    }
    if (formValues.haveWorkedWhereTaxesNotDeducted === undefined) {
      newErrors.haveWorkedWhereTaxesNotDeducted = 'Please select an option'
      isValid = false
    }
    if (formValues.haveWorkedWhereTaxesNotDeducted) {
      if (formValues.isReceivingPensionOrAnnuity === undefined) {
        newErrors.isReceivingPensionOrAnnuity = 'Please select an option'
        isValid = false
      }
      if (formValues.isReceivingPensionOrAnnuity) {
        if (
          formValues.isPensionOrAnnuityBasedOnGovernmentEmployment === undefined
        ) {
          newErrors.isPensionOrAnnuityBasedOnGovernmentEmployment =
            'Please select an option'
          isValid = false
        }
        if (formValues.pensionAnnuityRailroadWork === undefined) {
          newErrors.pensionAnnuityRailroadWork = 'Please select an option'
          isValid = false
        }
        if (formValues.isExpectPensionOrAnnuityInFuture === undefined) {
          newErrors.isExpectPensionOrAnnuityInFuture = 'Please select an option'
          isValid = false
        }
        if (formValues.isExpectPensionOrAnnuityInFuture) {
          if (formValues.pensionAnnuityFutureGovtWork === undefined) {
            newErrors.pensionAnnuityFutureGovtWork = 'Please select an option'
            isValid = false
          }
          if (!formValues.pensionAnnuityFutureStartDate?.trim()) {
            newErrors.pensionAnnuityFutureStartDate = 'Please select a date'
            isValid = false
          }
        }
        if (formValues.lumpSumNoTaxes === undefined) {
          newErrors.lumpSumNoTaxes = 'Please select an option'
          isValid = false
        }
      }
    }
    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: WorkTaxDetailsType = {}
      payload.educationId = formValues.educationId
      payload.didPaymentPaidAnotherYear = formValues.didPaymentPaidAnotherYear
      payload.haveWorkedWhereTaxesNotDeducted =
        formValues.haveWorkedWhereTaxesNotDeducted
      if (formValues.haveWorkedWhereTaxesNotDeducted) {
        payload.isReceivingPensionOrAnnuity =
          formValues.isReceivingPensionOrAnnuity
        if (formValues.isReceivingPensionOrAnnuity) {
          payload.isPensionOrAnnuityBasedOnGovernmentEmployment =
            formValues.isPensionOrAnnuityBasedOnGovernmentEmployment
          payload.pensionAnnuityRailroadWork =
            formValues.pensionAnnuityRailroadWork
          payload.isExpectPensionOrAnnuityInFuture =
            formValues.isExpectPensionOrAnnuityInFuture
          if (formValues.isExpectPensionOrAnnuityInFuture) {
            payload.pensionAnnuityFutureGovtWork =
              formValues.pensionAnnuityFutureGovtWork
            payload.pensionAnnuityFutureStartDate =
              formValues.pensionAnnuityFutureStartDate
          }
          payload.lumpSumNoTaxes = formValues.lumpSumNoTaxes
        }
      }
      dispatch(EditWorkTaxDetails(payload)).then((res) => {
        if (res?.payload?.success) {
          dispatch(setWorkTaxDetails(formValues))

          handleEditToggle()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="You're so close to completing this section. Now, we're going to discuss work tax and work-related payment questions. Let's keep going!" />
            <CommonRadioBox
              title='Do any of the total earnings include special payments paid during one year but earned in another?'
              YesTitle='The total earnings include special payments paid during one year but earned in another'
              NoTitle='None of the total earnings include special payments paid during one year but earned in another.'
              commonState={formValues.didPaymentPaidAnotherYear}
              setcommonState={(value) => {
                handleChange({ name: 'didPaymentPaidAnotherYear', value })
              }}
              error={errors.didPaymentPaidAnotherYear}
            />
            <Box height={'40px'} width={'100%'}></Box>
            <CommonRadioBox
              title='Have you ever worked a job in which U.S. Social Security taxes were not deducted or withheld?'
              YesTitle='I have worked a job in which U.S. Social Security taxes were not deducted or withheld'
              NoTitle='I have never worked a job in which U.S. Social Security taxes were not deducted or withheld'
              commonState={formValues.haveWorkedWhereTaxesNotDeducted}
              setcommonState={(value) => {
                handleChange({ name: 'haveWorkedWhereTaxesNotDeducted', value })
              }}
              error={errors.haveWorkedWhereTaxesNotDeducted}
            />
            {formValues.haveWorkedWhereTaxesNotDeducted ? (
              <>
                <Box height={'40px'} width={'100%'}></Box>
                <CommonRadioBox
                  title='Are you or your spouse receiving a pension or annuity based on this work for which taxes were not deducted or withheld?'
                  YesTitle='I am or my spouse is receiving a pension or annuity based on this work for which taxes were not deducted or withheld'
                  NoTitle='I am or my spouse is not receiving a pension or annuity based on this work for which taxes were not deducted or withheld'
                  commonState={formValues.isReceivingPensionOrAnnuity}
                  setcommonState={(value) => {
                    handleChange({ name: 'isReceivingPensionOrAnnuity', value })
                  }}
                  error={errors.isReceivingPensionOrAnnuity}
                />
                <StarText
                  text={`Example: Teachers, railroad workers, federal government workers after 1983, etc.`}
                />
                {formValues.isReceivingPensionOrAnnuity ? (
                  <>
                    <Box height={'40px'} width={'100%'}></Box>
                    <InputLabel className={Style.label}>
                      Is the pension or annuity based on government employment?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <CommonRadioBox
                      title='Based on Government Employment'
                      YesTitle='The pension or annuity is based on government employment'
                      NoTitle='The pension or annuity is not based on government employment'
                      commonState={
                        formValues.isPensionOrAnnuityBasedOnGovernmentEmployment
                      }
                      setcommonState={(value) => {
                        handleChange({
                          name: 'isPensionOrAnnuityBasedOnGovernmentEmployment',
                          value,
                        })
                      }}
                      error={
                        errors.isPensionOrAnnuityBasedOnGovernmentEmployment
                      }
                    />
                    <Box height={'40px'} width={'100%'}></Box>
                    <InputLabel className={Style.label}>
                      Is the pension or annuity based on railroad employment?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <CommonRadioBox
                      title='Based on Railroad Employment'
                      YesTitle='The pension or annuity is based on railroad employment'
                      NoTitle='The pension or annuity is not based on railroad employment'
                      commonState={formValues.pensionAnnuityRailroadWork}
                      setcommonState={(value) => {
                        handleChange({
                          name: 'pensionAnnuityRailroadWork',
                          value,
                        })
                      }}
                      error={errors.pensionAnnuityRailroadWork}
                    />
                    <Box height={'40px'} width={'100%'}></Box>
                    <CommonRadioBox
                      title='Do you expect to receive a pension or annuity in the future for this work, for which taxes were not deducted or withheld?'
                      YesTitle='I do expect to receive a pension or annuity in the future for this work, for which taxes were not deducted or withheld'
                      NoTitle='I do not expect to receive a pension or annuity in the future for this work, for which taxes were not deducted or withheld'
                      commonState={formValues.isExpectPensionOrAnnuityInFuture}
                      setcommonState={(value) => {
                        handleChange({
                          name: 'isExpectPensionOrAnnuityInFuture',
                          value,
                        })
                      }}
                      error={errors.isExpectPensionOrAnnuityInFuture}
                    />
                    {formValues.isExpectPensionOrAnnuityInFuture ? (
                      <>
                        <CommonRadioBox
                          title='We understand. Is the pension or annuity in the future based on government employment?'
                          YesTitle='The pension or annuity in the future is based on government employment'
                          NoTitle='The pension or annuity in the future is not based on government employment'
                          commonState={formValues.pensionAnnuityFutureGovtWork}
                          setcommonState={(value) => {
                            handleChange({
                              name: 'pensionAnnuityFutureGovtWork',
                              value,
                            })
                          }}
                          error={errors.pensionAnnuityFutureGovtWork}
                        />
                        <Calander
                          title={
                            'When is the pension or annuity expected to begin?'
                          }
                          value={formValues.pensionAnnuityFutureStartDate}
                          name={'pensionAnnuityFutureStartDate'}
                          error={errors?.pensionAnnuityFutureStartDate}
                          handleChange={handleChange}
                          future
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Box height={'40px'} width={'100%'}></Box>
                    <CommonRadioBox
                      title='Did you receive a lump sum payment of any kind instead of a pension or annuity for this work for which taxes were not deducted or withheld?'
                      YesTitle='I received a lump sum payment of any kind instead of a pension or annuity for this work for which taxes were not deducted or withheld'
                      NoTitle='I did not receive a lump sum payment of any kind instead of a pension or annuity for this work for which taxes were not deducted or withheld'
                      commonState={formValues.lumpSumNoTaxes}
                      setcommonState={(value) => {
                        handleChange({ name: 'lumpSumNoTaxes', value })
                      }}
                      error={errors.lumpSumNoTaxes}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={() => {
                  handleEditToggle()
                }}
              >
                Discard
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
                // onClick={handleNext}
              >
                Save & Update
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default WorkTaxDetails
