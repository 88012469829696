import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material'
import Style from './index.module.scss'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddCommunicationPreference } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { LanguageOptions } from 'src/Utils/Constants'
import { setCommunicationPreference } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CommonDropdown } from 'src/Components/Fields'

interface CommunicationPreferenceType {
  basicDetailId: string | undefined
  isPreferredLanguageEnglish: boolean | undefined
  preferredReadingLanguage: string
  preferredSpeakingLanguage: string
  preferredWritingLanguage: string
  canReadEnglish: boolean | undefined
  canWriteEnglish: boolean | undefined
  canSpeakEnglish: boolean | undefined
  isAbleToReadWriteMoreThenName: boolean | undefined
}

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const CommunicationPreference = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const CommunicationPreferenceState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.CommunicationPreference,
  )
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const [formValues, setFormValues] = useState<CommunicationPreferenceType>({
    basicDetailId: detailId,
    isPreferredLanguageEnglish: undefined,
    preferredReadingLanguage: '',
    preferredSpeakingLanguage: '',
    preferredWritingLanguage: '',
    canReadEnglish: undefined,
    canWriteEnglish: undefined,
    canSpeakEnglish: undefined,
    isAbleToReadWriteMoreThenName: undefined,
  })
  useEffect(() => {
    if (formValues?.isPreferredLanguageEnglish === true) {
      setFormValues((prevValues) => ({
        ...prevValues,
        preferredReadingLanguage: '',
        preferredSpeakingLanguage: '',
        preferredWritingLanguage: '',
        canReadEnglish: undefined,
        canWriteEnglish: undefined,
        canSpeakEnglish: undefined,
      }))
    }
  }, [formValues?.isPreferredLanguageEnglish])
  const handleOptionClick = (
    option: 'canReadEnglish' | 'canWriteEnglish' | 'canSpeakEnglish',
  ) => {
    setFormValues((prevState) => ({
      ...prevState,
      [`${option}`]: !prevState[`${option}`],
    }))
  }

  useEffect(() => {
    setFormValues({ ...CommunicationPreferenceState, basicDetailId: detailId })
  }, [])

  useEffect(() => {
    if (formValues?.preferredReadingLanguage === 'english') {
      setFormValues((prevState) => ({
        ...prevState,
        canReadEnglish: false,
      }))
    }
    if (formValues?.preferredWritingLanguage === 'english') {
      setFormValues((prevState) => ({
        ...prevState,
        canWriteEnglish: false,
      }))
    }
    if (formValues?.preferredSpeakingLanguage === 'english') {
      setFormValues((prevState) => ({
        ...prevState,
        canSpeakEnglish: false,
      }))
    }
  }, [
    formValues?.preferredReadingLanguage,
    formValues?.preferredWritingLanguage,
    formValues?.preferredSpeakingLanguage,
  ])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (data: {
    name: string
    value: string | number
  }) => {
    const { name, value } = data
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    if (formValues?.isPreferredLanguageEnglish === undefined) {
      newErrors.isPreferredLanguageEnglish = 'Please select an option'
      valid = false
    }
    if (formValues?.isAbleToReadWriteMoreThenName === undefined) {
      newErrors.isAbleToReadWriteMoreThenName = 'Please select an option'
      valid = false
    }
    if (!formValues.isPreferredLanguageEnglish) {
      // Validation logic for Full Name
      if (!formValues.preferredReadingLanguage?.trim()) {
        newErrors.preferredReadingLanguage = 'please select language'
        valid = false
      }
      if (!formValues.preferredSpeakingLanguage?.trim()) {
        newErrors.preferredSpeakingLanguage = 'please select language'
        valid = false
      }
      if (!formValues.preferredWritingLanguage?.trim()) {
        newErrors.preferredWritingLanguage = 'please select language'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit =
      formValues.isPreferredLanguageEnglish === false
        ? formValues
        : {
            basicDetailId: formValues?.basicDetailId,
            isPreferredLanguageEnglish: formValues?.isPreferredLanguageEnglish,
            isAbleToReadWriteMoreThenName:
              formValues?.isAbleToReadWriteMoreThenName,
          }
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(
        AddCommunicationPreference(
          formValuesToSubmit as CommunicationPreferenceType,
        ),
      ).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setCommunicationPreference(formValues))

            handleEditToggle()
          }
        },
      )
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <CommonRadioBox
            error={errors?.isPreferredLanguageEnglish}
            title='Is your preferred language for reading, writing, and speaking English?'
            commonState={formValues.isPreferredLanguageEnglish}
            setcommonState={(value: boolean) => {
              setFormValues((prevState) => ({
                ...prevState,
                isPreferredLanguageEnglish: value,
              }))
            }}
            YesTitle='English is my preferred language for reading, writing, and speaking.'
            NoTitle='English is not my preferred language for reading, writing, and speaking.'
          />

          {formValues.isPreferredLanguageEnglish === false ? (
            <>
              <Box>
                <Typography className={Style.label}>Communication</Typography>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <CommonDropdown
                    label='Preferred language for reading?'
                    name='preferredReadingLanguage'
                    value={formValues.preferredReadingLanguage}
                    menulist={LanguageOptions}
                    handleChange={handleFieldChange}
                    error={errors.preferredReadingLanguage}
                  />
                  <CommonDropdown
                    label='Preferred language for speaking?'
                    name='preferredSpeakingLanguage'
                    value={formValues.preferredSpeakingLanguage}
                    menulist={LanguageOptions}
                    handleChange={handleFieldChange}
                    error={errors.preferredSpeakingLanguage}
                  />
                  <CommonDropdown
                    label='Preferred language for writing?'
                    name='preferredWritingLanguage'
                    value={formValues.preferredWritingLanguage}
                    menulist={LanguageOptions}
                    handleChange={handleFieldChange}
                    error={errors.preferredWritingLanguage}
                  />
                </Grid>
              </Box>

              <Box>
                <Typography className={Style.label}>
                  Select the following options if you read, speak and write
                  English.
                </Typography>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  {formValues.preferredReadingLanguage !== 'English' ? (
                    <Grid
                      onClick={() => {
                        handleOptionClick('canReadEnglish')
                      }}
                      xs={2}
                      className={
                        formValues.canReadEnglish
                          ? Style.button
                          : Style.deSelectButton
                      }
                    >
                      <InputLabel className={Style.label}>Read</InputLabel>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {formValues.preferredSpeakingLanguage !== 'English' ? (
                    <Grid
                      onClick={() => {
                        handleOptionClick('canSpeakEnglish')
                      }}
                      xs={2}
                      className={
                        formValues.canSpeakEnglish
                          ? Style.button
                          : Style.deSelectButton
                      }
                    >
                      <InputLabel className={Style.label}>Speak</InputLabel>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {formValues.preferredWritingLanguage !== 'English' ? (
                    <Grid
                      onClick={() => {
                        handleOptionClick('canWriteEnglish')
                      }}
                      xs={2}
                      className={
                        formValues.canWriteEnglish
                          ? Style.button
                          : Style.deSelectButton
                      }
                    >
                      <InputLabel className={Style.label}>Write</InputLabel>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
              <Box height={'10px'} width={'100%'} />
            </>
          ) : (
            <></>
          )}

          <CommonRadioBox
            error={errors?.isAbleToReadWriteMoreThenName}
            title='Are you able to read and write more than your name?'
            // commonState={readName}
            // setcommonState={setReadName}
            commonState={formValues.isAbleToReadWriteMoreThenName}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isAbleToReadWriteMoreThenName: value,
              })
            }}
            YesTitle='I am able to read and write more than my name.'
            NoTitle='I am not able to read and write more than my name.'
          />

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleEditToggle}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default CommunicationPreference
