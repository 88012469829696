import React, { useState, FC, useEffect, useRef } from 'react'
import Delete from 'src/Assets/Icons/delete.svg'
import Edit from 'src/Assets/Icons/edit.svg'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import TextInput from 'src/Components/Application/CustomInputs/TextInput'
import {
  Box,
  Grid,
  Typography,
  DialogActions,
  Button,
  SelectChangeEvent,
  Divider,
  FormHelperText,
} from '@mui/material'
import Styles from 'src/Pages/Organizations/OrganizationDetails/Tabs/tabs.module.scss'
import {
  AddOrganizationOverview,
  EditOrganizationOverview,
  SetAddOrganization,
  SetAddOrganizationValue,
} from 'src/Redux/Slices/orgSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../stepper.module.scss'

interface PopupFormProps {
  handleNext: () => void
}

const PopupForm: FC<PopupFormProps> = ({ handleNext }) => {
  const dispatch = useAppDispatch()
  const { addOrganization } = useAppSelector((state) => state.organization)
  const [errors, setErrors] = useState({
    bussinessName: '',
    bussinessDescription: '',
    bussinessStreetAddress: '',
    bussinessCity: '',
    bussinessState: '',
    bussinessCountry: '',
    bussinessPostalCode: '',
    bussinessType: '',
    bussinessSpecialization: '',
    bussinessTrackingName: '',
    bussinessProfileUrl: '',
    bussinessPhoneNumber: '',
    bussinessAdminEmailId: '',
    bussinessSecondaryEmailId: '',
  })

  const initialObj = {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  }

  const [formValues, setFormValues] = useState({
    city: addOrganization.bussinessCity ?? '',
    state: addOrganization.bussinessState ?? '',
    zipCode: addOrganization.bussinessPostalCode ?? '',
    country: addOrganization.bussinessCountry ?? '',
    streetAddress: addOrganization.bussinessStreetAddress ?? '',
  })

  interface Place {
    id: string
    description: string
  }
  const inputRef = useRef()
  const [predictions, setPredictions] = useState<Place[]>([])
  const [removeProfileURL, setRemoveProfileURL] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(addOrganization?.profileUrl)
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    addOrganization?.profileUrl || addOrganization?.bussinessProfileUrl,
  )
  const itemStyle = {
    xs: 12,
    sm: 12,
    md: 12,
  }

  const marginStyle = {
    mr: { md: 2, xs: 2 },
  }

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  function fetchPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div'),
    )
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // Access address components
        const addressComponents = place.address_components

        if (addressComponents && addressComponents.length > 0) {
          if (place?.address_components) {
            const addressComponents = place.address_components
            const updatedFormValues = { ...formValues }
            updatedFormValues.streetAddress = ''
            addressComponents.forEach(
              (component: { types; long_name: string }) => {
                const types = component.types
                if (types.includes('street_number')) {
                  updatedFormValues.streetAddress = component.long_name
                } else if (
                  types.includes('route') ||
                  types.includes('sublocality')
                ) {
                  updatedFormValues.streetAddress += ` ${component.long_name}`
                } else if (types.includes('locality')) {
                  updatedFormValues.city = component.long_name
                } else if (types.includes('administrative_area_level_1')) {
                  updatedFormValues.state = component.long_name
                } else if (types.includes('postal_code')) {
                  updatedFormValues.zipCode = component.long_name
                } else if (types.includes('country')) {
                  updatedFormValues.country = component.long_name
                }
              },
            )

            setFormValues(updatedFormValues)
          } else {
            console.error('Invalid place or missing address components')
          }
        }
      }
    })
  }

  const handleFileSelect = (event: SelectChangeEvent<string[]>) => {
    const file = event.target?.files[0]
    if (file) {
      setRemoveProfileURL(false)
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
        const obj = {
          ...addOrganization,
          profileUrl: reader.result,
          removeFile: removeProfileURL,
        }
        dispatch(SetAddOrganization(obj))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleRemovePhoto = () => {
    setSelectedFile(null)
    setPreviewUrl(null)
    setRemoveProfileURL(true)
  }

  useEffect(() => {
    if (!addOrganization?.bussinessProfileUrl?.length) {
      setPreviewUrl(null)
    } else {
      setPreviewUrl(addOrganization.bussinessProfileUrl)
    }
  }, [addOrganization.bussinessProfileUrl])
  const handleValidation = () => {
    const errorsObj = { ...errors }
    errorsObj.bussinessName = addOrganization.bussinessName
      ? ''
      : 'Business name is required'
    errorsObj.bussinessDescription = addOrganization.bussinessDescription
      ? ''
      : 'Business description is required'
    errorsObj.bussinessStreetAddress = addOrganization.bussinessStreetAddress
      ? ''
      : 'Business street address is required'
    errorsObj.bussinessState = addOrganization.bussinessState
      ? ''
      : 'Business state is required'
    errorsObj.bussinessCountry = addOrganization.bussinessCountry
      ? ''
      : 'Business Country is required'
    errorsObj.bussinessType = addOrganization.bussinessType
      ? ''
      : 'Business type is required'
    errorsObj.bussinessSpecialization = addOrganization.bussinessSpecialization
      ? ''
      : 'Business specialization is required'

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      errorsObj.bussinessProfileUrl = 'Please select a file under 5MB in size'
    }
    // Validating business postal code
    if (!addOrganization.bussinessPostalCode) {
      errorsObj.bussinessPostalCode = 'Postal code is required'
    }
    // else if (
    //   !/^\d{5}(?:[-\s]\d{4})?$/.test(addOrganization.bussinessPostalCode)
    // ) {
    //   errorsObj.bussinessPostalCode = 'Invalid postal code'
    // }
    else {
      errorsObj.bussinessPostalCode = ''
    }
    // Validating business email IDs

    if (!addOrganization.bussinessSecondaryEmailId) {
      errorsObj.bussinessSecondaryEmailId = 'Email ID is required'
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        addOrganization.bussinessSecondaryEmailId,
      )
    ) {
      errorsObj.bussinessSecondaryEmailId = 'Invalid email ID'
    } else {
      errorsObj.bussinessSecondaryEmailId = ''
    }

    // Validating business Phone numbers
    if (!addOrganization.bussinessPhoneNumber) {
      errorsObj.bussinessPhoneNumber = 'Phone number is required'
    } else if (!/^\d{10}$/.test(addOrganization.bussinessPhoneNumber)) {
      errorsObj.bussinessPhoneNumber = 'Invalid Phone number'
    } else {
      errorsObj.bussinessPhoneNumber = ''
    }

    if (!addOrganization.bussinessAdminEmailId) {
      errorsObj.bussinessAdminEmailId = 'Email Id is required'
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addOrganization.bussinessAdminEmailId)
    ) {
      errorsObj.bussinessAdminEmailId = 'Invalid Email ID '
    } else {
      errorsObj.bussinessAdminEmailId = ''
    }

    setErrors(errorsObj)
    // }

    return errorsObj
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(prediction.id)
    // You can do additional things here, like setting the selected prediction as the input value
  }

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = event.target

    const key = id
    dispatch(
      SetAddOrganizationValue({
        key,
        value,
      }),
    )
  }
  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      setFormValues({
        ...formValues,
        [name]: value,
      })
      autocomplete(value)
    } else {
      setFormValues(initialObj)
      setPredictions([])
    }
  }

  const areAllErrorsEmpty = (formErrors: {
    [x: string]: string
    bussinessName: string
    bussinessDescription: string
    bussinessStreetAddress: string
    bussinessCity: string
    bussinessState: string
    bussinessCountry: string
    bussinessPostalCode: string
    bussinessType: string
    bussinessSpecialization: string
    bussinessTrackingName: string
    bussinessProfileUrl: string
    bussinessPhoneNumber: string
    bussinessAdminEmailId: string
    bussinessSecondaryEmailId: string
  }) => {
    for (const key in formErrors) {
      if (
        Object.prototype.hasOwnProperty.call(formErrors, key) &&
        formErrors[key] !== ''
      ) {
        return false // If any key has a non-empty value, return false
      }
    }
    return true // If all keys have empty values, return true
  }
  const handleSubmit = () => {
    const formErrors = handleValidation()
    const allErrorsEmpty = areAllErrorsEmpty(formErrors)
    if (allErrorsEmpty) {
      const obj = {
        ...addOrganization,
        bussinessEmailId: addOrganization.bussinessAdminEmailId,
        bussinessProfileUrl: selectedFile,
      }
      dispatch(SetAddOrganization(obj))
      if (addOrganization.uuid.length) {
        dispatch(
          EditOrganizationOverview({
            ...obj,
            uuid: addOrganization.uuid,
          }),
        ).then((res) => {
          if (res.payload?.success) {
            handleNext()
          }
        })
      } else {
        dispatch(AddOrganizationOverview(obj)).then((res) => {
          if (res.payload?.success) {
            handleNext()
          }
        })
      }
    }
  }

  useEffect(() => {
    if (formValues.streetAddress.length) {
      const obj = {
        bussinessStreetAddress: formValues.streetAddress,
        bussinessCity: formValues.city,
        bussinessCountry: formValues.country,
        bussinessState: formValues.state,
        bussinessPostalCode: formValues.zipCode,
      }
      dispatch(SetAddOrganization({ ...addOrganization, ...obj }))
      setPredictions([])
    }
  }, [formValues])

  return (
    <>
      <Box>
        <form>
          <Grid>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography className='stepNumber'>STEP 1 OF 2</Typography>
            </Grid>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Bussiness Information
              </Typography>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Typography className='FontBold'>Logo</Typography>
              <Box component='div' className={Styles.Element} p={2}>
                <Box component='div' className={Styles.Ans}>
                  <Box component='div' className={Styles.EditContactImage}>
                    <Box component='div' className={Styles.EditContactLogo}>
                      <Box component='div' className={Styles.overlapGroup}>
                        <img
                          className={Styles.Rectangle}
                          src={previewUrl ?? ProfilePhoto}
                          alt='Rectangle'
                        />
                        <Box component='div' className={Styles.IconEditWrapper}>
                          <input
                            onChange={handleFileSelect}
                            id='profile-photo-upload'
                            type='file'
                            accept='image/jpeg, image/png'
                            style={{ cursor: 'pointer' }}
                            className={Styles.inputFile}
                          />
                          <img
                            src={Edit}
                            className={Styles.EditIcon}
                            alt='Rectangle'
                          />
                        </Box>
                        <Box
                          component='div'
                          className={Styles.IconDeleteWrapper}
                        >
                          <img
                            src={Delete}
                            onClick={handleRemovePhoto}
                            alt='Rectangle'
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box component='div' className={Styles.Description}>
                      <Typography className={Styles.DescriptionP}>
                        Allowed file types: png, jpg, jpeg.
                      </Typography>
                    </Box>
                    <FormHelperText
                      style={{ padding: '8px', fontSize: '14px' }}
                    >
                      {errors.bussinessProfileUrl}
                    </FormHelperText>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  label={'Bussiness Name'}
                  required={true}
                  placeholder={'Business Name'}
                  id='bussinessName'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessName}
                  error={!!errors.bussinessName}
                  helperText={errors.bussinessName ? errors.bussinessName : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  type='number'
                  label={'Business Phone number'}
                  id='bussinessPhoneNumber'
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 10 &&
                      /^\d*$/.test(e.target.value)
                    )
                      handleTextChange(e)
                  }}
                  value={addOrganization.bussinessPhoneNumber}
                  inputProps={{ maxLength: 10 }} // Set maximum length
                  placeholder={'Business Phone number'}
                  error={!!errors.bussinessPhoneNumber}
                  helperText={
                    errors.bussinessPhoneNumber
                      ? errors.bussinessPhoneNumber
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  fullWidth
                  type='text'
                  required={true}
                  label={'Street Address'}
                  id='bussinessStreetAddress'
                  name='streetAddress'
                  className={Style.AnsTxt}
                  placeholder='Street Address'
                  value={formValues?.streetAddress}
                  onChange={handleAddressChange}
                  error={!!errors.bussinessStreetAddress}
                  helperText={
                    errors.bussinessStreetAddress
                      ? errors.bussinessStreetAddress
                      : ''
                  }
                  inputRef={inputRef}
                />
              </Grid>
            </Grid>
            {predictions.length ? (
              <Grid item md={12} xs={12} className='form-row-item'>
                <Grid
                  item
                  {...itemStyle}
                  {...marginStyle}
                  className={Style.listBox}
                >
                  {predictions.map((prediction, index) => (
                    <>
                      <div
                        key={index}
                        className={Style.listItem}
                        onClick={() => {
                          handlePredictionClick(prediction)
                        }}
                      >
                        {prediction.description}
                      </div>
                      <Divider />
                    </>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid container className='form-row-container'>
            <Grid item md={4} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessState'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessState}
                  placeholder={'State'}
                  label={'State'}
                  error={!!errors.bussinessState}
                  helperText={
                    errors.bussinessState ? errors.bussinessState : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessPostalCode'
                  inputProps={{ maxLength: 6 }} // Set maximum length
                  onChange={handleTextChange}
                  value={addOrganization.bussinessPostalCode}
                  placeholder={'Zip Code'}
                  label={'Zip Code'}
                  error={!!errors.bussinessPostalCode}
                  helperText={
                    errors.bussinessPostalCode ? errors.bussinessPostalCode : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessCountry'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessCountry}
                  placeholder={'Country'}
                  label={'Country'}
                  error={!!errors.bussinessCountry}
                  helperText={
                    errors.bussinessCountry ? errors.bussinessCountry : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessDescription'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessDescription}
                  placeholder={'Organization Description'}
                  label={'Organization Description'}
                  error={!!errors.bussinessDescription}
                  helperText={
                    errors.bussinessDescription
                      ? errors.bussinessDescription
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            {/* <Grid item md={6} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessTrackingName'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessTrackingName}
                  placeholder={'Tracking Name'}
                  label={'Tracking Name'}
                  error={!!errors.bussinessTrackingName}
                  helperText={
                    errors.bussinessTrackingName
                      ? errors.bussinessTrackingName
                      : ''
                  }
                />
              </Grid>
            </Grid> */}
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessType'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessType}
                  placeholder={'Type'}
                  label={'Type'}
                  error={!!errors.bussinessType}
                  helperText={errors.bussinessType ? errors.bussinessType : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessSpecialization'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessSpecialization}
                  placeholder={'Specialization'}
                  label={'Specialization'}
                  error={!!errors.bussinessSpecialization}
                  helperText={
                    errors.bussinessSpecialization
                      ? errors.bussinessSpecialization
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={6} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessAdminEmailId'
                  onChange={handleTextChange}
                  value={addOrganization.bussinessAdminEmailId}
                  label={'E- mail Address of Admin'}
                  placeholder={'E- mail Address of Admin'}
                  error={!!errors.bussinessAdminEmailId}
                  helperText={
                    errors.bussinessAdminEmailId
                      ? errors.bussinessAdminEmailId
                      : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  required={true}
                  id='bussinessSecondaryEmailId'
                  onChange={handleTextChange}
                  error={!!errors.bussinessSecondaryEmailId}
                  helperText={
                    errors.bussinessSecondaryEmailId
                      ? errors.bussinessSecondaryEmailId
                      : ''
                  }
                  value={addOrganization.bussinessSecondaryEmailId}
                  placeholder={'Alternate Email Address'}
                  label={'Alternate Email Address'}
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            {/* <Box className={Style.AddButton}>
              <Button
                variant='contained'
                type='submit'
                className={Style.Button}
              >
                Add
              </Button>
            </Box> */}
          </DialogActions>
        </form>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />

        <>
          <Button
            sx={{ mr: 1 }}
            className={Style.Button}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </>
      </Box>
    </>
  )
}

export default PopupForm
