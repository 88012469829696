/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

interface ContactInfo {
  question: string
  answer: any
}
interface Address {
  city: string
  state: string
  country: string
  postalCode: string
  streetAddress: string
}
const FamilyDetailsTable: React.FC = ({ question }: { question: any }) => {
  // Common function to format dates from 'YYYY-MM-DD' to 'DD MMMM YYYY'
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
    const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
  }

  // Function to handle boolean checkbox display
  const renderBooleanCheckbox = (answer: boolean) => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={answer === true} disabled />}
          label='Yes'
        />
        <FormControlLabel
          control={<Checkbox checked={answer === false} disabled />}
          label='No'
        />
      </FormGroup>
    )
  }

  // Function to render military service information or any iterable answer in tabular format
  const renderIterableAnswer = (answer: any[], iterable: string) => {
    if (iterable === 'dependents') {
      return (
        <>
          <TableContainer>
            <Table>
              <TableBody>
                {answer.map((service, index) => (
                  <React.Fragment key={index}>
                    {/* Title row for each spouse's details */}
                    <TableRow>
                      <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: '#f5f5f5',
                            padding: '10px',
                          }}
                        >
                          Dependent Details {index + 1}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {/* Data rows for each spouse */}
                    <TableRow>
                      <TableCell>Dependent Name</TableCell>
                      <TableCell>
                        {service.firstName + ' ' + service.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Is Younger Than 17</TableCell>
                      <TableCell>
                        {service.isYoungerThan17 === true
                          ? 'Yes'
                          : service.isYoungerThan17 === false
                            ? 'No'
                            : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Is Child Unmarried</TableCell>
                      <TableCell>
                        {service.isChildMarried === true
                          ? 'Yes'
                          : service.isChildMarried === false
                            ? 'No'
                            : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Is Child Disabled</TableCell>
                      <TableCell>
                        {service.isChildDisabled === true
                          ? 'Yes'
                          : service.isChildDisabled === false
                            ? 'No'
                            : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Is Child 18/19 in School Unmarried</TableCell>
                      <TableCell>
                        {service.isChild1819SchoolUnmarried === true
                          ? 'Yes'
                          : service.isChild1819SchoolUnmarried === false
                            ? 'No'
                            : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Is Child Disabled Before Age 22</TableCell>
                      <TableCell>
                        {service.isChildDisabledBeforeAge22 === true
                          ? 'Yes'
                          : service.isChildDisabledBeforeAge22 === false
                            ? 'No'
                            : 'N/A'}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )
    }
    if (iterable === 'pastSignificantOthers') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each spouse's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Spouse Details {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each spouse */}
                  <TableRow>
                    <TableCell>Spouse Name</TableCell>
                    <TableCell>
                      {service.spouseFirstName + ' ' + service.spouseLastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spouse Date of Birth</TableCell>
                    <TableCell>
                      {formatDate(service.spouseDateOfBirth)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spouse SSN</TableCell>
                    <TableCell>{service.spouseSSN}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type of Marriage</TableCell>
                    <TableCell>{service.typeOfMarriage}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date of Marriage</TableCell>
                    <TableCell>{formatDate(service.dateOfMarriage)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Marriage End Date</TableCell>
                    <TableCell>{formatDate(service.marriageEndDate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Place of Marriage</TableCell>
                    <TableCell>
                      {service.placeOfMarriage.city +
                        ', ' +
                        service.placeOfMarriage.state +
                        ', ' +
                        service.placeOfMarriage.country}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Place of Marriage Other</TableCell>
                    <TableCell>{service.placeOfMarriage.other}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>How Marriage Ended</TableCell>
                    <TableCell>{service.howMarriageEnded}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Place of Marriage Ended</TableCell>
                    <TableCell>
                      {service.placeOfMarriageEnded.city +
                        ', ' +
                        service.placeOfMarriageEnded.state +
                        ', ' +
                        service.placeOfMarriageEnded.country}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Place of Marriage Ended Other</TableCell>
                    <TableCell>{service.placeOfMarriageEnded.other}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spouse Deceased After Marriage Ended?</TableCell>
                    <TableCell>
                      {service?.pastSOEndInDeath === ''
                        ? 'N/A'
                        : service?.pastSOEndInDeath}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Spouse Death Date After Marriage Ended
                    </TableCell>
                    <TableCell>
                      {service?.pastSODeathDate === ''
                        ? 'N/A'
                        : service?.pastSODeathDate}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    // Add logic here for future iterable arrays if needed
    return null // Return null if no matching iterable
  }

  // Helper function to format address object into a string
  const formatAddress = (address: any) => {
    const { streetAddress, city, state, postalCode, country } = address
    return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
  }

  const renderAnswer = (item: any) => {
    const { answer, iterable } = item
    if (typeof answer === 'boolean') {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === true}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>Yes</Typography>}
            style={{ color: 'black' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === false}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>No</Typography>}
            style={{ color: 'black' }}
          />
        </FormGroup>
      )
    }

    const datePattern = /^\d{4}-\d{2}-\d{2}$/
    if (typeof answer === 'string' && datePattern.test(answer)) {
      // Convert the date string into a Date object
      const date = new Date(answer)

      // Format the date as 'DD MMMM YYYY' (e.g., '04 July 2018')
      const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
      const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
      const year = date.getFullYear()

      return `${day} ${month} ${year}` // Return the formatted date
    }

    if (Array.isArray(answer) && iterable) {
      // Handle iterable answers (like MilitaryServiceInformation)
      return renderIterableAnswer(answer, iterable)
    }

    if (answer?.city && answer?.state && answer?.country) {
      return `${answer?.city}, ${answer?.state}, ${answer?.country}`
    }

    if (
      typeof answer === 'object' &&
      answer?.streetAddress &&
      answer?.city &&
      answer?.state &&
      answer?.postalCode &&
      answer?.country
    ) {
      // If it's an address object, format it into a string
      return formatAddress(answer)
    }

    if (
      typeof answer === 'object' &&
      answer.suffix &&
      answer.firstName &&
      answer.middleName &&
      answer.lastName
    ) {
      return `${answer.suffix} ${answer.firstName} ${answer.middleName} ${answer.lastName}`
    }
    if (
      typeof answer === 'object' &&
      answer.spouseFirstName &&
      answer.spouseLastName
    ) {
      return `${answer.spouseFirstName} ${answer.spouseLastName}`
    }

    if (typeof answer === 'object' && Object.keys(answer).length === 0) {
      return 'N/A'
    }

    if (typeof answer === 'object') {
      return JSON.stringify(answer, null, 2)
    }

    return answer
  }

  const renderTable = (info: ContactInfo[]) => (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {info.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '30%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px' }}>
                    {item.question || item.iterable}
                  </span>
                </td>
                <td style={{ width: '70%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px', fontWeight: 500 }}>
                    {renderAnswer(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    // <Paper elevation={3} style={{ padding: '16px' }}>
    <Box p={'16px'}>
      <Box mb={4}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Typography
            fontSize={'22px'}
            textAlign={'center'}
            fontWeight={'bold'}
            gutterBottom
          >
            Family Details
          </Typography>
        </Grid>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Current Significant Other
          </Typography>
        </Box>
        {renderTable(question?.Current_Significant)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Pass Significant Other
          </Typography>
        </Box>
        {renderTable(question?.Past_Significant)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Depedents
          </Typography>
        </Box>
        {renderTable(question?.Dependents)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Parents
          </Typography>
        </Box>
        {renderTable(question?.Parents)}
      </Box>
    </Box>
    // </Paper>
  )
}

export default FamilyDetailsTable
