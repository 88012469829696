import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import HospitalMedicalFacilityCare from '../EditForms/HospitalMedicalFacilityCare'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
const MedicalDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const medicalFacilityRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    medicalFacilityRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.HospitalAndMedicalFacilityCare,
  )

  const hospitalOrMedicalFacilityCareSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) =>
          step.label === 'Hospital or Medical Facility Care',
      )?.sectionId,
  )

  return (
    <div id={hospitalOrMedicalFacilityCareSectionId} ref={medicalFacilityRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Hospital or Medical Facility Care
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Seen a medical professional or been to a medical
                        facility for an emergency room visit
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.haveEmergencyRoomVisit ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.haveEmergencyRoomVisit ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Added medical Facilities
                      </Typography>
                    </Box>
                    {/* card-1 */}
                    {data?.haveEmergencyRoomVisitMedicalFacility?.map(
                      (
                        item: {
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          phoneNumber: string | undefined
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                          treatmentType: string[]
                          typeOfTherapyDoctorProvided: string[]
                          hospitalName: string
                          otherTreatment: string
                          otherTypeOfTherapyDetails: string
                          specialProcedureDetail: string
                        },
                        index: React.Key | null | undefined,
                      ) => (
                        <>
                          <Box className={Styles.card} key={index}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Hospital or Medical Clinic Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.hospitalName}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      City/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Zip Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Arrived at Facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left the Facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Treatment(s) you received at this facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    {item?.treatmentType?.length ? (
                                      item?.treatmentType.map(
                                        (subItem: string, index: number) => (
                                          <Typography
                                            variant='body1'
                                            className={Styles.textWrapper4}
                                            key={index}
                                          >
                                            {subItem}
                                          </Typography>
                                        ),
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                </Grid>
                                {/* If treatmentType includes 'Therapy' */}
                                {item?.treatmentType?.includes('Therapy') && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={4}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          Therapy that this Medical professional
                                          provided or ordered
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.typeOfTherapyDoctorProvided
                                          ?.length ? (
                                          item?.typeOfTherapyDoctorProvided.map(
                                            (
                                              subItem: string,
                                              index: number,
                                            ) => (
                                              <Typography
                                                variant='body1'
                                                className={Styles.textWrapper4}
                                                key={index}
                                              >
                                                {subItem}
                                              </Typography>
                                            ),
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If therapy includes 'Other' */}
                                {item?.typeOfTherapyDoctorProvided?.includes(
                                  'Other',
                                ) && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          therapy, please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.otherTypeOfTherapyDetails ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.otherTypeOfTherapyDetails}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If treatmentType includes 'Other' */}
                                {item?.treatmentType?.includes('Other') && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          treatment, please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.otherTreatment ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.otherTreatment}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                {item?.treatmentType?.includes(
                                  'Special procedures (e.g., steroid injections)',
                                ) && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          special procedure (e.g., steroid
                                          injections), please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.specialProcedureDetail ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.specialProcedureDetail}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You seen a medical professional or been to a medical
                          facility for any mental health treatment or
                          evaluations.
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveMentalHeathTreatment ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.haveMentalHeathTreatment ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.haveMentalHeathTreatmentMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You have seen a medical professional or been to a
                          medical facility for any inpatient or overnight stays.
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveInpatientOrOverNightStay ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.haveInpatientOrOverNightStay ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.haveInpatientOrOverNightStayMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You have seen a medical professional or been to a
                          medical facility for any medical tests or medications
                          ordered by this facility
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveMedicalFacility ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.haveMedicalFacility ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.haveMedicalFacilityMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You seen a medical professional or been to a medical
                          facility for any outpatient or same-day visits.
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveOutpatientOrSamedayVisit ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.haveOutpatientOrSamedayVisit ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.haveOutpatientOrSamedayVisitMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You seen a medical professional or been to a medical
                          facility for any surgeries.
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveSurgeries ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.haveSurgeries ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.haveSurgeriesMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                {/* ************************************* */}

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          You seen a medical professional or been to a medical
                          facility for any physical therapy.
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.havePhysicalTherapy ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {data?.havePhysicalTherapy ? (
                    <>
                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Added medical Facilities
                        </Typography>
                      </Box>
                      {/* card-1 */}
                      {data?.havePhysicalTherapyMedicalFacility?.map(
                        (
                          item: {
                            address: {
                              streetAddress: string
                              city: string
                              state: string
                              postalCode: string
                              country: string
                            }
                            phoneNumber: string | undefined
                            arrivedDate: string | number | Date
                            leftDate: string | number | Date
                            treatmentType: string[]
                            typeOfTherapyDoctorProvided: string[]
                            hospitalName: string
                            otherTreatment: string
                            otherTypeOfTherapyDetails: string
                            specialProcedureDetail: string
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Hospital or Medical Clinic Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.hospitalName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Arrived at Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.arrivedDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Date Left the Facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(item?.leftDate)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment(s) you received at this
                                        facility
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.length ? (
                                        item?.treatmentType.map(
                                          (subItem: string, index: number) => (
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                              key={index}
                                            >
                                              {subItem}
                                            </Typography>
                                          ),
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </Grid>
                                  {/* If treatmentType includes 'Therapy' */}
                                  {item?.treatmentType?.includes('Therapy') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Therapy that this Medical
                                            professional provided or ordered
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.typeOfTherapyDoctorProvided
                                            ?.length ? (
                                            item?.typeOfTherapyDoctorProvided.map(
                                              (
                                                subItem: string,
                                                index: number,
                                              ) => (
                                                <Typography
                                                  variant='body1'
                                                  className={
                                                    Styles.textWrapper4
                                                  }
                                                  key={index}
                                                >
                                                  {subItem}
                                                </Typography>
                                              ),
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If therapy includes 'Other' */}
                                  {item?.typeOfTherapyDoctorProvided?.includes(
                                    'Other',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            therapy, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTypeOfTherapyDetails ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTypeOfTherapyDetails}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Other' */}
                                  {item?.treatmentType?.includes('Other') && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            treatment, please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.otherTreatment ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                  {item?.treatmentType?.includes(
                                    'Special procedures (e.g., steroid injections)',
                                  ) && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            If you received any other type of
                                            special procedure (e.g., steroid
                                            injections), please explain:
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          {item?.specialProcedureDetail ? (
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper3
                                              }
                                            >
                                              {item?.specialProcedureDetail}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                </>

                {/* ******************************** */}
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Doctors in a prison or jail
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isDoctorInJail ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {data?.isDoctorInJail ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Details of the doctors in a prison or jail.
                      </Typography>
                    </Box>
                    {/* card-3 */}
                    {data?.jailDoctorDetail?.map(
                      (
                        item: {
                          organizationName: string | undefined
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string | undefined
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Public welfare
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isPublicWelfare ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {data?.isPublicWelfare ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Details of the public welfare.
                      </Typography>
                    </Box>
                    {/* card-3 */}
                    {data?.welfareDetail?.map(
                      (
                        item: {
                          organizationName: string | undefined
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string | undefined
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Records held by an attorney/lawyer
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isRecordHeldByAttorney ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {data?.isRecordHeldByAttorney ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Details of the attorney/lawyer that has your medical
                        records.
                      </Typography>
                    </Box>
                    {/* card-3 */}
                    {data?.attorneyDetail?.map(
                      (
                        item: {
                          organizationName: string | undefined
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string | undefined
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Short-term or long-term disability insurance
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.haveDisabilityInsurance ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.haveDisabilityInsurance ? (
                  <>
                    {data?.insuranceDetail?.map(
                      (
                        item: {
                          hospitalName: string
                          otherTreatment: string
                          otherTypeOfTherapyDetails: string
                          specialProcedureDetail: string
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          phoneNumber: string
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                          treatmentType: string[]
                          typeOfTherapyDoctorProvided: string[]
                        },
                        index: React.Key | null | undefined,
                      ) => (
                        <>
                          <Box className={Styles.card} key={index}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Hospital or Medical Clinic Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.hospitalName}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      City/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Zip Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Arrived at Facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left the Facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Treatment(s) you received at this facility
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    {item?.treatmentType?.length ? (
                                      item?.treatmentType.map(
                                        (subItem: string, index: number) => (
                                          <Typography
                                            variant='body1'
                                            className={Styles.textWrapper4}
                                            key={index}
                                          >
                                            {subItem}
                                          </Typography>
                                        ),
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                </Grid>
                                {item?.treatmentType?.includes('Therapy') && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={4}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          Therapy that this Medical professional
                                          provided or ordered
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.typeOfTherapyDoctorProvided
                                          ?.length ? (
                                          item?.typeOfTherapyDoctorProvided.map(
                                            (
                                              subItem: string,
                                              index: number,
                                            ) => (
                                              <Typography
                                                variant='body1'
                                                className={Styles.textWrapper4}
                                                key={index}
                                              >
                                                {subItem}
                                              </Typography>
                                            ),
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If therapy includes 'Other' */}
                                {item?.typeOfTherapyDoctorProvided?.includes(
                                  'Other',
                                ) && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          therapy, please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.otherTypeOfTherapyDetails ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.otherTypeOfTherapyDetails}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If treatmentType includes 'Other' */}
                                {item?.treatmentType?.includes('Other') && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          treatment, please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.otherTreatment ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.otherTreatment}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* If treatmentType includes 'Special procedures (e.g., steroid injections)' */}
                                {item?.treatmentType?.includes(
                                  'Special procedures (e.g., steroid injections)',
                                ) && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={12}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          If you received any other type of
                                          special procedure (e.g., steroid
                                          injections), please explain:
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.specialProcedureDetail ? (
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper3}
                                          >
                                            {item?.specialProcedureDetail}
                                          </Typography>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Vocational rehabilitation services
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isVocationalRehabilitationService ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isVocationalRehabilitationService ? (
                  <>
                    {data?.rehabilitationOrganizationDetail?.map(
                      (
                        item: {
                          organizationName: string
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Workers' compensation
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isWorkersCompensation ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {data?.isWorkersCompensation ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Details of the workers' compensation organization that
                        has your medical records.
                      </Typography>
                    </Box>
                    {/* card-3 */}
                    {data?.workersCompensationDetail?.map(
                      (
                        item: {
                          organizationName: string | undefined
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string | undefined
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Other (Excluding private health insurance)
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isOtherHaveMedicalRecords ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isOtherHaveMedicalRecords ? (
                  <>
                    {data?.otherOrganizationDetail?.map(
                      (
                        item: {
                          organizationName: string
                          address: {
                            streetAddress: string | undefined
                            city: string | undefined
                            state: string | undefined
                            postalCode: string | undefined
                            country: string | undefined
                          }
                          contactPersonFirstName: any
                          contactPersonLastName: any
                          phoneNumber: string
                          arrivedDate: string | number | Date
                          leftDate: string | number | Date
                        },
                        index: any,
                      ) => (
                        <>
                          <Box className={Styles.card}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Organization name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.organizationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      city/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      ZIP Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Contact Person's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {`${item?.contactPersonFirstName} ${item?.contactPersonLastName}`}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date Arrived
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.arrivedDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Date Left
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(item?.leftDate)}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Hospital or Medical Facility Care
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <HospitalMedicalFacilityCare
                    handleEditToggle={handleEditToggle}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
