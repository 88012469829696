import { Box, Button, Grid, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
  Name,
} from 'src/Components/Fields'
import { SpecialEducationType } from 'src/Redux/Services/Types/applicationType'
import { EditSpecialEducation } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import {
  haveReceivedSpecialEducation,
  setSpecialEducation,
} from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../../index.module.scss'
import { GradeList } from '../../applicationConst'
import Address from 'src/Components/Fields/Address'
import { RootState } from 'src/Redux/store'

const SpecialEducation = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  // const storeValues = useAppSelector(
  //   (state) =>
  //     state?.applicationStore?.EducationAndEmployement?.SpecialEducation,
  // )

  const eduData = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement,
  )

  const storeValues = eduData?.SpecialEducation

  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<SpecialEducationType>({
    educationId,
    haveReceivedSpecialEducation: undefined,
    startDate: '',
    endDate: '',
    lastGrade: '',
    reasonForSpecialEducation: '',
    schoolName: '',
    city: '',
    state: '',
    country: '',
  })
  const [errors, setErrors] = useState<SpecialEducationType>({})

  useEffect(() => {
    setFormValues({
      ...storeValues,
      haveReceivedSpecialEducation: eduData.haveReceivedSpecialEducation,
      educationId,
    })
    setErrors({})
  }, [])

  useEffect(() => {
    if (formValues?.haveReceivedSpecialEducation === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        startDate: '',
        endDate: '',
        lastGrade: '',
        reasonForSpecialEducation: '',
        schoolName: '',
        city: '',
        state: '',
        country: '',
      }))
    }
  }, [formValues?.haveReceivedSpecialEducation])

  useEffect(() => {
    setErrors({ ...errors, state: '', country: '' })
  }, [formValues.state, formValues.country])

  const handleChange = (data: {
    name: string
    value?: string | boolean | number
  }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (!formValues.haveReceivedSpecialEducation === undefined) {
      newErrors.haveReceivedSpecialEducation = 'Please select an option'
      isValid = false
    }
    if (formValues.haveReceivedSpecialEducation) {
      if (!formValues.startDate) {
        newErrors.startDate = 'Please select Date'
        isValid = false
      }
      if (formValues.startDate > currentDate) {
        newErrors.startDate = 'Please select valid Date'
        isValid = false
      }
      if (!formValues.endDate) {
        newErrors.endDate = 'Please select Date'
        isValid = false
      }
      if (formValues.endDate > currentDate) {
        newErrors.endDate = 'Please select valid Date'
        isValid = false
      }
      if (!formValues.lastGrade) {
        newErrors.lastGrade = 'Please select an option'
        isValid = false
      }
      if (!formValues.reasonForSpecialEducation) {
        newErrors.reasonForSpecialEducation = 'Please enter reason'
        isValid = false
      }
      if (!formValues.schoolName) {
        newErrors.schoolName = 'Please enter school name'
        isValid = false
      }
      if (!formValues.city) {
        newErrors.city = 'Please enter city'
        isValid = false
      }
      if (!formValues.state) {
        newErrors.state = 'Please enter state'
        isValid = false
      }
      if (!formValues.country) {
        newErrors.country = 'Please enter country'
        isValid = false
      }
    }
    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: SpecialEducationType = {}
      payload.educationId = formValues.educationId
      payload.haveReceivedSpecialEducation =
        formValues.haveReceivedSpecialEducation
      if (formValues.haveReceivedSpecialEducation) {
        payload.startDate = formValues.startDate
        payload.endDate = formValues.endDate
        payload.lastGrade = formValues.lastGrade
        payload.reasonForSpecialEducation = formValues.reasonForSpecialEducation
        payload.schoolName = formValues.schoolName
        payload.city = formValues.city
        payload.state = formValues.state
        payload.country = formValues.country
      }
      dispatch(EditSpecialEducation(payload)).then((res) => {
        if (res?.payload?.success) {
          dispatch(setSpecialEducation(formValues))
          dispatch(
            haveReceivedSpecialEducation(
              formValues.haveReceivedSpecialEducation,
            ),
          )

          handleEditToggle()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading='Now, we need to gather details related to special education.' />
            <CommonRadioBox
              title='Have you ever received special education through an Individualized Education Plan (IEP) or similar program?'
              YesTitle='I have received special education through an Individualized Education Plan (IEP) or similar program'
              NoTitle='I have not received special education through an Individualized Education Plan (IEP) or similar program'
              commonState={formValues.haveReceivedSpecialEducation}
              setcommonState={(value) => {
                handleChange({ name: 'haveReceivedSpecialEducation', value })
              }}
            />
            {formValues.haveReceivedSpecialEducation === true && (
              <>
                <Box height={'40px'} width={'100%'}></Box>
                <InputLabel className={Style.label}>
                  Please provide the dates on which you started and ended this
                  program.<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Calander
                    name='startDate'
                    value={formValues.startDate}
                    handleChange={handleChange}
                    error={errors.startDate}
                    title='Start Date (Estimate if unknown)'
                  />
                  <Calander
                    name='endDate'
                    value={formValues.endDate}
                    handleChange={handleChange}
                    error={errors.endDate}
                    title='End Date (Estimate if unknown)'
                    minDate={formValues.startDate}
                  />
                </Grid>
                <CommonDropdown
                  handleChange={handleChange}
                  label='Select the last grade that you received special education'
                  menulist={GradeList}
                  name='lastGrade'
                  value={formValues.lastGrade ?? ''}
                  error={errors?.lastGrade}
                />
                <Name
                  name='reasonForSpecialEducation'
                  title='Please tell us why the IEP or equivalent education was recommended'
                  multiline
                  value={formValues.reasonForSpecialEducation ?? ''}
                  handleChange={handleChange}
                  error={errors?.reasonForSpecialEducation}
                />
                <Box height={'40px'} width={'100%'}></Box>
                <InputLabel className={Style.label}>
                  When special education was last received, what school was it?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Name
                  title='School Name'
                  value={formValues.schoolName}
                  name='schoolName'
                  handleChange={handleChange}
                  error={errors?.schoolName}
                />
                <Address
                  errors={errors}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </>
            )}
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={() => {
                  handleEditToggle()
                }}
              >
                Discard
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
                // onClick={handleNext}
              >
                Save & Update
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default SpecialEducation
