import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumb from 'src/Components/BreadCrumb'
import { Applications } from 'src/Components/Employees/Details/Application'
import EditDetails from 'src/Components/Employees/Details/EditDetails'
import { EmployeeDetailsRequest } from 'src/Redux/Slices/employeeSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import styles from './index.module.scss'

const EmployeeDetails: React.FunctionComponent = () => {
  const location = useLocation()
  const employeeData = useAppSelector((state) => state.employee?.details?.data)
  const dispatch = useAppDispatch()
  const [isEditing, setIsEditing] = React.useState(location?.state?.editMode)
  useEffect(() => {
    dispatch(EmployeeDetailsRequest(location?.state?.uuid))
  }, [])

  return (
    <Box className={styles.container}>
      <Breadcrumb
        title='Employee Details'
        Menus={[
          { name: 'Employees', route: ROUTES.ADMIN_MANAGE_EMPLOYEES },
          { name: 'Employee Details', route: ROUTES.ADMIN_EMPLOYEE_DETAIL },
        ]}
      />
      <Box className={styles.main}>
        <Box className={styles.profileDetail}>
          <Box className={styles.flexBox}>
            <Box className={styles.info}>
              <Grid className={styles.MainGrid}>
                <Grid className={styles.SubGrid}>
                  <Box className={styles.AvatarBox}>
                    <Avatar
                      sx={{ height: '120px', width: '120px' }}
                      src={employeeData?.profileUrl}
                      alt='photoURL'
                    />
                    <Box className={styles.ContentTypeBox}>
                      <Box className={styles.ContentText}>
                        {employeeData?.jobType ?? '-'}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.ContentBox}>
                    <Box className={styles.ContentHeadBox}>
                      <Box>
                        <Typography className={styles.ContentHeadNameText}>
                          {employeeData?.firstName ?? '-'}
                        </Typography>
                        <Typography className={styles.ContentHead}>
                          {employeeData?.jobTitle ?? '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box className={styles.flexCenter}>
                <Box className={styles.semiBoldTextWrapper}>
                  Assigned Applications
                </Box>
                <Box className={styles.list}>
                  <Box className={styles.listItems}>
                    <Typography className={styles.midBlckText}>
                      {' '}
                      {employeeData?.application?.length}
                    </Typography>
                    <Typography className={styles.smallGrayText}>
                      Total
                    </Typography>
                  </Box>
                  <Box className={styles.listItems}>
                    <Typography className={styles.midBlckText}>
                      {
                        employeeData?.application?.filter(
                          (i: { status: string }) => i.status === 'Completed',
                        )?.length
                      }
                    </Typography>
                    <Typography className={styles.smallGrayText}>
                      Completed
                    </Typography>
                  </Box>
                  <Box className={styles.listItems}>
                    <Typography className={styles.midBlckText}>
                      {
                        employeeData?.application?.filter(
                          (i: { status: string }) => i.status === 'Pending',
                        )?.length
                      }
                    </Typography>
                    <Typography className={styles.smallGrayText}>
                      In Progress
                    </Typography>
                  </Box>
                  <Box className={styles.listItems}>
                    <Typography className={styles.midBlckText}>
                      {
                        employeeData?.application?.filter(
                          (i: { status: string }) => i.status === 'Rejected',
                        )?.length
                      }
                    </Typography>
                    <Typography className={styles.smallGrayText}>
                      Rejected
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.flexBox}>
              <Box className={styles.spacedHead}>
                <div className={styles.dottedLine} />
                <Box className={styles.centerTitle}>
                  <Box className={styles.semiBoldTextWrapper}>
                    Personal Details
                  </Box>
                  <Button
                    onClick={() => {
                      setIsEditing(!isEditing)
                    }}
                    className={styles.editButton}
                  >
                    {isEditing ? 'Cancel' : 'Edit'}
                  </Button>
                </Box>
                <div className={styles.dottedLine} />
              </Box>
              <Box className={styles.flexBox}>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Email</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.emailId}
                  </Box>
                </Box>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Phone number</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.phoneNumber
                      ? `(${employeeData?.phoneNumber.slice(0, 3)}) ${employeeData?.phoneNumber.slice(3, 6)}-${employeeData?.phoneNumber.slice(6)}`
                      : '-'}
                  </Box>
                </Box>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Role</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.roleName ?? '-'}
                  </Box>
                </Box>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Permission</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.permission?.map(
                      (item: string, index: number) => {
                        return (
                          <Box key={index} className={styles.smallGrayBox}>
                            {item}
                          </Box>
                        )
                      },
                    ) ?? '-'}
                  </Box>
                </Box>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Job Title</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.jobTitle ?? '-'}
                  </Box>
                </Box>
                <Box className={styles.justifiedBox}>
                  <Box className={styles.blackTextWrapper}>Job Type</Box>
                  <Box className={styles.smallGrayText}>
                    {employeeData?.jobType ?? '-'}
                  </Box>
                </Box>
              </Box>
              {employeeData?.roleScope === 'Admin' ? (
                <></>
              ) : (
                <>
                  <Box className={styles.spacedHead}>
                    <div className={styles.dottedLine} />
                    <Box className={styles.centerTitle}>
                      <Box className={styles.semiBoldTextWrapper}>
                        Organization Details
                      </Box>
                    </Box>
                    <div className={styles.dottedLine} />
                  </Box>
                  <Box className={styles.flexBox}>
                    <Box className={styles.justifiedBox}>
                      <Box className={styles.blackTextWrapper}>
                        Organization name
                      </Box>
                      <Box className={styles.smallGrayText}>
                        {employeeData?.organizationName ?? '-'}
                      </Box>
                    </Box>
                    <Box className={styles.justifiedBox}>
                      <Box className={styles.blackTextWrapper}>
                        Organization Location
                      </Box>
                      <Box className={styles.smallGrayText}>
                        {employeeData?.organizationAddress?.streetAddress ??
                          '-'}
                      </Box>
                    </Box>
                    <Box className={styles.justifiedBox}>
                      <Box className={styles.blackTextWrapper}>
                        Organization Description
                      </Box>
                      <Box className={styles.smallGrayText}>
                        {employeeData?.organizationdescription ?? '-'}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles.application}>
          {isEditing ? (
            <EditDetails
              employeeData={employeeData}
              setIsEditing={setIsEditing}
            />
          ) : (
            <Applications ApplicationsData={employeeData?.application} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EmployeeDetails
