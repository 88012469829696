import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { PDFExport } from '@progress/kendo-react-pdf'
import { useRef } from 'react'
import download from 'src/Assets/Icons/download.svg'
import { Icons } from 'src/Utils/Constants'
import BasicDetails from './BasicDetails'
import FamilyDetails from './FamilyDetails'
import LegalDetails from './LegalDetails'
import Style from './index.module.scss'
import DisabilityDetails from './DisabilityDetails'
import EducationDetails from './EducationDetails'
import MedicalDetails from './MedicalDetails'
import AssistanceDetails from './AssistanceDetails'
import HeaderFooter from './HeaderFooter'
import { useAppSelector } from 'src/Redux/hooks'

const ApplicationPopup = ({
  applicationId,
  clintName,
  open,
  setOpen,
  handleOpen,
  question,
}: {
  applicationId: string
  clintName: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
  question: any
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  const pdfExportComponent = useRef(null)
  const viewProfile = useAppSelector((state) => state?.profile?.data)

  const pageTemplate = ({
    pageNum,
    totalPages,
  }: {
    pageNum: number
    totalPages: number
  }) => (
    <HeaderFooter
      applicationId={applicationId}
      clintName={clintName}
      pageNum={pageNum}
      totalPages={totalPages}
      representative={viewProfile}
    />
  )

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
          sx: {
            // borderRadius: '10px',
            // width: '100%',
          },
        }}
        // sx={{
        //   '& .MuiPaper-root': {
        //     width: '100%',
        //   },
        // }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Application Download
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='Cancel'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PDFExport
                keepTogether='page-break'
                imageResolution={300}
                forcePageBreak='.page-break'
                margin={{ top: 50, left: 25, right: 25, bottom: 50 }}
                fileName='SSA-Application.pdf'
                paperSize={'A4'}
                scale={0.4}
                ref={pdfExportComponent}
                pageTemplate={pageTemplate}
              >
                <Box id='mydiv' sx={{ padding: '25px 10px' }}>
                  <Box>
                    <AssistanceDetails question={question.Assistance_Data} />

                    <BasicDetails question={question.Basic_Details} />

                    <FamilyDetails question={question.Family_Details} />

                    <LegalDetails question={question.Legal_Details} />

                    <DisabilityDetails question={question.Disability_Details} />

                    <EducationDetails
                      question={question.Education_Employment_Details}
                    />

                    <MedicalDetails question={question.Medical_Details} />
                  </Box>
                </Box>
              </PDFExport>
            </Box>
            <DialogActions>
              <Box className={Style.AddButton}>
                <Button
                  onClick={() => {
                    // window.print()
                    if (pdfExportComponent.current) {
                      pdfExportComponent.current.save()
                    }
                  }}
                  variant='contained'
                  className={Style.button2}
                >
                  <Typography
                    variant='button'
                    sx={{ cursor: 'pointer' }}
                    className={Style.textWrapper3}
                  >
                    Download PDF
                  </Typography>
                  <img src={download} alt='star' className={Style.image} />
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default ApplicationPopup
