import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import Delete from 'src/Assets/Icons/delete.svg'
import Edit from 'src/Assets/Icons/edit.svg'
import {
  EditProfileRequest,
  ViewProfileRequest,
} from 'src/Redux/Slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import styled from 'styled-components'
import Style from './index.module.scss'
import { Country } from 'country-state-city'

interface FormType {
  profileURL: string
  firstName: string
  lastName: string
  emailId: string
  phoneNumber: string
  country: string
  role: string
  countryCode: string
}
interface contryType {
  name: string
}
const StyledSelect = styled(Select)({
  borderRadius: '10px',
})
const Profile: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewProfileRequest())
  }, [])

  const countrySelect = useAppSelector(
    (state: { country: { data: Array<{ name: string }> } }) =>
      state.country.data,
  )

  const getPhoneCodeByCountryName = (countryName: string): string | null => {
    const countries = Country.getAllCountries()
    const country = countries.find((c) => c.name === countryName)
    return country ? `+${country.phonecode}` : null
  }

  const viewProfile = useAppSelector((state) => state.profile.data)
  const [removeProfileURL, setRemoveProfileURL] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(viewProfile?.profileUrl)
  const [disableButton, setdisableButton] = useState<Boolean>(true)

  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    viewProfile?.profileUrl,
  )
  const [formValues, setFormValues] = useState<FormType>({
    profileURL: selectedFile,
    firstName: viewProfile?.firstName ?? '',
    lastName: viewProfile?.lastName ?? '',
    emailId: viewProfile?.emailId ?? '',
    phoneNumber: viewProfile?.phoneNumber ?? '',
    country: viewProfile?.country ?? '',
    role: viewProfile?.roleName ?? '',
    countryCode: '',
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  useEffect(() => {
    if (viewProfile) {
      setFormValues({
        profileURL: selectedFile,
        firstName: viewProfile?.firstName ?? '',
        lastName: viewProfile?.lastName ?? '',
        emailId: viewProfile?.emailId ?? '',
        phoneNumber: viewProfile?.phoneNumber ?? '',
        country: viewProfile?.country ?? '',
        role: viewProfile?.roleName ?? '',
        countryCode: '',
      })
      setSelectedFile(viewProfile?.profileUrl)
      setPreviewUrl(viewProfile?.profileUrl)
    }
  }, [viewProfile])

  const onDiscard = () => {
    setdisableButton(true)
    setFormValues({
      profileURL: selectedFile,
      firstName: viewProfile?.firstName ?? '',
      lastName: viewProfile?.lastName ?? '',
      emailId: viewProfile?.emailId ?? '',
      phoneNumber: viewProfile?.phoneNumber ?? '',
      country: viewProfile?.country ?? '',
      role: viewProfile?.roleName ?? '',
      countryCode: '',
    })
  }

  const handleFieldChange = (
    e: React.ChangeEvent<
      | HTMLInputElement
      | HTMLTextAreaElement
      | { name?: string | undefined; value: string | any }
    >,
  ) => {
    setdisableButton(false)

    const { name, value } = e.target
    if (name === 'country') {
      const countryCode = getPhoneCodeByCountryName(e.target.value)

      if (countryCode) {
        setFormValues((prevState: FormType) => ({
          ...prevState,
          countryCode,
          [name]: value,
        }))
      } else {
        setFormValues((prevState: FormType) => ({
          ...prevState,
          [name]: value,
        }))
      }
    } else {
      setFormValues((prevState: FormType) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.firstName.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    }
    if (!formValues.lastName.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    }

    // Validation logic for Phone number
    if (!formValues.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required'
      valid = false
    } else if (!/^\d{10}$/.test(formValues.phoneNumber.trim())) {
      newErrors.phoneNumber = 'Phone number must be 10 digits'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateForm()) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('firstName', formValues.firstName)
      formData.append('lastName', formValues.lastName)
      formData.append('emailId', formValues.emailId)
      formData.append('phoneNumber', formValues.phoneNumber)
      formData.append('country', formValues.country)
      formData.append('countryCode', formValues.countryCode)
      formData.append('removeProfile', `${removeProfileURL}`)
      setdisableButton(true)
      dispatch(EditProfileRequest(formData)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewProfileRequest())
        },
      )
    }
  }

  // Function to handle file selection
  const handleFileSelect = (event: SelectChangeEvent<string[]>) => {
    setdisableButton(false)
    const file = event.target.files[0]
    if (file) {
      setRemoveProfileURL(false)
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // Function to handle removing the selected photo
  const handleRemovePhoto = () => {
    setSelectedFile(null)
    setPreviewUrl(null)
    setRemoveProfileURL(true)
  }

  return (
    <>
      <Box className={Style.Main}>
        <Grid container direction='column' className={Style.EditContact}>
          {/* <Box className={Style.Head}>
            <Box>
              <Typography className={Style.HeadText}>
                Personal Information
              </Typography>
            </Box>
          </Box> */}
          <form className={Style.Form} onSubmit={handleSubmit}>
            <Grid item className={Style.Form}>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Image
                  </Typography>
                </Grid>
                <Box component='div' className={Style.Ans}>
                  <Box component='div' className={Style.EditContactImage}>
                    <Box component='div' className={Style.EditContactLogo}>
                      <Box component='div' className={Style.overlapGroup}>
                        <Avatar
                          className={Style.Rectangle}
                          src={previewUrl ?? ProfilePhoto}
                          alt='Rectangle'
                        />
                        <Box component='div' className={Style.IconEditWrapper}>
                          <input
                            onChange={handleFileSelect}
                            id='profile-photo-upload'
                            type='file'
                            accept='image/jpeg, image/png'
                            style={{ cursor: 'pointer' }}
                            className={Style.inputFile}
                          />
                          <img
                            src={Edit}
                            className={Style.EditIcon}
                            alt='Rectangle'
                          />
                        </Box>
                        <Box
                          component='div'
                          className={Style.IconDeleteWrapper}
                        >
                          <img
                            src={Delete}
                            onClick={handleRemovePhoto}
                            alt='Rectangle'
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box component='div' className={Style.Description}>
                      <Typography className={Style.DescriptionP}>
                        Allowed file types: png, jpg, jpeg.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Full Name
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='firstName'
                    name='firstName'
                    placeholder='First Name'
                    className={Style.AnsTxt}
                    value={formValues.firstName}
                    onChange={handleFieldChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='lastName'
                    name='lastName'
                    placeholder='Last Name'
                    className={Style.AnsTxt}
                    value={formValues.lastName}
                    onChange={handleFieldChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Email
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='email'
                    id='email'
                    name='emailId'
                    placeholder='Email'
                    className={Style.AnsTxt}
                    value={formValues?.emailId}
                    disabled
                  />
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Phone number
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='number'
                    id='phoneNumber'
                    name='phoneNumber'
                    inputProps={{ maxLength: 10 }}
                    placeholder='Phone number'
                    className={Style.AnsTxt}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>(+1)</InputAdornment>
                      ),
                    }}
                    value={formValues.phoneNumber}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 10 &&
                        /^\d*$/.test(e.target.value)
                      )
                        handleFieldChange(e)
                    }}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                  />
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Country
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <StyledSelect
                    fullWidth
                    onChange={(e) => {
                      handleFieldChange(e)
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the value as needed
                        },
                      },
                    }}
                    displayEmpty
                    renderValue={
                      formValues.country !== ''
                        ? () => formValues.country
                        : () => 'Select'
                    }
                    value={formValues.country}
                    required
                    id='country'
                    name='country'
                    placeholder='Country'
                    error={!!errors.country}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {countrySelect?.map((item: contryType, index: number) => {
                      return (
                        <MenuItem key={index} value={item.name}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </StyledSelect>
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>Role</Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='role'
                    name='role'
                    placeholder='Role'
                    className={Style.AnsTxt}
                    value={formValues?.role}
                    disabled
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item className={Style.Action}>
              <Button
                variant='contained'
                className={Style.ActionButton}
                onClick={onDiscard}
                disabled={disableButton}
              >
                <Typography className={Style.TextWrapper4}>Discard</Typography>
              </Button>
              <Button
                type='submit'
                variant='contained'
                className={Style.DivWrapper}
                disabled={disableButton}
              >
                <Typography className={Style.TextWrapper4}>
                  Save Changes
                </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  )
}

export default Profile
