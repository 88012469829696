import {
  Box,
  Button,
  FormGroup,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  // SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import styles from '../index.module.scss'
import { PersonalRequest, setSignUpData } from 'src/Redux/Slices/authSlice'
import { RootState } from 'src/Redux/store'
import { PayloadAction } from '@reduxjs/toolkit'
import { Country } from 'country-state-city'
interface contryType {
  name: string
  value: string
}
const Personal = ({ handleNext }: { handleNext: () => void }) => {
  const dispatch = useAppDispatch()
  const { SignUpData } = useAppSelector((state: RootState) => state?.auth)

  const [formData, setFormData] = useState<Record<string, string>>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    countryCode: '',
    role: 'Organization',
  })

  React.useEffect(() => {
    setFormData({
      firstName: SignUpData?.firstName ?? '',
      lastName: SignUpData?.lastName ?? '',
      phoneNumber: SignUpData?.phoneNumber ?? '',
      country: SignUpData?.country ?? '',
      countryCode: '',
      role: SignUpData?.role ?? '',
    })
  }, [])
  const [errors, setErrors] = useState<typeof formData>({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const handleValidation = () => {
    const errorsCopy = { ...errors }
    const { firstName, lastName, email, phoneNumber, country, role } = formData

    // First Name validation
    const firstNameRegex = /^[a-zA-Z]+$/
    errorsCopy.firstName = firstName
      ? firstNameRegex.test(firstName)
        ? ''
        : 'First Name must contain only alphabetic characters'
      : 'First Name is required'

    // Last Name validation
    const lastNameRegex = /^[a-zA-Z]+$/
    errorsCopy.lastName = lastName
      ? lastNameRegex.test(lastName)
        ? ''
        : 'Last Name must contain only alphabetic characters'
      : 'Last Name is required'

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    errorsCopy.email = email
      ? emailRegex.test(email)
        ? ''
        : 'Please enter a valid email address.'
      : 'Email is required'

    // Phone number validation
    const phoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    errorsCopy.phoneNumber = phoneNumber
      ? phoneNumberRegex.test(phoneNumber)
        ? ''
        : 'Please enter a valid Phone number.'
      : 'Phone number is required'

    // Country validation
    errorsCopy.country = country ? '' : 'Country is required'

    // Role validation
    errorsCopy.role = role ? '' : 'Role is required'

    setErrors(errorsCopy)
  }
  const data = useAppSelector(
    (state: { country: { data: Array<{ name: string }> } }) =>
      state.country.data,
  )

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = e.target
    const key = id
    setFormData({ ...formData, [e.target.name]: e.target.value })
    dispatch(setSignUpData({ key, value }))
  }

  const getPhoneCodeByCountryName = (countryName: string): string | null => {
    const countries = Country.getAllCountries()
    const country = countries.find((c) => c.name === countryName)
    return country ? `+${country.phonecode}` : null
  }

  const handleSelectChange = (e: any, keyid: string) => {
    const { value } = e.target
    const key = keyid
    const countryCode = getPhoneCodeByCountryName(value)

    if (countryCode) {
      const key = 'countryCode'
      const value = countryCode
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        countryCode,
      })
      dispatch(setSignUpData({ key, value }))
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    dispatch(setSignUpData({ key, value }))
  }
  interface stateType {}

  interface SerializedError {}
  interface PayloadType {
    warn?: boolean | object
    success?: boolean | object
  }
  const handleContinue = () => {
    handleValidation()
    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.country ||
      errors.role
    ) {
      setIsSubmitted(true)
      return
    }
    dispatch(PersonalRequest(formData)).then(
      (
        res:
          | PayloadAction<
              stateType,
              string,
              {
                arg: any
                requestId: string
                requestStatus: 'fulfilled'
              },
              never
            >
          | PayloadAction<
              unknown,
              string,
              {
                arg: any
                requestId: string
                requestStatus: 'rejected'
                aborted: boolean
                condition: boolean
              } & ({} | ({} & {})),
              SerializedError
            >,
      ) => {
        const payload = res.payload as PayloadType

        if (payload?.success) {
          handleNext()
        } else {
          setIsSubmitted(true)
        }
      },
    )
  }

  return (
    <>
      <Box className={styles.title}>
        <Typography className={styles.phrase}>Personal Details</Typography>
        <Typography className={styles.subPhrase}>
          Please provide your personal details.
        </Typography>
      </Box>
      <Box className={styles.form}>
        <FormGroup className={styles.fieldsWrapper}>
          <Box className={styles.fields}>
            <Box className={styles.flexSpaceBox}>
              <Box className={styles.fullWidth}>
                <Typography className={styles.textFieldWrapper}>
                  First Name
                  <Typography className={styles.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='firstName'
                  className={styles.field}
                  placeholder='Enter your first Name'
                  onChange={handleChange}
                  value={formData.firstName}
                  name='firstName'
                  error={isSubmitted && !!errors.firstName}
                  helperText={isSubmitted ? errors.firstName : ''}
                />
              </Box>
              <Box className={styles.fullWidth}>
                <Typography className={styles.textFieldWrapper}>
                  Last Name
                  <Typography className={styles.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='lastName'
                  className={styles.field}
                  placeholder='Enter your last Name'
                  onChange={handleChange}
                  value={formData.lastName}
                  name='lastName'
                  error={isSubmitted && !!errors.lastName}
                  helperText={isSubmitted ? errors.lastName : ''}
                />
              </Box>
            </Box>
            <Box className={styles.flexStartBox}>
              <Typography className={styles.textFieldWrapper}>
                Country
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <Select
                className={styles.field}
                IconComponent={(props) => (
                  <IconButton {...props}>
                    <img src={IconChevronDown} alt='IconChevronDown' />
                  </IconButton>
                )}
                displayEmpty
                renderValue={
                  formData.country !== '' ? undefined : () => 'Select'
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400, // Adjust the value as needed
                    },
                  },
                }}
                onChange={(e) => {
                  handleSelectChange(e, 'country')
                }}
                value={formData.country}
                size='small'
                name='country'
                error={isSubmitted && !!errors.country}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {data?.map((item: contryType, index: number) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>{errors.country}</FormHelperText>
            </Box>
            <Box className={styles.flexStartBox}>
              <Typography className={styles.textFieldWrapper}>
                Phone number
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                id='phoneNumber'
                className={styles.field}
                placeholder='Enter your number'
                onChange={(e) => {
                  if (
                    e.target.value.length <= 10 &&
                    /^\d*$/.test(e.target.value)
                  )
                    handleChange(e)
                }}
                value={formData.phoneNumber}
                name='phoneNumber'
                error={isSubmitted && !!errors.phoneNumber}
                helperText={isSubmitted ? errors.phoneNumber : ''}
              />
            </Box>
            <Box className={styles.flexStartBoxRole}>
              <Typography className={styles.textFieldWrapper}>
                Role
                {/* <Typography className={styles.FF002E}>*</Typography> */}
              </Typography>
              <Select
                className={styles.field}
                IconComponent={(props) => (
                  <IconButton {...props}>
                    <img src={IconChevronDown} alt='IconChevronDown' />
                  </IconButton>
                )}
                displayEmpty
                onChange={(e) => {
                  handleSelectChange(e, 'role')
                }}
                disabled
                value={formData.role}
                size='small'
                name='role'
                error={isSubmitted && !!errors.role}
              >
                <MenuItem value={'Organization'}>Organization</MenuItem>
              </Select>
              <FormHelperText>{errors.role}</FormHelperText>
            </Box>
          </Box>
        </FormGroup>
      </Box>
      <Button
        onClick={() => {
          handleContinue()
        }}
        className={styles.button}
      >
        <Typography className={styles.mediumText}>Continue</Typography>
      </Button>
    </>
  )
}

export default Personal
