import {
  TableCell,
  tableCellClasses,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  TableRow,
  Paper,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { DynamicObj, convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import PastApplications from '../../EditForms/BasicDetails/PassApplication/index'
import { useAppSelector } from 'src/Redux/hooks'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const PastApplication: React.FC = () => {
  const [editMode, setEditMode] = useState(false)

  const data = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.PassApplication,
  )

  function renderContactInfoPart(
    part: string | null | undefined,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }
  const passApplicationRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    passApplicationRef?.current?.scrollIntoView()
  }

  const pastApplicationsSectionId = useAppSelector(
    (state) =>
      state?.steps?.BasicApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Past Applications',
      )?.sectionId,
  )
  return (
    <div id={pastApplicationsSectionId} ref={passApplicationRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Past Applications
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>

          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <Typography variant='h3' className='FontBold' m={2}>
                  Medical Benefits
                </Typography>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Currently Receiving Medicare Benefits
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isReceivingMedicareBenefits ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                    {data?.isReceivingMedicareBenefits === false ? (
                      <>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Previously applied for Medicare benefits
                          </StyledTableCell>
                          <StyledTableCell className='MediumBlackFonts'>
                            {data?.haveAppliedForMedicareBenefits
                              ? 'Yes'
                              : 'No'}
                          </StyledTableCell>
                        </TableRow>
                        {data?.haveAppliedForMedicareBenefits === true ? (
                          <TableRow>
                            <StyledTableCell
                              variant='head'
                              className='grayFonts'
                            >
                              Status of the Medicare benefits program for which
                              you previously applied
                            </StyledTableCell>
                            <StyledTableCell className='MediumBlackFonts'>
                              {data?.statusOfPreviousMedicareBenefit}
                            </StyledTableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Typography variant='h3' className='FontBold' m={4}>
                Social Security Benefits (SSDI)
              </Typography>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Currently receiving Social Security benefits (SSDI)
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isReceivingSSDI ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>

                    {data?.isReceivingSSDI === false ? (
                      <>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Previously applied for Social Security benefits
                            (SSDI)
                          </StyledTableCell>
                          <StyledTableCell className='MediumBlackFonts'>
                            {data?.haveAppliedForSSDI ? 'Yes' : 'No'}
                          </StyledTableCell>
                        </TableRow>

                        {data?.haveAppliedForSSDI === true ? (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Status of the Social Security benefits (SSDI)
                                program for which you previously applied
                              </StyledTableCell>
                              <StyledTableCell className='MediumBlackFonts'>
                                {data?.statusOfPreviousSSDI}
                              </StyledTableCell>
                            </TableRow>
                            {data?.statusOfPreviousSSDI === 'Approved' && (
                              <TableRow>
                                <StyledTableCell
                                  variant='head'
                                  className='grayFonts'
                                >
                                  End Date of Benefits
                                </StyledTableCell>
                                <StyledTableCell className='MediumBlackFonts'>
                                  {convertISOToDate(data?.SSDIApprovedEndDate)}
                                </StyledTableCell>
                              </TableRow>
                            )}

                            {data?.statusOfPreviousSSDI === 'Denied' && (
                              <>
                                <TableRow>
                                  <StyledTableCell
                                    variant='head'
                                    className='grayFonts'
                                  >
                                    Benefits application denied in the last 60
                                    days
                                  </StyledTableCell>
                                  <StyledTableCell className='MediumBlackFonts'>
                                    {data?.isSSDIDenial60Days ? 'Yes' : 'No'}
                                  </StyledTableCell>
                                </TableRow>
                                {data?.isSSDIDenial60Days === false && (
                                  <TableRow>
                                    <StyledTableCell
                                      variant='head'
                                      className='grayFonts'
                                    >
                                      Denial Date of Benefits
                                    </StyledTableCell>
                                    <StyledTableCell className='MediumBlackFonts'>
                                      {convertISOToDate(data?.denialDateSSDI)}
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Typography variant='h3' className='FontBold' m={4}>
                Supplemental Security Income (SSI)
              </Typography>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Currently receiving Supplemental Security Income (SSI)
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isReceivingSSI ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                    {data?.isReceivingSSI === false && (
                      <>
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Previously applied for Supplemental Security Income
                            (SSI)
                          </StyledTableCell>
                          <StyledTableCell className='MediumBlackFonts'>
                            {data?.haveAppliedForSSI ? 'Yes' : 'No'}
                          </StyledTableCell>
                        </TableRow>

                        {data?.haveAppliedForSSI ? (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Status of the previously applied Supplemental
                                Security Income (SSI)
                              </StyledTableCell>
                              <StyledTableCell className='MediumBlackFonts'>
                                {data?.statusOfSSI}
                              </StyledTableCell>
                            </TableRow>
                          </>
                        ) : null}

                        {data?.statusOfSSI === 'Approved' ? (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                End Date of Benefits (estimate if unknown)
                              </StyledTableCell>
                              <StyledTableCell
                                className='MediumBlackFonts'
                                sx={{ width: '200px' }}
                              >
                                {data?.SSIApprovedEndDate}
                              </StyledTableCell>
                            </TableRow>
                          </>
                        ) : null}

                        {data?.statusOfSSI === 'Denied' ? (
                          <>
                            <TableRow>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Benefits application denied in the last 60 days
                              </StyledTableCell>
                              <StyledTableCell className='MediumBlackFonts'>
                                {data?.isSSIDenial60Days ? 'Yes' : 'No'}
                              </StyledTableCell>
                            </TableRow>
                            {data?.isSSIDenial60Days === false && (
                              <TableRow>
                                <StyledTableCell
                                  variant='head'
                                  className='grayFonts'
                                >
                                  Denial Date of Benefits (estimate if unknown)
                                </StyledTableCell>
                                <StyledTableCell
                                  className='MediumBlackFonts'
                                  sx={{ width: '200px' }}
                                >
                                  {data?.denialDateSSI}
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </>
                        ) : null}
                      </>
                    )}
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Previously apply using your own Social Security Number
                        (SSN)
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.haveAppliedWithSSN ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {!data?.haveAppliedWithSSN && (
                <Grid m={2}>
                  <Typography variant='h3' className='FontBold' m={2}>
                    We ask who s social security number (SSN) for benefits were
                    applied to for any of the above benefits so these could be
                    for SSDI, medicare or SSI.
                  </Typography>
                  <Grid container m={2}>
                    {data?.benefitAppliedSSNDetail?.map(
                      (
                        item: {
                          socialSecurityNumber: string | undefined
                          monthlyBenefitAmount: any
                        },
                        index: any,
                      ) => (
                        <>
                          <Grid lg={6} pr={2}>
                            <Paper
                              key={index}
                              sx={{
                                p: 2,
                                bgcolor: 'transparent',
                                border: '1px solid #E1E4EB',
                                boxShadow: 0,
                              }}
                            >
                              <Grid container>
                                <Grid lg={12}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Name
                                  </Typography>
                                  <Typography variant='h3'>
                                    {renderContactInfo(item)}
                                  </Typography>
                                </Grid>

                                <Grid pt={2} container>
                                  <Grid lg={6}>
                                    <Typography
                                      variant='h3'
                                      className='grayFonts'
                                    >
                                      Social Security Number (SSN)
                                    </Typography>
                                    <Typography variant='h3'>
                                      {item?.socialSecurityNumber}
                                    </Typography>
                                  </Grid>
                                  <Grid lg={6}>
                                    <Typography
                                      variant='h3'
                                      className='grayFonts'
                                    >
                                      Monthly Benefit Amount
                                    </Typography>
                                    <Typography variant='h3'>
                                      {`$${item?.monthlyBenefitAmount}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </>
                      ),
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Future Benefits Applying For
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.appliedForAdditionalBenefits}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Participated in, or participating in, any of the
                        programs
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.currentlyparticipatingPrograms}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid m={2}>
                <Typography variant='h3' className='FontBold' m={2}>
                  details of the answer in which you ve participated or are
                  planing to participate
                </Typography>
                <Grid container m={2} gap={2}>
                  {data.participateProgramDetails?.map(
                    (
                      item: {
                        organizationName: string | undefined
                        address: {
                          streetAddress: string | undefined
                          city: string | undefined
                          state: string | undefined
                          postalCode: string | undefined
                          country: string | undefined
                        }
                        contactName: string | undefined
                        phone: string | undefined
                        estimatedDate: string | number | Date
                        stillParticipatedInProgram: any
                        description: string | undefined
                      },
                      index: any,
                    ) => (
                      <>
                        <Grid lg={12} pr={2}>
                          <Paper
                            sx={{
                              p: 2,
                              bgcolor: 'transparent',
                              border: '1px solid #E1E4EB',
                              boxShadow: 0,
                            }}
                          >
                            <Grid container>
                              <Grid lg={4}>
                                <Typography variant='h3' className='grayFonts'>
                                  Organization name
                                </Typography>
                                <Typography variant='h3'>
                                  {item?.organizationName}
                                </Typography>
                              </Grid>
                              <Grid lg={4}>
                                <Typography variant='h3' className='grayFonts'>
                                  Street Address
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {item?.address?.streetAddress}
                                </Typography>
                              </Grid>
                              <Grid lg={4}>
                                <Typography variant='h3' className='grayFonts'>
                                  City/Town
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {item?.address?.city}
                                </Typography>
                              </Grid>
                              <Grid pt={2} container>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    State
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.address?.state}
                                  </Typography>
                                </Grid>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    ZIP Code
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.address?.postalCode}
                                  </Typography>
                                </Grid>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Country
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.address?.country}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid pt={2} container>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Contact Person s Name
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.contactName}
                                  </Typography>
                                </Grid>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Phone number
                                  </Typography>
                                  <Typography variant='h3'>
                                    {item?.phone}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid pt={2} container>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Estimated date
                                  </Typography>
                                  <Typography variant='h3'>
                                    {convertISOToDate(item?.estimatedDate)}
                                  </Typography>
                                </Grid>
                                <Grid lg={4}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Still participating in this plan or program
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.stillParticipatedInProgram
                                      ? 'Yes'
                                      : 'No'}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid pt={2} container>
                                <Grid lg={12}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Plan Description
                                  </Typography>
                                  <Typography variant='h3'>
                                    {item?.description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </>
                    ),
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <Grid m={2}>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Past Applications
              </Typography>
            </Grid>
            <Divider
              sx={{ width: '95%', marginTop: '8px', marginBottom: '8px' }}
            />
          </Grid>
          <PastApplications handleEditToggle={handleEditToggle} />
        </Grid>
      )}
    </div>
  )
}

export default PastApplication
