import { Box, Button, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  ArraySelection,
  CommonDropdown,
  MultiSelectDropDown,
  Name,
  StarText,
} from 'src/Components/Fields'
import { JobDetailsType } from 'src/Redux/Services/Types/applicationType'
import { EditJobDetails } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setJobDetails } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../../index.module.scss'

const JobDetails = ({ handleEditToggle }: { handleEditToggle: () => void }) => {
  const dispatch = useAppDispatch()
  const selectList = [
    {
      title: 'Use machines, tools, or equipment',
      value: 'Use machines, tools, or equipment',
      name: 'This includes small and large machines, tools, or equipment.',
    },
    {
      title: 'Use technical knowledge or skills',
      value: 'Use technical knowledge or skills',
      name: 'Ask yourself: Would those outside my field understand all of the terms I use daily for my job?',
    },
    {
      title: 'Write or complete reports',
      value: 'Write or complete reports',
      name: 'Reports can include measurement reports, analysis reports, case studies, testing studies, financial updates, HR-related employee updates, etc.',
    },
  ]

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement?.JobDetails,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<JobDetailsType>({
    educationId,
    oneOrMoreJobsIn15Years: '',
    jobDescriptionOf15Years: '',
    applicableJobs: [''],
    hoursToPerform: {
      walk: 0,
      stand: 0,
      sit: 0,
      climb: 0,
      bend: 0,
      kneel: 0,
      crounch: 0,
      crawl: 0,
      writeOrType: 0,
      handleLargeObject: 0,
      reachToThings: 0,
      handleSmallObjects: 0,
    },
    whatLiftedAndHowOften: '',
    isLifitingInJob: undefined,
    howFarCarryItems: '',
    heaviestWeightLifted: '',
    howmuchWeightLiftedPerDay: '',
    didSuperviseOther: undefined,
    percentageOfTimeSpentSupervising: '',
    howManyPeopleSupervise: 0,
    didHireOrFirePeople: undefined,
    didLeadOrMentorOthers: undefined,
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setErrors({})
  }, [])
  // useEffect(() => {
  //   setErrors({ ...errors, state: '', country: '' })
  // }, [formValues.state, formValues.country])

  const handleChange = (data: {
    name: string
    value?: string | boolean | number | typeof formValues.hoursToPerform
  }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const handleSelectChange = (name: string[]) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      applicableJobs: name,
    }))
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (!formValues.oneOrMoreJobsIn15Years?.trim()) {
      newErrors.oneOrMoreJobsIn15Years = 'Please select an option'
      isValid = false
    }
    if (
      formValues.oneOrMoreJobsIn15Years ===
      'I had only one job during the last 15 years before I became unable to work.'
    ) {
      if (!formValues.jobDescriptionOf15Years?.trim()) {
        newErrors.jobDescriptionOf15Years = 'Job Description is required'
        isValid = false
      }
      if (!formValues.applicableJobs?.[0]?.length) {
        newErrors.applicableJobs = 'Please select an option'
        isValid = false
      }
      if (formValues.isLifitingInJob === undefined) {
        newErrors.isLifitingInJob = 'Please select an option'
        isValid = false
      }
      if (formValues.isLifitingInJob) {
        if (!formValues.whatLiftedAndHowOften?.trim()) {
          newErrors.whatLiftedAndHowOften =
            'Please enter what lifted and how often'
          isValid = false
        }
        if (!formValues.howFarCarryItems?.trim()) {
          newErrors.howFarCarryItems = 'Please enter how far to carry items'
          isValid = false
        }
      }
      if (formValues.didSuperviseOther === undefined) {
        newErrors.didSuperviseOther = 'Please select an option'
        isValid = false
      }
      if (formValues.didSuperviseOther) {
        if (formValues.didHireOrFirePeople === undefined) {
          newErrors.didHireOrFirePeople = 'Please select an option'
          isValid = false
        }
      }
    }
    if (
      formValues.oneOrMoreJobsIn15Years ===
        'I had more than one job during the last 15 years before I was unable to work.' ??
      formValues.didSuperviseOther === false
    ) {
      if (formValues.didLeadOrMentorOthers === undefined) {
        newErrors.didLeadOrMentorOthers = 'Please select an option'
        isValid = false
      }
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: JobDetailsType = {}
      payload.educationId = formValues.educationId
      payload.oneOrMoreJobsIn15Years = formValues.oneOrMoreJobsIn15Years
      if (
        formValues.oneOrMoreJobsIn15Years ===
        'I had only one job during the last 15 years before I became unable to work.'
      ) {
        payload.jobDescriptionOf15Years = formValues.jobDescriptionOf15Years
        payload.applicableJobs = formValues.applicableJobs
        payload.hoursToPerform = formValues.hoursToPerform
        payload.isLifitingInJob = formValues.isLifitingInJob
        if (formValues.isLifitingInJob) {
          payload.whatLiftedAndHowOften = formValues.whatLiftedAndHowOften
          payload.howFarCarryItems = formValues.howFarCarryItems
          payload.heaviestWeightLifted = formValues.heaviestWeightLifted
          payload.howmuchWeightLiftedPerDay =
            formValues.howmuchWeightLiftedPerDay
        }
        payload.didSuperviseOther = formValues.didSuperviseOther
        if (formValues.didSuperviseOther) {
          payload.percentageOfTimeSpentSupervising =
            formValues.percentageOfTimeSpentSupervising
          payload.howManyPeopleSupervise = formValues.howManyPeopleSupervise
          payload.didHireOrFirePeople = formValues.didHireOrFirePeople
        }
      }
      if (
        formValues.oneOrMoreJobsIn15Years ===
          'I had more than one job during the last 15 years before I was unable to work.' ??
        formValues.didSuperviseOther === false
      ) {
        payload.didLeadOrMentorOthers = formValues.didLeadOrMentorOthers
      }

      dispatch(EditJobDetails(payload)).then((res) => {
        if (res?.payload?.success) {
          dispatch(setJobDetails(formValues))

          handleEditToggle()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="Now, we're going to collect details related to your job responsibilities. Let's get into it." />
            <CommonDropdown
              handleChange={handleChange}
              label='Please select the statement that applies to you.'
              menulist={[
                {
                  name: 'I had more than one job during the last 15 years before I was unable to work.',
                  value:
                    'I had more than one job during the last 15 years before I was unable to work.',
                },
                {
                  name: 'I had only one job during the last 15 years before I became unable to work.',
                  value:
                    'I had only one job during the last 15 years before I became unable to work.',
                },
              ]}
              name='oneOrMoreJobsIn15Years'
              value={formValues.oneOrMoreJobsIn15Years ?? ''}
              error={errors?.oneOrMoreJobsIn15Years}
            />
            {formValues.oneOrMoreJobsIn15Years ===
            'I had only one job during the last 15 years before I became unable to work.' ? (
              <>
                <StarText
                  text={`Understood. Can you please describe the job?`}
                />
                <Name
                  name='jobDescriptionOf15Years'
                  title='Job Description'
                  multiline
                  value={formValues.jobDescriptionOf15Years ?? ''}
                  handleChange={handleChange}
                  error={errors?.jobDescriptionOf15Years}
                />
                <StarText
                  text={`Now, please check all of the boxes that apply to this job`}
                />
                <MultiSelectDropDown
                  name={'applicableJobs'}
                  value={formValues.applicableJobs ?? []}
                  error={errors.applicableJobs}
                  label={'Check all that apply to your job:'}
                  menulist={selectList}
                  handleSelectChange={handleSelectChange}
                />
                <Box height={'40px'} width={'100%'}></Box>
                <InputLabel className={Style.label}>
                  How many hours each day did you perform the following?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <ArraySelection
                  round
                  title='Walk'
                  formValue={formValues.hoursToPerform?.walk ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      walk: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Stand'
                  formValue={formValues.hoursToPerform?.stand ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      stand: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Sit'
                  formValue={formValues.hoursToPerform?.sit ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      sit: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Climb'
                  formValue={formValues.hoursToPerform?.climb ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      climb: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Bend'
                  formValue={formValues.hoursToPerform?.bend ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      bend: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Kneel'
                  formValue={formValues.hoursToPerform?.kneel ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      kneel: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Crouch'
                  formValue={formValues.hoursToPerform?.crounch ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      crounch: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Crawl'
                  formValue={formValues.hoursToPerform?.crawl ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      crawl: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Write or Type'
                  formValue={formValues.hoursToPerform?.writeOrType ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      writeOrType: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Handle Large Objects'
                  formValue={formValues.hoursToPerform?.handleLargeObject ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      handleLargeObject: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Handle Small Objects'
                  formValue={
                    formValues.hoursToPerform?.handleSmallObjects ?? ''
                  }
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      handleSmallObjects: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <ArraySelection
                  round
                  title='Reach for Things'
                  formValue={formValues.hoursToPerform?.reachToThings ?? ''}
                  handleItemClick={(v) => {
                    const newValues = {
                      ...formValues.hoursToPerform,
                      reachToThings: v as number,
                    }
                    handleChange({ name: 'hoursToPerform', value: newValues })
                  }}
                  values={Array(24)
                    .fill(0)
                    .map((_, i) => i + 1)}
                />
                <Box height={'40px'} width={'100%'}></Box>
                <CommonRadioBox
                  title='Did you lift?'
                  YesTitle='I lifted'
                  NoTitle="I didn't lift"
                  commonState={formValues.isLifitingInJob}
                  setcommonState={(value) => {
                    handleChange({ name: 'isLifitingInJob', value })
                  }}
                  error={errors.isLifitingInJob}
                />
                {formValues.isLifitingInJob ? (
                  <>
                    <Name
                      name='whatLiftedAndHowOften'
                      title='What was lifted, and how often, in this job?'
                      multiline
                      value={formValues.whatLiftedAndHowOften ?? ''}
                      handleChange={handleChange}
                      error={errors?.whatLiftedAndHowOften}
                    />
                    <Name
                      number
                      name='howFarCarryItems'
                      title='How far did you carry these items? (in feet)'
                      value={formValues.howFarCarryItems ?? ''}
                      handleChange={handleChange}
                      error={errors?.howFarCarryItems}
                    />
                    <ArraySelection
                      title='What was the heaviest weight lifted?'
                      formValue={formValues.heaviestWeightLifted ?? ''}
                      handleItemClick={(v) => {
                        handleChange({ name: 'heaviestWeightLifted', value: v })
                      }}
                      values={[
                        '< 10 lbs',
                        ' 11-20 lbs',
                        ' 21-50 lbs',
                        ' 51-100 lbs',
                        ' 101 + lbs',
                      ]}
                    />
                    <ArraySelection
                      title='How much weight was lifted 30% of the day or more?'
                      formValue={formValues.howmuchWeightLiftedPerDay ?? ''}
                      handleItemClick={(v) => {
                        handleChange({
                          name: 'howmuchWeightLiftedPerDay',
                          value: v,
                        })
                      }}
                      values={[
                        '< 10 lbs',
                        ' 11-20 lbs',
                        ' 21-50 lbs',
                        ' 51-100 lbs',
                        ' 101 + lbs',
                      ]}
                    />
                  </>
                ) : (
                  <></>
                )}
                <Box height={'40px'} width={'100%'}></Box>
                <CommonRadioBox
                  title='Did you supervise other people?'
                  YesTitle='I supervised other people'
                  NoTitle='I did not supervise other people'
                  commonState={formValues.didSuperviseOther}
                  setcommonState={(value) => {
                    handleChange({ name: 'didSuperviseOther', value })
                  }}
                  error={errors.didSuperviseOther}
                />
                {formValues.didSuperviseOther ? (
                  <>
                    <StarText
                      text={`Great. Now, we just have three quick follow-up questions.`}
                    />
                    <ArraySelection
                      title='What percentage of your time was spent supervising people?'
                      formValue={
                        formValues.percentageOfTimeSpentSupervising ?? ''
                      }
                      handleItemClick={(v) => {
                        handleChange({
                          name: 'percentageOfTimeSpentSupervising',
                          value: v,
                        })
                      }}
                      values={Array(10)
                        .fill(0)
                        .map((_, i) => `${(i + 1) * 10}%`)}
                    />
                    <ArraySelection
                      title='How many people did you supervise?'
                      formValue={formValues.howManyPeopleSupervise ?? ''}
                      handleItemClick={(v) => {
                        handleChange({
                          name: 'howManyPeopleSupervise',
                          value: v,
                        })
                      }}
                      values={[
                        ' 1-10 ',
                        ' 11-50 ',
                        ' 51-100 ',
                        ' 101-250 ',
                        ' 251-500 ',
                        ' 501 + ',
                      ]}
                    />
                    <CommonRadioBox
                      title='Did you hire and fire employees?'
                      YesTitle='I hired and fired employees'
                      NoTitle='I did not hire or fire employees'
                      commonState={formValues.didHireOrFirePeople}
                      setcommonState={(value) => {
                        handleChange({ name: 'didHireOrFirePeople', value })
                      }}
                      error={errors.didHireOrFirePeople}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {formValues.oneOrMoreJobsIn15Years ===
              'I had more than one job during the last 15 years before I was unable to work.' ??
            formValues.didSuperviseOther === false ? (
              <>
                <StarText
                  text={`Knowing this, we now want to understand if any of your job responsibilities included managerial or mentoring duties.`}
                />
                <CommonRadioBox
                  title='Did you lead or mentor other workers?'
                  YesTitle='I did lead or mentor other workers'
                  NoTitle='I did not lead or mentor other workers'
                  commonState={formValues.didLeadOrMentorOthers}
                  setcommonState={(value) => {
                    handleChange({ name: 'didLeadOrMentorOthers', value })
                  }}
                  error={errors.didLeadOrMentorOthers}
                />
              </>
            ) : (
              <></>
            )}
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={() => {
                  handleEditToggle()
                }}
              >
                Discard
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
                // onClick={handleNext}
              >
                Save & Update
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default JobDetails
