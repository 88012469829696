/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

interface ContactInfo {
  question: string
  answer: any
}

interface Address {
  city: string
  state: string
  country: string
  postalCode: string
  streetAddress: string
}
const DisabilityDetailsTable: React.FC = ({ question }: { question: any }) => {
  // combines address object into a string
  const combineAddress = (address: Address): string => {
    return `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode}, ${address.country}`
  }

  // Function to render military service information or any iterable answer in tabular format
  const renderIterableAnswer = (answer: any[], iterable: string) => {
    if (iterable === 'LegalNames') {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Middle Name</TableCell>
                <TableCell>Last Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer.map((name, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{name.firstName}</TableCell>
                  <TableCell>{name.middleName}</TableCell>
                  <TableCell>{name.lastName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (iterable === 'otherSocialSecurityNumbers') {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Social Security Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer.map((ssn, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{ssn}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    // Add logic here for future iterable arrays if needed
    return null // Return null if no matching iterable
  }

  // Helper function to format address object into a string
  const formatAddress = (address: any) => {
    const { streetAddress, city, state, postalCode, country } = address
    return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
  }
  // Common function to format dates from 'YYYY-MM-DD' to 'DD MMMM YYYY'
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
    const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
  }

  const renderAnswer = (item: any) => {
    const { answer, iterable } = item
    if (typeof answer === 'boolean') {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === true}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>Yes</Typography>}
            style={{ color: 'black' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === false}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>No</Typography>}
            style={{ color: 'black' }}
          />
        </FormGroup>
      )
    }

    if (Array.isArray(answer) && typeof answer[0] === 'string') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((item, index) => (
                <TableRow key={index}>
                  {/* <TableCell>{index + 1}</TableCell> */}
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    // Check if the answer is a date string in the format YYYY-MM-DD
    const datePattern = /^\d{4}-\d{2}-\d{2}$/
    if (typeof answer === 'string' && datePattern.test(answer)) {
      // Use the common formatDate function to format the date
      return formatDate(answer)
    }

    if (Array.isArray(answer) && iterable) {
      // Handle iterable answers (like MilitaryServiceInformation)
      return renderIterableAnswer(answer, iterable)
    }

    if (
      typeof answer === 'object' &&
      answer?.streetAddress &&
      answer?.city &&
      answer?.state &&
      answer?.postalCode &&
      answer?.country
    ) {
      // If it's an address object, format it into a string
      return formatAddress(answer)
    }

    if (typeof answer === 'object') {
      return JSON.stringify(answer, null, 2)
    }

    return answer
  }

  const renderTable = (info: ContactInfo[]) => (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {info.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '30%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px' }}>
                    {item.question || item.iterable}
                  </span>
                </td>
                <td style={{ width: '70%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px', fontWeight: 500 }}>
                    {renderAnswer(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    // <Paper elevation={3} style={{ padding: '16px' }}>
    <Box p={'16px'}>
      <Box mb={4}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Typography
            fontSize={'22px'}
            textAlign={'center'}
            fontWeight={'bold'}
            gutterBottom
          >
            Disability Details
          </Typography>
        </Grid>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Disability Background
          </Typography>
        </Box>
        {renderTable(question?.disabilityBackground)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Disability and Employment
          </Typography>
        </Box>
        {renderTable(question?.disabilityEmployment)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Payout and Sattlement
          </Typography>
        </Box>
        {renderTable(question?.payoutsAndSettlements)}
      </Box>
    </Box>
    // </Paper>
  )
}

export default DisabilityDetailsTable
