import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, type FC } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom'
import { ViewLatestOrganization } from 'src/Redux/Slices/DashboardSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { SetSelectedOrganization } from 'src/Redux/Slices/orgSlice'
const LatestOrganization: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(ViewLatestOrganization())
  }, [])
  const latestOrganization = useAppSelector(
    (state) => state?.dashboard?.data?.latestOrganization,
  )
  const [icon, setIcon] = React.useState('')

  function formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters from the Phone number
    const cleanedPhoneNumber = phoneNumber?.replace(/\D/g, '')

    // Check if the Phone number is at least 10 digits long
    if (cleanedPhoneNumber?.length < 10) {
      return phoneNumber
    }

    // Format the Phone number
    const countryCode = '+1'
    const areaCode = cleanedPhoneNumber?.slice(0, 3)
    const prefix = cleanedPhoneNumber?.slice(3, 6)
    const lineNumber = cleanedPhoneNumber?.slice(6)
    return `(${countryCode}) ${areaCode} ${prefix} ${lineNumber}`
  }

  const indicator = (index: number) => {
    if (index === 0) {
      setIcon(Icons.LtsOrgDot)
    } else if (index === 1) {
      setIcon(Icons.LtsOrgDot2)
    } else {
      setIcon(Icons.LtsOrgDot3)
    }
  }

  return (
    <Box className={Style.OrganizationBox}>
      <Grid xs={12}>
        <Typography
          className={Style.OrganizationTitle}
          onClick={() => {
            navigate(ROUTES.ORGANIZATIONS)
          }}
        >
          Latest Organizations
          <img src={icon} alt='' style={{ marginRight: '4px' }} />
        </Typography>
      </Grid>
      <Carousel
        indicators={false}
        animation='slide'
        interval={3500}
        onChange={(now) => {
          indicator(now ?? 0)
        }}
      >
        {latestOrganization?.length &&
          latestOrganization?.map(
            (LatestOrganizationData: any, index: number) => (
              <>
                <Box
                  key={index}
                  className={Style.OrganizationBoxSub}
                  // onClick={() => {
                  //   navigate(ROUTES.ORGANIZATION_DETAIL,
                  //     { state: LatestOrganizationData.uuid })
                  // }}
                  onClick={() => {
                    dispatch(SetSelectedOrganization(LatestOrganizationData))
                    navigate(ROUTES.ORGANIZATION_DETAIL)
                  }}
                >
                  <Grid xs={12} className={Style.OrganizationGrid}>
                    <Grid sx={{ display: 'flex', marginRight: '20px' }}>
                      <Box className={Style.OrganizationGridBox}>
                        <img
                          className='LtsOrgBlack'
                          width={'52px'}
                          height={'40px'}
                          src={
                            LatestOrganizationData?.profileUrl?.length
                              ? LatestOrganizationData?.profileUrl
                              : Icons?.organization
                          }
                          alt=''
                        />
                      </Box>
                    </Grid>
                    <Grid xs={10}>
                      <Typography className={Style.OrganizationGridTitle}>
                        {LatestOrganizationData?.name}
                      </Typography>
                      <Typography className={Style.OrganizationGridLabel}>
                        <img
                          src={Icons.location}
                          alt='location'
                          style={{ marginRight: '4px' }}
                        />
                        {(LatestOrganizationData?.address?.streetAddress
                          ? LatestOrganizationData?.address?.streetAddress +
                            ', '
                          : '') +
                          (LatestOrganizationData?.address?.state
                            ? LatestOrganizationData?.address?.state
                            : '') +
                          (LatestOrganizationData?.address?.state &&
                          LatestOrganizationData?.address?.country
                            ? ', '
                            : '') +
                          (LatestOrganizationData?.address?.country
                            ? LatestOrganizationData?.address?.country
                            : '') +
                          (LatestOrganizationData?.address?.postalCode
                            ? ' - '
                            : '') +
                          (LatestOrganizationData?.address?.postalCode
                            ? LatestOrganizationData?.address?.postalCode
                            : '') +
                          '.'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} py={'10px'}>
                    <Typography className={Style.OrganizationGridDec}>
                      {LatestOrganizationData?.description}
                    </Typography>
                  </Grid>
                  <Grid xs={12} className={Style.OrganizationGridLine} />
                  <Grid item xs={12} display='flex' py={'10px'}>
                    <Grid item xs={6} md={6} display='flex'>
                      <Typography className={Style.OrganizationGridName}>
                        {LatestOrganizationData?.firstname +
                          ' ' +
                          LatestOrganizationData?.lastname}
                      </Typography>
                      <div className={Style.Dot} />
                      <Typography className={Style.OrganizationGridTxt}>
                        {LatestOrganizationData?.address?.country}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      display='flex'
                      justifyContent={'flex-end'}
                    >
                      <Typography className={Style.OrganizationGridTxtEnd}>
                        {LatestOrganizationData?.createdat.slice(0, 10)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} display='flex' gap={5}>
                    <Typography className={Style.OrganizationGridTxt}>
                      <img
                        style={{ paddingRight: '5px' }}
                        src={Icons.phone}
                        alt='phone'
                      />
                      {LatestOrganizationData?.phonenumber
                        ? formatPhoneNumber(LatestOrganizationData?.phonenumber)
                        : ''}
                    </Typography>

                    <Typography className={Style.OrganizationGridTxt}>
                      <img
                        style={{ paddingRight: '5px' }}
                        src={Icons.email}
                        alt='email'
                      />
                      {LatestOrganizationData?.emailid}
                    </Typography>
                  </Grid>
                </Box>
              </>
            ),
          )}
      </Carousel>
    </Box>
  )
}

export default LatestOrganization
