import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApplicationIntroBox, Name } from 'src/Components/Fields'
import { SelfEmployementType } from 'src/Redux/Services/Types/applicationType'
import { EditSelfEmployement } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setSelfEmployement } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import Style from '../index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { currentYear, nextYear, previousYear } from 'src/Utils/Constants'

const SelfEmployement = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleNext: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) =>
      state?.applicationStore?.EducationAndEmployement?.SelfEmployement,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<SelfEmployementType>({
    educationId,
    isSelfEmployed: undefined,
    isSelfEmployedDuringLastYear: undefined,
    typeOfLastYearBusiness: '',
    profitMoreAmountLastYear: undefined,
    totalEarnedAmountLastYear: '',
    isSelfEmployedDuringCurrentYear: undefined,
    typeOfCurrentYearBusiness: '',
    profitMoreAmountCurrentYear: undefined,
    totalEarnedAmountCurrentYear: '',
    isSelfEmployedNextYear: undefined,
    typeOfNextYearBusiness: '',
    profitMoreAmountNextYear: undefined,
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setErrors({})
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isSelfEmployed:
              formValues?.isSelfEmployed === undefined
                ? 'Boolean'
                : formValues?.isSelfEmployed,
            isSelfEmployedDuringLastYear:
              formValues?.isSelfEmployedDuringLastYear === undefined
                ? 'Boolean'
                : formValues?.isSelfEmployedDuringLastYear,
            profitMoreAmountLastYear:
              formValues?.profitMoreAmountLastYear === undefined
                ? 'Boolean'
                : formValues?.profitMoreAmountLastYear,
            isSelfEmployedDuringCurrentYear:
              formValues?.isSelfEmployedDuringCurrentYear === undefined
                ? 'Boolean'
                : formValues?.isSelfEmployedDuringCurrentYear,
            profitMoreAmountCurrentYear:
              formValues?.profitMoreAmountCurrentYear === undefined
                ? 'Boolean'
                : formValues?.profitMoreAmountCurrentYear,
            isSelfEmployedNextYear:
              formValues?.isSelfEmployedNextYear === undefined
                ? 'Boolean'
                : formValues?.isSelfEmployedNextYear,
            profitMoreAmountNextYear:
              formValues?.profitMoreAmountNextYear === undefined
                ? 'Boolean'
                : formValues?.profitMoreAmountNextYear,
          },
        }),
      )
  }, [isBotOpen])
  // useEffect(() => {
  //   setErrors({ ...errors, state: '', country: '' })
  // }, [formValues.state, formValues.country])

  const handleChange = (data: {
    name: string
    value?: string | boolean | number
  }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (formValues.isSelfEmployed === undefined) {
      newErrors.isSelfEmployed = 'Please select an option'
      isValid = false
    }
    if (formValues.isSelfEmployed) {
      if (formValues.isSelfEmployedDuringLastYear === undefined) {
        newErrors.isSelfEmployedDuringLastYear = 'Please select an option'
        isValid = false
      }
      if (formValues.isSelfEmployedDuringLastYear) {
        if (formValues.profitMoreAmountLastYear === undefined) {
          newErrors.profitMoreAmountLastYear = 'Please select an option'
          isValid = false
        }
        if (formValues.typeOfLastYearBusiness === '') {
          newErrors.typeOfLastYearBusiness = 'Please enter type of business'
          isValid = false
        }
        if (formValues.profitMoreAmountLastYear) {
          if (formValues.totalEarnedAmountLastYear === '') {
            newErrors.totalEarnedAmountLastYear = 'Please enter amount'
            isValid = false
          }
        }
      }
      if (formValues.isSelfEmployedDuringCurrentYear === undefined) {
        newErrors.isSelfEmployedDuringCurrentYear = 'Please select an option'
        isValid = false
      }
      if (formValues.isSelfEmployedDuringCurrentYear) {
        if (formValues.profitMoreAmountCurrentYear === undefined) {
          newErrors.profitMoreAmountCurrentYear = 'Please select an option'
          isValid = false
        }
        if (formValues.typeOfCurrentYearBusiness === '') {
          newErrors.typeOfCurrentYearBusiness = 'Please enter type of business'
          isValid = false
        }
        if (formValues.profitMoreAmountCurrentYear) {
          if (formValues.totalEarnedAmountCurrentYear === '') {
            newErrors.totalEarnedAmountCurrentYear = 'Please enter amount'
            isValid = false
          }
        }
      }
    }
    if (formValues.isSelfEmployedNextYear === undefined) {
      newErrors.isSelfEmployedNextYear = 'Please select an option'
      isValid = false
    }
    if (formValues.isSelfEmployedNextYear) {
      if (formValues.profitMoreAmountNextYear === undefined) {
        newErrors.profitMoreAmountNextYear = 'Please select an option'
        isValid = false
      }
      if (formValues.typeOfNextYearBusiness === '') {
        newErrors.typeOfNextYearBusiness = 'Please enter type of business'
        isValid = false
      }
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: SelfEmployementType = {}
      payload.educationId = formValues.educationId
      payload.isSelfEmployed = formValues.isSelfEmployed
      payload.isSelfEmployedNextYear = formValues.isSelfEmployedNextYear
      if (formValues.isSelfEmployedNextYear) {
        payload.profitMoreAmountNextYear = formValues.profitMoreAmountNextYear
        payload.typeOfNextYearBusiness = formValues.typeOfNextYearBusiness
      }
      if (formValues.isSelfEmployed) {
        payload.isSelfEmployedDuringLastYear =
          formValues.isSelfEmployedDuringLastYear
        if (formValues.isSelfEmployedDuringLastYear) {
          payload.profitMoreAmountLastYear = formValues.profitMoreAmountLastYear
          payload.typeOfLastYearBusiness = formValues.typeOfLastYearBusiness
          if (formValues.profitMoreAmountLastYear) {
            payload.totalEarnedAmountLastYear =
              formValues.totalEarnedAmountLastYear
          }
        }
        payload.isSelfEmployedDuringCurrentYear =
          formValues.isSelfEmployedDuringCurrentYear
        if (formValues.isSelfEmployedDuringCurrentYear) {
          payload.profitMoreAmountCurrentYear =
            formValues.profitMoreAmountCurrentYear
          payload.typeOfCurrentYearBusiness =
            formValues.typeOfCurrentYearBusiness
          if (formValues.profitMoreAmountCurrentYear) {
            payload.totalEarnedAmountCurrentYear =
              formValues.totalEarnedAmountCurrentYear
          }
        }
      }

      dispatch(setSelfEmployement(formValues))
      dispatch(EditSelfEmployement(payload)).then((res) => {
        if (res?.payload?.success) {
          handleNext()
        }
      })
    }
  }

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="You're on a roll! Next up: Self-Employment. " />
            <CommonRadioBox
              title='Have you been self-employed in the last 15 years?'
              YesTitle='I have been self-employed in the last 15 years'
              NoTitle='I have not been self-employed in the last 15 years'
              commonState={formValues.isSelfEmployed}
              setcommonState={(value) => {
                handleChange({ name: 'isSelfEmployed', value })
              }}
              error={errors.isSelfEmployed}
            />
            {formValues.isSelfEmployed ? (
              <>
                <Box height={'40px'} width={'100%'}></Box>
                <CommonRadioBox
                  title={`Have you been self-employed during ${previousYear}?`}
                  YesTitle={`I have been self-employed during ${previousYear}`}
                  NoTitle={`I have not been self-employed during ${previousYear}`}
                  commonState={formValues.isSelfEmployedDuringLastYear}
                  setcommonState={(value) => {
                    handleChange({
                      name: 'isSelfEmployedDuringLastYear',
                      value,
                    })
                  }}
                  error={errors.isSelfEmployedDuringLastYear}
                />
                {formValues.isSelfEmployedDuringLastYear ? (
                  <>
                    <Name
                      name='typeOfLastYearBusiness'
                      title='Noted! What type of business was it?'
                      multiline
                      value={formValues.typeOfLastYearBusiness ?? ''}
                      handleChange={handleChange}
                      error={errors?.typeOfLastYearBusiness}
                    />
                    <CommonRadioBox
                      title={`Did you profit more than $400 from the self-employed job in ${previousYear}?`}
                      YesTitle={`I profited more than $400 from the self-employed job in ${previousYear}`}
                      NoTitle={`I did not profit more than $400 from the self-employed job in ${previousYear}`}
                      commonState={formValues.profitMoreAmountLastYear}
                      setcommonState={(value) => {
                        handleChange({
                          name: 'profitMoreAmountLastYear',
                          value,
                        })
                      }}
                      error={errors.profitMoreAmountLastYear}
                    />
                    {formValues.profitMoreAmountLastYear ? (
                      <>
                        <Name
                          name='totalEarnedAmountLastYear'
                          handleChange={handleChange}
                          value={formValues?.totalEarnedAmountLastYear}
                          title={`What was the total of all wages and tips earned during ${previousYear}?`}
                          error={errors?.totalEarnedAmountLastYear}
                          preInput='$'
                          number
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Box height={'40px'} width={'100%'}></Box>
                <CommonRadioBox
                  title={`Have you been self-employed during ${currentYear}?`}
                  YesTitle={`I have been self-employed during ${currentYear}`}
                  NoTitle={`I have not been self-employed during ${currentYear}`}
                  commonState={formValues.isSelfEmployedDuringCurrentYear}
                  setcommonState={(value) => {
                    handleChange({
                      name: 'isSelfEmployedDuringCurrentYear',
                      value,
                    })
                  }}
                  error={errors.isSelfEmployedDuringCurrentYear}
                />
                {formValues.isSelfEmployedDuringCurrentYear ? (
                  <>
                    <Name
                      name='typeOfCurrentYearBusiness'
                      title='Great! What type of business was it?'
                      multiline
                      value={formValues.typeOfCurrentYearBusiness ?? ''}
                      handleChange={handleChange}
                      error={errors?.typeOfCurrentYearBusiness}
                    />
                    <CommonRadioBox
                      title={`Did you profit more than $400 from the self-employed job in ${currentYear}?`}
                      YesTitle={`I profited more than $400 from the self-employed job in ${currentYear}`}
                      NoTitle={`I did not profit more than $400 from the self-employed job in ${currentYear}`}
                      commonState={formValues.profitMoreAmountCurrentYear}
                      setcommonState={(value) => {
                        handleChange({
                          name: 'profitMoreAmountCurrentYear',
                          value,
                        })
                      }}
                      error={errors.profitMoreAmountCurrentYear}
                    />
                    {formValues.profitMoreAmountCurrentYear ? (
                      <>
                        <Name
                          name='totalEarnedAmountCurrentYear'
                          handleChange={handleChange}
                          value={formValues?.totalEarnedAmountCurrentYear}
                          title={`What was the total of all wages and tips earned during ${currentYear}?`}
                          error={errors?.totalEarnedAmountCurrentYear}
                          preInput='$'
                          number
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <Box height={'40px'} width={'100%'}></Box>
            <CommonRadioBox
              title={`Do you plan to be self-employed in ${nextYear}?`}
              YesTitle={`I plan to be self-employed during ${nextYear}`}
              NoTitle={`I do not plan to be self-employed during ${nextYear}`}
              commonState={formValues.isSelfEmployedNextYear}
              setcommonState={(value) => {
                handleChange({
                  name: 'isSelfEmployedNextYear',
                  value,
                })
              }}
              error={errors.isSelfEmployedNextYear}
            />
            {formValues.isSelfEmployedNextYear ? (
              <>
                <Name
                  name='typeOfNextYearBusiness'
                  title='Noted! What type of business will it be?'
                  multiline
                  value={formValues.typeOfNextYearBusiness ?? ''}
                  handleChange={handleChange}
                  error={errors?.typeOfNextYearBusiness}
                />
                <CommonRadioBox
                  title={`Will you profit more than $400 from the self-employed job in ${nextYear}?`}
                  YesTitle={`I will profit more than $400 from the self-employed job in ${nextYear}`}
                  NoTitle={`I will not profit more than $400 from the self-employed job in ${nextYear}`}
                  commonState={formValues.profitMoreAmountNextYear}
                  setcommonState={(value) => {
                    handleChange({
                      name: 'profitMoreAmountNextYear',
                      value,
                    })
                  }}
                  error={errors.profitMoreAmountNextYear}
                />
              </>
            ) : (
              <></>
            )}
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default SelfEmployement
