interface QuestionType {
  question: string
  answerPath: string | string[]
  iterable?: string // Optional iterable property
}

export interface ApplicationPDFType {
  Assistance_Data: {
    Assistance_Programs: QuestionType[]
    assigneeData: QuestionType[]
  }
  Basic_Details: {
    contactInformation: QuestionType[]
    communication: QuestionType[]
    citizenship: QuestionType[]
    Military_Service: QuestionType[]
    Pass_Application: QuestionType[]
  }
  Family_Details: {
    Current_Significant: QuestionType[]
    Past_Significant: QuestionType[]
    Dependents: QuestionType[]
    Parents: QuestionType[]
  }
  Legal_Details: {
    legalNames: QuestionType[]
    identificationNumber: QuestionType[]
    receivingDisabilityBenefitPayment: QuestionType[]
  }
  Disability_Details: {
    disabilityBackground: QuestionType[]
    disabilityEmployment: QuestionType[]
    payoutsAndSettlements: QuestionType[]
  }
  Education_Employment_Details: {
    education: QuestionType[]
    specialEducation: QuestionType[]
    specializedTradeEducationAndTraining: QuestionType[]
    employerDetail: QuestionType[]
    jobDetail: QuestionType[]
    childcare: QuestionType[]
    selfEmployment: QuestionType[]
    foreignWork: QuestionType[]
    workTaxDetail: QuestionType[]
  }
  Medical_Details: {
    GeneralHealth: QuestionType[]
    VisionAndHearing: QuestionType[]
    IllnessesInjuriesAndConditions: QuestionType[]
    MedicalProfessionals: QuestionType[]
    Hospital_Or_Medical_Facility_Care: QuestionType[]
    Medical_Test: QuestionType[]
    Medicine: QuestionType[]
    AdditionalInformation: QuestionType[]
  }
}

const ApplicationData: ApplicationPDFType = {
  Assistance_Data: {
    Assistance_Programs: [
      {
        question: 'Assistant full name?',
        answerPath: 'assistancedata[0]',
      },
      {
        question: 'What is the name of the assistance organization?',
        answerPath: 'assistancedata[0].organizationName',
      },
      {
        question: 'What is the relationship to the applicant?',
        answerPath: 'assistancedata[0].relationToApplicant',
      },
      {
        question: 'What is the address of the assistance organization?',
        answerPath: 'assistancedata[0].address',
      },
      {
        question: 'What is the email ID of the assistance organization?',
        answerPath: 'assistancedata[0].emailId',
      },
      {
        question: 'What is the phone number of the assistance organization?',
        answerPath: 'assistancedata[0].phoneNumber',
      },
    ],
    assigneeData: [
      {
        question: 'What is the first name of the assignee?',
        answerPath: 'assigneedata[0].firstName',
      },
      {
        question: 'What is the last name of the assignee?',
        answerPath: 'assigneedata[0].lastName',
      },
    ],
  },
  Basic_Details: {
    contactInformation: [
      {
        question: 'What is applicant’s full name?',
        answerPath: 'basicdata[0].contactInformation',
      },
      // {
      //   question: 'What is applicant’s middle name?',
      //   answerPath: 'basicdata[0].contactInformation.middleName',
      // },
      // {
      //   question: 'What is applicant’s last name?',
      //   answerPath: 'basicdata[0].contactInformation.lastName',
      // },
      {
        question: 'What is applicant’s physical address?',
        answerPath: 'basicdata[0].contactInformation.physicalAddress',
      },
      {
        question: 'Does applicant live at this physical address?',
        answerPath: 'basicdata[0].contactInformation.isLiveAtPhysicalAddress',
      },
      {
        question:
          'Please provide the address where applicant currently reside. What is applicant’s reside address?',
        answerPath: 'basicdata[0].contactInformation.resideAddress',
      },
      {
        question:
          'Does applicant want to receive mail at this physical address?',
        answerPath:
          'basicdata[0].contactInformation.wantToReceiveMailAtPhysicalAddress',
      },
      {
        question: "What's applicant’s preferred mailing address?",
        answerPath: 'basicdata[0].contactInformation.mailAddress',
      },
      {
        question: 'Is applicant’s phone number a USA number?',
        answerPath: 'basicdata[0].contactInformation.isUSAPhoneNumber',
      },
      {
        question: 'What is applicant’s phone number?',
        answerPath: 'basicdata[0].contactInformation.phoneNumber',
      },
      {
        question: 'What is applicant’s email ID?',
        answerPath: 'basicdata[0].contactInformation.emailId',
      },
    ],
    communication: [
      {
        question:
          'Is applicant’s preferred language for reading, writing, and speaking English?',
        answerPath: 'basicdata[0].communication.isPreferredLanguageEnglish',
      },
      {
        question: 'What is applicant’s preferred reading language?',
        answerPath: 'basicdata[0].communication.preferredReadingLanguage',
      },
      {
        question: 'What is applicant’s preferred writing language?',
        answerPath: 'basicdata[0].communication.preferredWritingLanguage',
      },
      {
        question: 'What is applicant’s preferred speaking language?',
        answerPath: 'basicdata[0].communication.preferredSpeakingLanguage',
      },
      {
        question: 'Can applicant read English?',
        answerPath: 'basicdata[0].communication.canReadEnglish',
      },
      {
        question: 'Can applicant write in English?',
        answerPath: 'basicdata[0].communication.canWriteEnglish',
      },
      {
        question: 'Can applicant speak English?',
        answerPath: 'basicdata[0].communication.canSpeakEnglish',
      },
      {
        question:
          'Is applicant able to read and write more than applicant’s name?',
        answerPath: 'basicdata[0].communication.isAbleToReadWriteMoreThenName',
      },
    ],
    citizenship: [
      {
        question: 'Was applicant born in the U.S. or a U.S. territory?',
        answerPath: 'basicdata[0].citizenship.wereBornInUSA',
      },
      {
        question: 'What city was applicant born in?',
        answerPath: 'basicdata[0].citizenship.bornUSACityName',
      },
      {
        question: 'What state was applicant born in?',
        answerPath: 'basicdata[0].citizenship.bornUSAStateName',
      },
      {
        question: 'What country was applicant born in?',
        answerPath: 'basicdata[0].citizenship.bornCountry',
      },
      {
        question: 'Is applicant a confirmed USA citizen?',
        answerPath: 'basicdata[0].citizenship.confirmUSACitizen',
      },
      {
        question: 'What is applicant’s qualified USA citizenship status?',
        answerPath: 'basicdata[0].citizenship.qualifiedUSACitizenship',
      },
      {
        question: 'What is the date of applicant’s citizenship?',
        answerPath: 'basicdata[0].citizenship.dateOfCitizenship',
      },
      {
        question: 'What countries is applicant a citizen of?',
        answerPath: 'basicdata[0].citizenship.citizenshipCountryName',
      },
      {
        question: 'Is applicant going through the process of USA citizenship?',
        answerPath:
          'basicdata[0].citizenship.isGoingThroughProcessOfUSACitizenship',
      },
      {
        question: 'Is applicant a legal resident of the USA?',
        answerPath: 'basicdata[0].citizenship.isLegalResidentOfUSA',
      },
      {
        question: 'What is applicant’s resident card number?',
        answerPath: 'basicdata[0].citizenship.residentCardNumber',
      },
    ],
    Military_Service: [
      {
        question:
          'Did applicant serve or is applicant serving in the U.S. military?',
        answerPath: 'basicdata[0].militaryService.isServedInMilitary',
      },
      // Array
      {
        iterable: 'MilitaryServiceInformation',
        question: 'Military Service Information',
        answerPath: 'basicdata[0].militaryService.serviceInformation',
      },
      {
        question:
          'Is applicant receiving or eligible to receive a military or civilian federal agency benefit?',
        answerPath:
          'basicdata[0].militaryService.isReceivingFederalAgencyBenefit',
      },
      {
        question: 'What type of benefit is applicant receiving?',
        answerPath: 'basicdata[0].militaryService.typeOfBenefit',
      },
    ],
    Pass_Application: [
      {
        question: 'Is applicant currently receiving Medicare benefits?',
        answerPath: 'basicdata[0].pastApplication.isReceivingMedicareBenefits',
      },
      {
        question: 'Has applicant applied for Medicare benefits?',
        answerPath:
          'basicdata[0].pastApplication.haveAppliedForMedicareBenefits',
      },
      {
        question:
          'What is the status of applicant’s previous Medicare benefit?',
        answerPath:
          'basicdata[0].pastApplication.statusOfPreviousMedicareBenefit',
      },
      {
        question:
          'Is applicant currently receiving Social Security benefits (SSDI)?',
        answerPath: 'basicdata[0].pastApplication.isReceivingSSDI',
      },
      {
        question:
          'Has applicant previously applied for Social Security benefits (SSDI)?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedForSSDI',
      },
      {
        question:
          "What's the status of the Social Security benefits program for which applicant previously applied (SSDI)?",
        answerPath: 'basicdata[0].pastApplication.statusOfPreviousSSDI',
      },
      {
        question: 'What is the SSDI approved end date (estimate if unknown)?',
        answerPath: 'basicdata[0].pastApplication.SSDIApprovedEndDate',
      },
      {
        question: 'Has applicant been denied SSDI within 60 days?',
        answerPath: 'basicdata[0].pastApplication.isSSDIDenial60Days',
      },
      {
        question: 'What is the denial date for SSDI?',
        answerPath: 'basicdata[0].pastApplication.denialDateSSDI',
      },
      {
        question:
          'Is applicant currently receiving Supplemental Security Income (SSI)?',
        answerPath: 'basicdata[0].pastApplication.isReceivingSSI',
      },
      {
        question:
          'Has applicant previously applied for Supplemental Security Income (SSI)?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedForSSI',
      },
      {
        question:
          "What's the status of the Supplemental Security Income (SSI) benefits program for which applicant previously applied?",
        answerPath: 'basicdata[0].pastApplication.statusOfSSI',
      },
      {
        question: 'What is the SSI approved end date?',
        answerPath: 'basicdata[0].pastApplication.SSIApprovedEndDate',
      },
      {
        question: 'Has applicant been denied SSI within 60 days?',
        answerPath: 'basicdata[0].pastApplication.isSSIDenial60Days',
      },
      {
        question: 'What is the denial date for SSI?',
        answerPath: 'basicdata[0].pastApplication.denialDateSSI',
      },
      {
        question: 'Has applicant applied with SSN?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedWithSSN',
      },
      {
        question: 'Has applicant applied for additional benefits?',
        answerPath: 'basicdata[0].pastApplication.appliedForAdditionalBenefits',
      },
      {
        question: 'What programs is applicant currently participating in?',
        answerPath:
          'basicdata[0].pastApplication.currentlyparticipatingPrograms',
      },
      // Array
      {
        iterable: 'benefitAppliedSSNDetail',
        question:
          'who’s social security number (SSN) for Benefits were applied to',
        answerPath: 'basicdata[0].pastApplication.benefitAppliedSSNDetail',
      },
      // Array
      {
        iterable: 'participateProgramDetails',
        question: 'What programs did applicant participate in?',
        answerPath: 'basicdata[0].pastApplication.participateProgramDetails',
      },
    ],
  },
  Family_Details: {
    Current_Significant: [
      {
        question: 'Is applicant currently legally married?',
        answerPath: 'familydata[0].isLegallyMarried',
      },
      {
        question: "What is applicant’s spouse's full name?",
        answerPath: 'familydata[0].currentSignificantOther',
      },
      // {
      //   question: "What is applicant’s spouse's last name?",
      //   answerPath: 'familydata[0].currentSignificantOther.spouseLastName',
      // },
      {
        question: "What is applicant’s spouse's date of birth?",
        answerPath: 'familydata[0].currentSignificantOther.spouseDateOfBirth',
      },
      {
        question: "What is applicant’s spouse's SSN?",
        answerPath: 'familydata[0].currentSignificantOther.spouseSSN',
      },
      {
        question: 'What type of marriage does applicant have?',
        answerPath: 'familydata[0].currentSignificantOther.typeOfMarriage',
      },
      {
        question: 'What is the date of applicant’s marriage?',
        answerPath: 'familydata[0].currentSignificantOther.dateOfMarriage',
      },
      {
        question: 'Where did applicant get married?',
        answerPath: 'familydata[0].currentSignificantOther.placeOfMarriage',
      },
      {
        question: 'Other details',
        answerPath:
          'familydata[0].currentSignificantOther.placeOfMarriage.other',
      },
    ],
    Past_Significant: [
      {
        question: 'Does applicant have any prior marriages?',
        answerPath: 'familydata[0].havePriorMarriages',
      },
      {
        question:
          'Did any of applicant’s prior marriages last 10 years or more?',
        answerPath: 'familydata[0].pastMarriage10Years',
      },
      {
        question:
          "Did any of applicant’s prior marriages end due to applicant’s spouse's death?",
        answerPath: 'familydata[0].isPriorMarriageEndDuetoSpouseDeath',
      },
      // Array
      {
        iterable: 'pastSignificantOthers',
        question: 'Past Significant Others',
        answerPath: 'familydata[0].pastSignificantOthers',
      },
    ],
    Dependents: [
      {
        question: 'Does applicant have any children?',
        answerPath: 'familydata[0].haveAnyChildern',
      },
      {
        question: 'number of children',
        answerPath: 'familydata[0].numberOfChildern',
      },
      // Array
      {
        iterable: 'dependents',
        question: 'Dependent Information:',
        answerPath: 'familydata[0].dependents',
      },
    ],
    Parents: [
      {
        question:
          'Does applicant have a parent who receives one-half support from you?',
        answerPath: 'familydata[0].isParentReceiveOneHalfSupport',
      },
      // {
      //   question: "What is applicant’s first parent's suffix?",
      //   answerPath: 'familydata[0].firstParent.suffix',
      // },
      {
        question:
          "What is applicant’s full name of applicant’s first parent's?",
        answerPath: 'familydata[0].firstParent',
      },
      // {
      //   question: "What is applicant’s first parent's middle name?",
      //   answerPath: 'familydata[0].firstParent.middleName',
      // },
      // {
      //   question: "What is applicant’s first parent's last name?",
      //   answerPath: 'familydata[0].firstParent.lastName',
      // },
      {
        question: "What is applicant’s first parent's address?",
        answerPath: 'familydata[0].firstParent.address',
      },
      {
        question:
          'Does applicant have a second parent who receives one-half support from you?',
        answerPath: 'familydata[0].isSecondParentReceiveOneHalfSupport',
      },
      // {
      //   question: "What is applicant’s second parent's suffix?",
      //   answerPath: 'familydata[0].secondParent.suffix',
      // },
      {
        question:
          "What is applicant’s full name of applicant’s second parent's?",
        answerPath: 'familydata[0].secondParent',
      },
      // {
      //   question: "What is applicant’s second parent's middle name?",
      //   answerPath: 'familydata[0].secondParent.middleName',
      // },
      // {
      //   question: "What is applicant’s second parent's last name?",
      //   answerPath: 'familydata[0].secondParent.lastName',
      // },
      {
        question: "What is applicant’s second parent's address?",
        answerPath: 'familydata[0].secondParent.address',
      },
    ],
  },
  Legal_Details: {
    legalNames: [
      {
        question: 'Has applicant used any other name?',
        answerPath: 'legalData[0].haveUsedOtherName',
      },
      // Array
      {
        iterable: 'LegalNames',
        question: 'Please provide these names:',
        answerPath: 'legalData[0].legalNames',
      },
    ],
    identificationNumber: [
      {
        question: 'What is applicant’s social security number?',
        answerPath: 'legalData[0].identificationNumber.socialSecurityNumber',
      },
      {
        question: 'Has applicant used any other social security number?',
        answerPath: 'legalData[0].identificationNumber.isUsingOtherSSN',
      },
      // Array
      {
        iterable: 'otherSocialSecurityNumbers',
        question: 'Please enter these social security numbers.',
        answerPath:
          'legalData[0].identificationNumber.otherSocialSecurityNumbers',
      },
    ],
    receivingDisabilityBenefitPayment: [
      {
        question:
          'Does applicant have a bank account that applicant can use to receive direct deposit payments?',
        answerPath: 'legalData[0].isHavingBankAccount',
      },
      {
        question: 'What is applicant’s account type?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.accountType',
      },
      {
        question: 'What is applicant’s routing number?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.routingNumber',
      },
      {
        question: 'What is applicant’s account number?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.accountNumber',
      },
    ],
  },
  Disability_Details: {
    disabilityBackground: [
      {
        question:
          'Is applicant currently able to work regardless of applicant’s illness, injury, or condition?',
        answerPath: 'disabilitydetails[0].disabilityBackground.isAbleToWork',
      },
      {
        question:
          'Is applicant’s disability expected to last 1 year or longer?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.isDisabilityExpected1YearOrLonger',
      },
      {
        question: 'What is the start date of applicant’s disability?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityStartDate',
      },
      {
        question: 'Does applicant’s disability end in death?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityEndInDeath',
      },
      {
        question: 'Is applicant able to perform applicant’s usual job?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.isAbleToPerformUsualJob',
      },
      {
        question:
          'Has applicant been unable to do applicant’s job for 14 months?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.is14MonthsUnableToDoJob',
      },
      {
        question: 'Is applicant’s disability related to work?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityRelatedToWork',
      },
    ],
    disabilityEmployment: [
      {
        question:
          'What details can applicant provide about how applicant’s disability affects applicant’s employment?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.affectingEmploymentDetail',
      },
      {
        question: 'What were applicant employed in during the last 2 years?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.employedDuringLast2YearDetail',
      },
      {
        question: 'What was the reason applicant stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.reasonToStoppedWorking',
      },
      {
        question: 'Explain why applicant stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.explainStoppedWorking',
      },
      {
        question: 'What is the date applicant stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.dateOfStoppedWorking',
      },
      {
        question: 'Does applicant require changes to applicant’s work?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.isRequireWorkChanges',
      },
      {
        question:
          'Has applicant made more money in any month since applicant’s disability?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.haveMadeMoreMoneyInAnyMonth',
      },
      {
        question: 'What is the start date of applicant’s work changes?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.workChangeStartDate',
      },
      {
        question:
          'What date did applicant’s disability keep applicant from working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.disabilityKeepFromWorkingDate',
      },
      {
        question: 'Does applicant intend to work next year?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.intendToWorkNextYear',
      },
    ],
    payoutsAndSettlements: [
      {
        question:
          'Has applicant received money from applicant’s employer on or after the date that applicant became unable to work?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.haveReceiveMoneyAfterDisability',
      },
      {
        question: 'What types of income has applicant received?',
        answerPath: 'disabilitydetails[0].payoutsAndSettlements.typeOfPays',
      },
      {
        question: 'Can applicant explain other income received?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.otherPayExplain',
      },
      {
        question:
          'Does applicant expect to receive any money from applicant’s employer in the future?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.isExpectMoneyInFuture',
      },
      {
        question: 'What is the approximate amount applicant expect to receive?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.approxAmountExpected',
      },
      {
        question: 'What types of future income does applicant expect?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.typeOfFuturePay',
      },
      {
        question: 'Can applicant explain the potential future payments?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.futureOtherPayExplain',
      },
      {
        question:
          'What is the approximate amount of future payouts applicant expect?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.approxAmountFuturePayout',
      },
    ],
  },
  Education_Employment_Details: {
    education: [
      {
        question:
          'What Is the highest level of education that applicant have completed?',
        answerPath: 'educationdetails[0].education.highestGrade',
      },
      {
        question: 'What is the name of applicant’s school?',
        answerPath: 'educationdetails[0].education.schoolName',
      },
      {
        question: 'In which city is applicant’s school located?',
        answerPath: 'educationdetails[0].education.city',
      },
      {
        question: 'In which state is applicant’s school located?',
        answerPath: 'educationdetails[0].education.state',
      },
      {
        question: 'In which country is applicant’s school located?',
        answerPath: 'educationdetails[0].education.country',
      },
      {
        question: 'When did applicant complete applicant’s education?',
        answerPath: 'educationdetails[0].education.dateEducationCompleted',
      },
    ],
    specialEducation: [
      {
        question:
          'Has applicant ever received special education through an Individualized Education Plan (IEP) or similar program?',
        answerPath: 'educationdetails[0].haveReceivedSpecialEducation',
      },
      {
        question:
          'Please provide the dates on which applicant started this program.',
        answerPath: 'educationdetails[0].specialEducation.startDate',
      },
      {
        question:
          'Please provide the dates on which applicant ended this program.',
        answerPath: 'educationdetails[0].specialEducation.endDate',
      },
      {
        question:
          'Select the last grade that applicant received special education:        ',
        answerPath: 'educationdetails[0].specialEducation.lastGrade',
      },
      {
        question: 'What was the reason for applicant’s special education?',
        answerPath:
          'educationdetails[0].specialEducation.reasonForSpecialEducation',
      },
      {
        question:
          'What is the name of the school for applicant’s special education?',
        answerPath: 'educationdetails[0].specialEducation.schoolName',
      },
      {
        question:
          'In which city is applicant’s special education school located?',
        answerPath: 'educationdetails[0].specialEducation.city',
      },
      {
        question:
          'In which state is applicant’s special education school located?',
        answerPath: 'educationdetails[0].specialEducation.state',
      },
      {
        question:
          'In which country is applicant’s special education school located?',
        answerPath: 'educationdetails[0].specialEducation.country',
      },
    ],
    specializedTradeEducationAndTraining: [
      {
        question:
          'Specialized education is quite popular, and we do like to understand if this applies to you. Has applicant completed any type of specialized job training, including trade or vocational school?',
        answerPath: 'educationdetails[0].haveSpecializedTraining',
      },
      {
        question: 'What type of specialized training did applicant receive?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.typeOfSpecializedTraining',
      },
      {
        question: 'Can applicant describe applicant’s specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.description',
      },
      {
        question:
          'What was the start date of applicant’s specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.startDate',
      },
      {
        question: 'What was the end date of applicant’s specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.endDate',
      },
      {
        question:
          'What is the name of the school for applicant’s specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.schoolName',
      },
      {
        question:
          'In which city is applicant’s specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.city',
      },
      {
        question:
          'In which state is applicant’s specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.state',
      },
      {
        question:
          'In which country is applicant’s specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.country',
      },
    ],
    employerDetail: [
      // Array
      {
        iterable: 'employerDetail',
        question: 'What is applicant’s employer?',
        answerPath: 'educationdetails[0].employerDetail',
      },
    ],
    jobDetail: [
      {
        question: 'Has applicant held one or more jobs in the last 15 years?',
        answerPath: 'educationdetails[0].jobDetail.oneOrMoreJobsIn15Years',
      },
      {
        question: 'Did applicant lead or mentor others?',
        answerPath: 'educationdetails[0].jobDetail.didLeadOrMentorOthers',
      },
      {
        question:
          'Can applicant describe applicant’s job experience over the last 15 years?',
        answerPath: 'educationdetails[0].jobDetail.jobDescriptionOf15Years',
      },
      {
        question: 'What applicable jobs did applicant hold?',
        answerPath: 'educationdetails[0].jobDetail.applicableJobs',
      },
      {
        question:
          'How many hours does applicant typically walk during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.walk',
      },
      {
        question:
          'How many hours does applicant typically stand during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.stand',
      },
      {
        question:
          'How many hours does applicant typically sit during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.sit',
      },
      {
        question:
          'How many hours does applicant typically climb during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.climb',
      },
      {
        question:
          'How many hours does applicant typically bend during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.bend',
      },
      {
        question:
          'How many hours does applicant typically kneel during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.kneel',
      },
      {
        question:
          'How many hours does applicant typically crouch during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.crounch',
      },
      {
        question:
          'How many hours does applicant typically crawl during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.crawl',
      },
      {
        question:
          'How many hours does applicant typically write or type during applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.writeOrType',
      },
      {
        question:
          'How many hours does applicant typically handle large objects during applicant’s job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.handleLargeObject',
      },
      {
        question:
          'How many hours does applicant typically reach for things during applicant’s job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.reachToThings',
      },
      {
        question:
          'How many hours does applicant typically handle small objects during applicant’s job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.handleSmallObjects',
      },
      {
        question: 'Is lifting a part of applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.isLifitingInJob',
      },
      {
        question: 'How far does applicant typically carry items?',
        answerPath: 'educationdetails[0].jobDetail.howFarCarryItems',
      },
      {
        question: 'What is the heaviest weight applicant has  lifted?',
        answerPath: 'educationdetails[0].jobDetail.heaviestWeightLifted',
      },
      {
        question: 'How much weight does applicant lift per day?',
        answerPath: 'educationdetails[0].jobDetail.howMuchWeightLiftedPerDay',
      },
      {
        question: 'Did applicant supervise others in applicant’s job?',
        answerPath: 'educationdetails[0].jobDetail.didSuperviseOther',
      },
      {
        question: 'What percentage of applicant’s time was spent supervising?',
        answerPath:
          'educationdetails[0].jobDetail.percentageOfTimeSpentSupervising',
      },
      {
        question: 'How many people did applicant supervise?',
        answerPath: 'educationdetails[0].jobDetail.howManyPeopleSupervise',
      },
      {
        question: 'Did applicant has  the authority to hire or fire people?',
        answerPath: 'educationdetails[0].jobDetail.didHireOrFirePeople',
      },
      {
        question: 'What did applicant lift and how often?',
        answerPath: 'educationdetails[0].jobDetail.whatLiftedAndHowOften',
      },
    ],
    childcare: [
      {
        question:
          'Has applicant cared for children under the age of three (3) full-time?',
        answerPath:
          'educationdetails[0].childcare.fulltimeChildcareUnder3Years',
      },
      {
        question: 'Year Started Caring for Children Under 3:',
        answerPath: 'educationdetails[0].childcare.fulltimeChildcareStartYear',
      },
      {
        question: 'Year Ended Caring for Children Under 3:',
        answerPath: 'educationdetails[0].childcare.fulltimeChildcareEndYear',
      },
    ],
    selfEmployment: [
      {
        question: 'Is applicant self-employed?',
        answerPath: 'educationdetails[0].selfEmployment.isSelfEmployed',
      },
      {
        question: 'Was applicant self-employed during the last year?',
        answerPath:
          'educationdetails[0].selfEmployment.isSelfEmployedDuringLastYear',
      },
      {
        question: 'What type of business did applicant run last year?',
        answerPath: 'educationdetails[0].selfEmployment.typeOfLastYearBusiness',
      },
      {
        question: 'Did applicant earn more profit last year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountLastYear',
      },
      {
        question: 'What was applicant’s total earned amount last year?',
        answerPath:
          'educationdetails[0].selfEmployment.totalEarnedAmountLastYear',
      },
      {
        question: 'Is applicant self-employed during the current year?',
        answerPath:
          'educationdetails[0].selfEmployment.isSelfEmployedDuringCurrentYear',
      },
      {
        question: 'What type of business is applicant running this year?',
        answerPath:
          'educationdetails[0].selfEmployment.typeOfCurrentYearBusiness',
      },
      {
        question: 'Did applicant earn more profit this year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountCurrentYear',
      },
      {
        question: 'What was applicant’s total earned amount this year?',
        answerPath:
          'educationdetails[0].selfEmployment.totalEarnedAmountCurrentYear',
      },
      {
        question: 'Will applicant be self-employed next year?',
        answerPath: 'educationdetails[0].selfEmployment.isSelfEmployedNextYear',
      },
      {
        question: 'What type of business will applicant run next year?',
        answerPath: 'educationdetails[0].selfEmployment.typeOfNextYearBusiness',
      },
      {
        question: 'Will applicant earn more profit next year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountNextYear',
      },
    ],
    foreignWork: [
      {
        question: 'Has applicant worked outside the USA?',
        answerPath: 'educationdetails[0].foreignWork.haveWorkedOutSideUSA',
      },
      {
        question:
          'Is applicant’s current year income from salary, wage, or self-employment?',
        answerPath:
          'educationdetails[0].foreignWork.isSalaryWageOrCurrentYearSelfEmployement',
      },
      {
        question:
          'What is applicant’s total earned amount for the current year?',
        answerPath:
          'educationdetails[0].foreignWork.totalEarnedAmountCurrentYear',
      },
      {
        question:
          'Is applicant’s current year earning shown in the Social Security statement?',
        answerPath:
          'educationdetails[0].foreignWork.earningShowInSocialSecutiryStatementCurrentYear',
      },
      {
        question:
          'Is applicant’s last year income from salary, wage, or self-employment?',
        answerPath:
          'educationdetails[0].foreignWork.isSalaryWageOrLastYearSelfEmployement',
      },
      {
        question: 'What is applicant’s total earned amount for last year?',
        answerPath: 'educationdetails[0].foreignWork.totalEarnedAmountLastYear',
      },
      {
        question:
          'Is applicant’s last year earning shown in the Social Security statement?',
        answerPath:
          'educationdetails[0].foreignWork.earningShowInSocialSecutiryStatementLastYear',
      },
      {
        question:
          'Is applicant eligible for benefits under a foreign social security system?',
        answerPath:
          'educationdetails[0].foreignWork.eligibleForBenefitsUnderForeignSocialSecuritySystem',
      },
      {
        question:
          'Were USA taxes taken out of applicant’s income for the current year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesTakenOutCurrentYear',
      },
      {
        question: 'In which months were USA taxes not taken out this year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesNotTakenOutCurrentYearMonths',
      },
      {
        question:
          'Were USA taxes taken out of applicant’s income for the past year?',
        answerPath: 'educationdetails[0].foreignWork.usaTaxesTakenOutPastYear',
      },
      {
        question: 'In which months were USA taxes not taken out last year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesNotTakenOutPastYearMonths',
      },
    ],
    workTaxDetail: [
      {
        question: 'Did applicant make payments for another year?',
        answerPath:
          'educationdetails[0].workTaxDetail.didPaymentPaidAnotherYear',
      },
      {
        question: 'Has applicant worked where taxes were not deducted?',
        answerPath:
          'educationdetails[0].workTaxDetail.haveWorkedWhereTaxesNotDeducted',
      },
      {
        question: 'Is applicant receiving a pension or annuity?',
        answerPath:
          'educationdetails[0].workTaxDetail.isReceivingPensionOrAnnuity',
      },
      {
        question:
          'Is applicant’s pension or annuity based on government employment?',
        answerPath:
          'educationdetails[0].workTaxDetail.isPensionOrAnnuityBasedOnGovernmentEmployment',
      },
      {
        question:
          'Does applicant expect to receive a pension or annuity in the future?',
        answerPath:
          'educationdetails[0].workTaxDetail.isExpectPensionOrAnnuityInFuture',
      },
      {
        question: 'Is applicant’s pension or annuity related to railroad work?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityRailroadWork',
      },
      {
        question:
          'Is applicant’s pension or annuity related to future government work?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityFutureGovtWork',
      },
      {
        question:
          'What is the expected start date for applicant’s future pension or annuity?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityFutureStartDate',
      },
      {
        question: 'Did applicant receive a lump sum with no taxes deducted?',
        answerPath: 'educationdetails[0].workTaxDetail.lumpSumNoTaxes',
      },
    ],
  },
  Medical_Details: {
    GeneralHealth: [
      {
        question: 'What is applicant’s date of birth?',
        answerPath: 'medicaldetails[0].generalHealth.dateOfBirth',
      },
      {
        question: 'What is applicant’s gender?',
        answerPath: 'medicaldetails[0].generalHealth.gender',
      },
      {
        question: 'How tall are you?',
        answerPath: 'medicaldetails[0].generalHealth.physique',
      },
      {
        question: 'How much does applicant weigh (lbs)?',
        answerPath: 'medicaldetails[0].generalHealth.physique.weight',
      },
      // {
      //   question: 'What is applicant’s body build?',
      //   answerPath: 'medicaldetails[0].generalHealth.physique.build',
      // },
    ],
    VisionAndHearing: [
      {
        question: 'Does applicant have 20/200 or less vision?',
        answerPath: 'medicaldetails[0].visionAndHearing.zeroOrLessVision2020',
      },
      {
        question: 'Please tell us whether applicant use any vision correctors.',
        answerPath: 'medicaldetails[0].visionAndHearing.visionCorrectorDetail',
      },
      {
        question:
          'Does applicant still experience impaired vision even with glasses or contacts?',
        answerPath: 'medicaldetails[0].visionAndHearing.isImpairedVision',
      },
      {
        question:
          'Tell us whether applicant experience any of the following vision-related symptoms.',
        answerPath: 'medicaldetails[0].visionAndHearing.visionRelatedSymptoms',
      },
      {
        question: 'Does applicant have any additional vision-related symptoms?',
        answerPath:
          'medicaldetails[0].visionAndHearing.additionalVisionRelatedSymptoms',
      },
      {
        question:
          'help us understand applicant’s hearing situation. Is applicant partially or totally deaf?',
        answerPath: 'medicaldetails[0].visionAndHearing.isPartialOrTotalDeaf',
      },
      {
        question:
          'Does applicant still experience impaired hearing, even with hearing aids?',
        answerPath: 'medicaldetails[0].visionAndHearing.isUsingHearingAids',
      },
      {
        question: 'Does applicant have impaired hearing?',
        answerPath: 'medicaldetails[0].visionAndHearing.isImpairedHearing',
      },
      {
        question: 'What are applicant’s hearing-related symptoms?',
        answerPath: 'medicaldetails[0].visionAndHearing.hearingSymptoms',
      },
      {
        question:
          'Does applicant have any additional hearing-related symptoms?',
        answerPath:
          'medicaldetails[0].visionAndHearing.additionalHearingRelatedSymptoms',
      },
      {
        question:
          'Please select one of applicant’s physical and mental illnesses, injuries, and conditions that limit applicant’s ability to work.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.limitedAbilityToWorkCondition',
      },
    ],
    IllnessesInjuriesAndConditions: [
      {
        question:
          "We'd like to understand if applicant is experiencing any muscle or mobility-related symptoms due to applicant’s illness, injury, or condition. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.muscleOrMemoryRelatedSymptoms',
      },
      {
        question:
          "We'd like to understand whether applicant experience any energy or sleep-related symptoms. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.energyOrSleepRelatedSymptoms',
      },
      {
        question:
          'help us understand if applicant experience any fever or skin-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.feverOrSkinRelatedSymptoms',
      },
      {
        question:
          "We'd like to understand if applicant experience any breathing or heart-related symptoms. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.breathingOrHeartRelatedSymptoms',
      },
      {
        question:
          'help us understand if applicant experience any speech-related symptoms due to applicant’s illness, injury, or condition. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.speechRelatedSymptoms',
      },
      {
        question:
          'How long do applicant’s longest speech-related symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.speechSymptomsDuration',
      },
      {
        question:
          'we do like to understand if applicant experience any memory-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.memoryRelatedSymptoms',
      },
      {
        question:
          'help us understand if applicant experience any mental health-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.mentalHealthRelatedSymptoms',
      },
      {
        question:
          'we do like to understand if applicant experience any urinary symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.urinarySymptoms',
      },
      {
        question:
          'What are applicant’s dizziness or headache related symptoms?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.dizzinessOrHeadacheRelatedSymptoms',
      },
      {
        question:
          'help us understand if applicant experience any dizziness or headache-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.isSeizureRelatedSymptoms',
      },
      {
        question:
          'Does applicant or has applicant previously experienced seizures?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureRelatedSymptoms',
      },
      {
        question: 'What symptoms does applicant experience during a seizure?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureSymptoms',
      },
      {
        question: 'Does applicant have any other symptoms?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.anotherSymptoms',
      },
      {
        question: 'How long do applicant’s symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.howLongSymptomLast',
      },
      {
        question: 'Does applicant experience after effects from seizures?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.isAfterEffectSeizureExperience',
      },
      {
        question: 'What are applicant’s seizure after effect experiences?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureAfterEffectExperience',
      },
      {
        question: 'Does applicant have any emotional or learning problems?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.emotionalOrLearningProblems',
      },
      {
        question:
          'Which of the following migraine or headache symptoms does applicant experience?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.headacheMigrainesSymptoms',
      },
      {
        question:
          'How long do applicant’s longest migraine-related symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.headacheMigrainesSymptomDuration',
      },
      {
        question: 'How long do applicant’s seizure after effect symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureAfterEffectSymptomsDuration',
      },
    ],
    MedicalProfessionals: [
      {
        question:
          'Has applicant seen a doctor or been treated for any mental illnesses, injuries, or conditions since applicant has  been unable to work? Mental illnesses or conditions refer to those that impact the brain.',
        answerPath: 'medicaldetails[0].medicalProfessionals.isMentalIllness',
      },
      // Array
      {
        iterable: 'mentalIllnessMedicalProfessionals',
        question:
          'Now, we need to gather information on all of the medical professionals who have evaluated, treated, ordered testing, or prescribed medication to applicant for applicant’s conditions.',
        answerPath:
          'medicaldetails[0].medicalProfessionals.mentalIllnessMedicalProfessionals',
      },
      {
        question:
          'Has applicant seen at least one doctor or been treated for any physical illnesses, injuries, or conditions since applicant has  been unable to work? Physical conditions, illnesses, or injuries refer to those that impact one or more body systems.',
        answerPath: 'medicaldetails[0].medicalProfessionals.isPhysicalIllness',
      },
      // Array
      {
        iterable: 'physicalIllnessMedicalProfessionals',
        question:
          'Now, we need to gather information on all of the medical professionals who have evaluated, treated, ordered testing, or prescribed medication to applicant for applicant’s conditions.',
        answerPath:
          'medicaldetails[0].medicalProfessionals.physicalIllnessMedicalProfessionals',
      },
    ],
    Hospital_Or_Medical_Facility_Care: [
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for an emergency room visit?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveEmergencyRoomVisit',
      },
      // Array
      {
        iterable: 'haveEmergencyRoomVisitMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveEmergencyRoomVisitMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any medical tests or medications ordered by this facility?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMedicalFacility',
      },
      // Array
      {
        iterable: 'haveMedicalFacilityMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMedicalFacilityMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any mental health treatment or evaluations?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMentalHeathTreatment',
      },
      // Array
      {
        iterable: 'haveMentalHeathTreatmentMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMentalHeathTreatmentMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any inpatient or overnight stays?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveInpatientOrOverNightStay',
      },
      // Array
      {
        iterable: 'haveInpatientOrOverNightStayMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveInpatientOrOverNightStayMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any outpatient or same-day visits?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveOutpatientOrSamedayVisit',
      },
      // Array
      {
        iterable: 'haveOutpatientOrSamedayVisitMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveOutpatientOrSamedayVisitMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any surgeries?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveSurgeries',
      },
      // Array
      {
        iterable: 'haveSurgeriesMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveSurgeriesMedicalFacility',
      },
      {
        question:
          'Has applicant seen a medical professional or been to a medical facility for any physical therapy?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.havePhysicalTherapy',
      },
      // Array
      {
        iterable: 'havePhysicalTherapyMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated applicant for applicant’s emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.havePhysicalTherapyMedicalFacility',
      },
      // org
      {
        question: 'Doctors in a prison or jail:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isDoctorInJail',
      },
      // Array
      {
        iterable: 'jailDoctorDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.jailDoctorDetail',
      },
      {
        question: 'Public welfare:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isPublicWelfare',
      },
      // Array
      {
        iterable: 'welfareDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.welfareDetail',
      },
      {
        question: 'Records held by an attorney/lawyer:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isRecordHeldByAttorney',
      },
      // Array
      {
        iterable: 'attorneyDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.attorneyDetail',
      },
      {
        question:
          'Short-term or long-term disability insurance (Excluding private health insurance):',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveDisabilityInsurance',
      },
      // Array
      {
        iterable: 'insuranceDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.insuranceDetail',
      },
      {
        question: 'Vocational rehabilitation services:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isVocationalRehabilitationService',
      },
      // Array
      {
        iterable: 'rehabilitationOrganizationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.rehabilitationOrganizationDetail',
      },
      {
        question: 'Workers compensation:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isWorkersCompensation',
      },
      // Array
      {
        iterable: 'workersCompensationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.workersCompensationDetail',
      },
      {
        question: 'Other, please explain (Excluding private health insurance):',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isOtherHaveMedicalRecords',
      },
      // Array
      {
        iterable: 'otherOrganizationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.otherOrganizationDetail',
      },
    ],
    Medical_Test: [
      {
        question:
          'Has applicant had any medical tests completed related to applicant’s illnesses, injuries, or conditions?',
        answerPath: 'medicaldetails[0].medicalTest.isMedicalTestCompleted',
      },
      // Array
      {
        iterable: 'medicalTest',
        question:
          'Please share the details related to this medical test and the medical professional who ordered it.',
        answerPath: 'medicaldetails[0].medicalTest.medicalTest',
      },
    ],
    Medicine: [
      {
        question:
          'Is applicant taking any medication (i.e., prescribed or over-the-counter) for applicant’s illnesses, injuries, or conditions?',
        answerPath: 'medicaldetails[0].medicine.isMedication',
      },
      // Array
      {
        iterable: 'medication',
        question:
          'Please share the details related to this medication and the medical professional who prescribed or recommended it.',
        answerPath: 'medicaldetails[0].medicine.medication',
      },
    ],
    AdditionalInformation: [
      {
        question: 'Additional Information',
        answerPath: 'medicaldetails[0].additionalInformation',
      },
    ],
  },
}

export default ApplicationData
