import { Avatar, Box, Grid, Popover, Typography } from '@mui/material'
import React, { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountSetting from 'src/Assets/Icons/AccountSetting.svg'
import Logout from 'src/Assets/Icons/Logout.svg'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import { LogoutRequest } from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { Icons, LegalPermissions } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import NotificationPopup from './Notification'
import './index.scss'

interface HeaderProps {
  showProfile: () => void
}

const Header: FC<HeaderProps> = ({ showProfile }) => {
  const anchor = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openBox = Boolean(anchorEl)
  const id = openBox ? 'simple-popover' : undefined

  const [showNotsification, setShowNotsification] = React.useState(false)
  const navigate = useNavigate()
  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const viewProfile = useAppSelector((state) => state.profile.data)
  const viewAuth = useAppSelector((state) => state.auth.data)
  const dispatch = useAppDispatch()

  const showNotification = () => {
    setShowNotsification(true)
  }

  const handleLogout = () => {
    // Remove token from local storage
    dispatch(LogoutRequest())
    // Navigate to sign-in page
    navigate('/')
  }

  const getHelp = () => {
    window.open('mailto:support@benefitsboost.com', '_blank')
  }

  return (
    <>
      <Box className='header-container' alignItems='center' display='flex'>
        <Box
          display='flex'
          alignItems='center'
          className='pointer sub-header-container'
          onClick={() => {
            navigate(ROUTES.DASHBOARD)
          }}
        >
          <img src={Icons.headerLogo} alt='header' />
        </Box>
        <Box alignItems='center' display='flex'>
          {UserPermission.includes(LegalPermissions.NOTIFICATION) && (
            <Box
              onClick={() => {
                if (process.env.REACT_APP_MILESTONE_4) {
                  showNotification()
                }
              }}
              ref={anchor}
              className='pointer'
            >
              <img
                className='img-m-10 img-hw-24'
                src={Icons.notification}
                alt='notification'
              />
            </Box>
          )}

          <img
            onClick={getHelp}
            className='img-m-10 img-hw-24 mr-25'
            src={Icons.help}
            alt='help'
          />
          <button
            onClick={handleClickPopover}
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
              padding: 0,
              font: 'inherit',
              cursor: 'pointer',
              outline: 'inherit',
            }}
          >
            <Box
              display='flex'
              alignItems='center'
              className='d-flex align-items-center img-box'
            >
              <Avatar
                className='img-style-1'
                src={
                  viewProfile?.profileUrl ||
                  viewAuth?.profileUrl ||
                  ProfilePhoto
                }
                alt='profileUrl'
              />
              <Box className='center msg-box' display={'flex'}>
                <Typography variant='body1' className='text-cmn-3'>
                  Hey,&nbsp;
                </Typography>
                <Typography variant='body1' className='SfUi-semiBold'>
                  {viewProfile?.firstName || viewAuth?.firstName}
                </Typography>
              </Box>

              <img
                // className='img-style-2'
                className={openBox ? 'imgStyleRotate' : 'img-style-2'}
                src={Icons.downSelect}
                alt='select'
              />
            </Box>
          </button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={openBox}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: '15px' }}
      >
        <Box className='profileBox' gap={2}>
          <Grid py={2} sx={{ cursor: 'pointer' }}>
            <Typography
              onClick={() => {
                navigate(ROUTES.PROFILE)
                handleClose()
              }}
              className='SfUi-semi'
              display={'flex'}
              alignItems={'center'}
            >
              <img
                style={{
                  padding: '5px',
                  marginRight: '5px',
                }}
                src={AccountSetting}
                alt='select'
              />
              Account Setting
            </Typography>
          </Grid>
          <Grid py={2} sx={{ cursor: 'pointer' }}>
            <Typography
              className='SfUi-semi-red'
              display={'flex'}
              alignItems={'center'}
              onClick={() => {
                handleLogout()
                handleClose()
              }}
            >
              <img
                style={{
                  padding: '5px',
                  marginRight: '5px',
                  color: 'red',
                }}
                src={Logout}
                alt='select'
              />
              Logout
            </Typography>
          </Grid>
        </Box>
      </Popover>
      <NotificationPopup
        open={showNotsification}
        onClose={() => {
          setShowNotsification(false)
        }}
        anchor={anchor}
      />
    </>
  )
}

export default Header
