/* eslint-disable no-irregular-whitespace */
import {
  DialogTitle,
  Dialog,
  Box,
  Typography,
  DialogContent,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import star from 'src/Assets/Icons/star.svg'
import { useAppDispatch } from 'src/Redux/hooks'
import { setIsDisqualified } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
const Disqualification = ({
  open,
  setOpen,
  handleOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const handleClose = () => {
    setOpen(false)
    dispatch(setIsDisqualified(false))
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Disqualification
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='Cancel'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography className={Style.textWrapper}>
              Based on this answer, the applicant is not eligible for SSDI or
              SSI disability benefits.
            </Typography>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'flex-start'}
              sx={{
                p: 2,
                mb: 4,
                mt: 1,
                backgroundColor: '#ECF6FF',
                borderRadius: '10px',
                gap: '10px',
              }}
            >
              <img src={star} className={Style.star} alt='' />
              <Typography>
                <i>
                  We encourage you to speak with one of our BenefitsBoost
                  <span style={{ fontSize: '20px' }}>®</span> disability
                  experts to ensure the applicant is not eligible.
                </i>
              </Typography>
            </Box>

            <Box className={Style.DialogContentBox}>
              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  Phone: 1-720-515-1705 <br />
                  Email: support@benefitsboost.com
                  <br />
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default Disqualification
