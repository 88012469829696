import {
  type FunctionComponent,
  useMemo,
  type CSSProperties,
  useState,
} from 'react'
import styles from '../Frame2/index.module.scss'
import MailIcon from 'src/Assets/Icons/mail.svg'
import PhoneIcon from 'src/Assets/Icons/phoneIcon.svg'
import LocationIcon from 'src/Assets/Icons/LocationIcon.svg'
import employee from 'src/Assets/Images/employee.svg'
import Time from 'src/Assets/Icons/Time.svg'
import AssignEmpPopup from 'src/Components/AssignEmp'

import {
  Box,
  Avatar,
  Button,
  Grid,
  Paper,
  Typography,
  type LinearProgressProps,
  LinearProgress,
  styled,
} from '@mui/material'
import stylesNew from './index.module.scss'
import { ROUTES } from 'src/Utils/Routes'
import { useNavigate } from 'react-router-dom'
import { DynamicObj, MileStoneFour } from 'src/Utils/Constants'
import { setApplicationId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { useAppDispatch } from 'src/Redux/hooks'
import { TAB_DATA } from '../../allTabsData'
import { setActiveStepTab } from 'src/Redux/Slices/stepSlice'

export interface SeparatorFrameType {
  jessicaMarieJohnson?: string
  completionVal?: string
  status?: string

  /** Style props */
  propTextTransform?: CSSProperties['textTransform']
  propWidth?: CSSProperties['width']
  propPadding?: CSSProperties['padding']
  propWidth1?: CSSProperties['width']
  propFlex?: CSSProperties['flex']
  propPadding1?: CSSProperties['padding']
  propWidth2?: CSSProperties['width']
  data?: DynamicObj
}

const SeparatorFrame: FunctionComponent<SeparatorFrameType> = ({
  completionVal,
  status,
  propWidth,
  propPadding,
  propPadding1,
  propWidth2,
  data,
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpen = () => {
    setOpenDialog(true)
  }

  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    }
  }, [propWidth])

  const barStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
    }
  }, [propPadding])

  const rightStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding1,
      width: propWidth2,
    }
  }, [propPadding1, propWidth2])

  const CustomLinearProgress = styled(LinearProgress)(() => ({
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#7752FE', // Change the background color as needed
    },
  }))
  const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number },
  ) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <CustomLinearProgress variant='determinate' {...props} />
        </Box>
      </Box>
    )
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  function renderContactInfoPart(
    part: string | null,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }

  function renderAddress(address: DynamicObj) {
    const { streetAddress, city, state, country, postalCode } = address

    const formattedAddress = `${renderContactInfoPart(streetAddress, ',')}${renderContactInfoPart(city, ',')}${renderContactInfoPart(state, ',')}${renderContactInfoPart(country, ',')}${renderContactInfoPart(postalCode)}`

    return formattedAddress
  }

  type AnyObject = Record<string, any>

  const hasValues = (arr: AnyObject[]): boolean => {
    for (const obj of arr) {
      for (const key in obj) {
        if (
          obj[key] !== null &&
          obj[key] !== undefined &&
          obj[key] !== '' &&
          obj[key] !== 0
        ) {
          return true
        }
      }
    }
    return false
  }

  return (
    <>
      {openDialog && (
        <AssignEmpPopup
          open={openDialog}
          setOpen={setOpenDialog}
          handleOpen={handleOpen}
          data={data}
        />
      )}
      {data && (
        <Grid lg={11} m={1} sx={{ cursor: 'pointer' }} width={'100%'}>
          <Paper className={styles.separatorFrame} sx={{ boxShadow: 0 }}>
            <Grid
              onClick={() => {
                if (process.env.REACT_APP_MILESTONE_4) {
                  dispatch(setApplicationId(data.uuid))
                  dispatch(setActiveStepTab(TAB_DATA.BASIC))
                  navigate(ROUTES.APPLICATION_DETAIL)
                }
              }}
            >
              <Grid className={styles.frameParent} lg={11} pb={2}>
                <Grid
                  container
                  className={styles.frameGroup}
                  onClick={() => {
                    dispatch(setApplicationId(data.uuid))
                    navigate(ROUTES.APPLICATION_DETAIL)
                  }}
                >
                  <Grid item lg={6}>
                    {' '}
                    <Box className={styles.samanthaLynnDavisWrapper}>
                      <Typography
                        variant='h1'
                        className={styles.samanthaLynnDavis}
                      >
                        {renderContactInfo(data)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6}>
                    {data?.socialSecurityNumber && (
                      <Box className={styles.samanthaLynnDavisWrapper}>
                        <Typography variant='h1' className={styles.ssnText}>
                          SSN : {data?.socialSecurityNumber}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={styles.infoWrapper}>
                <Grid lg={12} className={styles.info}>
                  {data?.address && (
                    <Grid lg={12} className={styles.location}>
                      <img
                        className={styles.iconlocation}
                        loading='eager'
                        alt=''
                        src={LocationIcon}
                      />
                      <Typography variant='body2' className={styles.aAvenueJf}>
                        {renderAddress(data?.address)}
                      </Typography>
                    </Grid>
                  )}

                  {data?.phoneNumber && (
                    <Grid lg={12} className={styles.contact}>
                      <div className={styles.phone}>
                        <img
                          className={styles.phoneIcon}
                          loading='eager'
                          alt=''
                          src={PhoneIcon}
                        />
                        <Typography variant='body2' className={styles.div1}>
                          {data?.phoneNumber}
                        </Typography>
                      </div>
                    </Grid>
                  )}

                  {data?.emailId && (
                    <Grid lg={12} className={styles.contact}>
                      <div className={styles.email}>
                        <img
                          className={styles.mail01Icon}
                          loading='eager'
                          alt=''
                          src={MailIcon}
                        />
                        <Typography
                          variant='body2'
                          className={styles.ellyseperrygmailcom}
                        >
                          {data?.emailId}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Box className={styles.wrapper}>
              <div className={styles.div} />
            </Box>
            <Grid lg={12} className={stylesNew.separatorFrameInner}>
              {hasValues(data?.assigneeData) ? (
                <>
                  <Grid container className={stylesNew.userParent}>
                    <Box>
                      <Avatar
                        sx={{ height: '50px', width: '50px' }}
                        src={data?.assigneeData?.[0]?.profileURL}
                        alt='photoURL'
                      />
                    </Box>
                    {completionVal && (
                      <Grid lg={9} md={12} className={stylesNew.titleParent}>
                        <Grid className={stylesNew.title} mt={0.5}>
                          <div className={stylesNew.applicationCompletion}>
                            Application Completion
                          </div>
                          <div
                            className={stylesNew.wrapper}
                            style={frameDivStyle}
                          >
                            <div className={stylesNew.div}>
                              {`${parseInt(completionVal)}%`}
                            </div>
                          </div>
                        </Grid>
                        <Grid className={stylesNew.bar} style={barStyle}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel
                              value={parseInt(completionVal)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    <Box className={stylesNew.right} style={rightStyle}>
                      <img src={Time} />
                      <div className={styles.inProgress}>
                        {status?.replace(/([a-z])([A-Z])/g, '$1 $2')}
                      </div>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Button
                  className={styles.progress}
                  disableElevation={true}
                  variant='contained'
                  sx={{
                    textTransform: 'none',
                    color: '#fff',
                    fontSize: '14',
                    background: '#7752FE',
                    borderRadius: '8px',
                    height: '36px',
                    marginTop: '0px',
                    '&:hover': { background: '#7752FE' },
                  }}
                  onClick={() => {
                    if (process.env.REACT_APP_MILESTONE_4) {
                      setOpenDialog(true)
                    }
                  }}
                >
                  Assign
                </Button>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  )
}

export default SeparatorFrame
