/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { CommonMedicalTable, CommonOrgTable } from './commonTable'

interface ContactInfo {
  question: string
  answer: any
}
interface Address {
  city: string
  state: string
  country: string
  postalCode: string
  streetAddress: string
}
const MedicalDetailsTable: React.FC = ({ question }: { question: any }) => {
  // combines address object into a string
  const combineAddress = (address: Address): string => {
    return `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode}, ${address.country}`
  }

  const medicalFacilityIterables = [
    'haveEmergencyRoomVisitMedicalFacility',
    'haveMedicalFacilityMedicalFacility',
    'haveMentalHeathTreatmentMedicalFacility',
    'haveInpatientOrOverNightStayMedicalFacility',
    'haveOutpatientOrSamedayVisitMedicalFacility',
    'haveSurgeriesMedicalFacility',
    'havePhysicalTherapyMedicalFacility',
  ]
  const organizationIterables = [
    'jailDoctorDetail',
    'welfareDetail',
    'attorneyDetail',
    'insuranceDetail',
    'rehabilitationOrganizationDetail',
    'workersCompensationDetail',
    'otherOrganizationDetail',
  ]

  // Function to render military service information or any iterable answer in tabular format
  const renderIterableAnswer = (answer: any[], iterable: string) => {
    if (iterable === 'mentalIllnessMedicalProfessionals') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each organization's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Medical Professionals Detail {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each organization */}
                  <TableRow>
                    <TableCell width={0}>Doctor Name</TableCell>
                    <TableCell>
                      {service.suffix +
                        ' ' +
                        service.doctorFirstName +
                        ' ' +
                        service.doctorLastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Doctar's Medical Speciality</TableCell>
                    <TableCell>{service.doctorMedicalSpecialty}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Clinic Name or Office Name</TableCell>
                    <TableCell>{service.officeOrClinicName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell width={0} style={{ wordWrap: 'break-word' }}>
                      {combineAddress(service.address)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Phone Number</TableCell>
                    <TableCell>{service.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>First Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.firstAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Last Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.lastAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Next Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.nextAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Treatment Type</TableCell>
                    <TableCell>
                      {service.treatmentType === ''
                        ? 'N/A'
                        : service.treatmentType}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Other Treatment</TableCell>
                    <TableCell>
                      {service.otherTreatment === ''
                        ? 'N/A'
                        : service.otherTreatment}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>
                      Type of Therapy Doctor Provided
                    </TableCell>
                    <TableCell>
                      {service.typeOfTherapyDoctorProvided.length === 0
                        ? 'N/A'
                        : service.typeOfTherapyDoctorProvided}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other Type of Therapy Details</TableCell>
                    <TableCell>
                      {service.otherTypeOfTherapyDetails === ''
                        ? 'N/A'
                        : service.otherTypeOfTherapyDetails}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Special Procedure Detail</TableCell>
                    <TableCell>
                      {service.specialProcedureDetail === ''
                        ? 'N/A'
                        : service.specialProcedureDetail}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (iterable === 'physicalIllnessMedicalProfessionals') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each organization's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Medical Professionals Detail {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each organization */}
                  <TableRow>
                    <TableCell width={0}>Doctor Name</TableCell>
                    <TableCell>
                      {service.suffix +
                        ' ' +
                        service.doctorFirstName +
                        ' ' +
                        service.doctorLastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Doctar's Medical Speciality</TableCell>
                    <TableCell>{service.doctorMedicalSpecialty}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Clinic Name or Office Name</TableCell>
                    <TableCell>{service.officeOrClinicName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell width={0} style={{ wordWrap: 'break-word' }}>
                      {combineAddress(service.address)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Phone Number</TableCell>
                    <TableCell>{service.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>First Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.firstAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Last Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.lastAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Next Appoinment Date</TableCell>
                    <TableCell>
                      {formatDate(service.nextAppointmentDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Treatment Type</TableCell>
                    <TableCell>
                      {service.treatmentType === ''
                        ? 'N/A'
                        : service.treatmentType}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Other Treatment</TableCell>
                    <TableCell>
                      {service.otherTreatment === ''
                        ? 'N/A'
                        : service.otherTreatment}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>
                      Type of Therapy Doctor Provided
                    </TableCell>
                    <TableCell>
                      {service.typeOfTherapyDoctorProvided.length === 0
                        ? 'N/A'
                        : service.typeOfTherapyDoctorProvided}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other Type of Therapy Details</TableCell>
                    <TableCell>
                      {service.otherTypeOfTherapyDetails === ''
                        ? 'N/A'
                        : service.otherTypeOfTherapyDetails}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Special Procedure Detail</TableCell>
                    <TableCell>
                      {service.specialProcedureDetail === ''
                        ? 'N/A'
                        : service.specialProcedureDetail}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (medicalFacilityIterables.includes(iterable)) {
      return CommonMedicalTable(answer, 'Medical Details')
    }

    if (organizationIterables.includes(iterable)) {
      return CommonOrgTable(answer, 'Organization Details')
    }

    if (iterable === 'medication') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each organization's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Medicine Detail {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each organization */}
                  <TableRow>
                    <TableCell width={0}>Doctor Name</TableCell>
                    <TableCell>
                      {service.suffix +
                        ' ' +
                        service.doctorFirstName +
                        ' ' +
                        service.doctorLastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Medication Name</TableCell>
                    <TableCell>{service.medicationName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Side Effects</TableCell>
                    <TableCell>{service.sideEffects}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Medication Reason</TableCell>
                    <TableCell>{service.medicationReason}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (iterable === 'medicalTest') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((service, index) => (
                <React.Fragment key={index}>
                  {/* Title row for each organization's details */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          backgroundColor: '#f5f5f5',
                          padding: '10px',
                        }}
                      >
                        Medicine Detail {index + 1}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Data rows for each organization */}
                  <TableRow>
                    <TableCell width={0}>Doctor Name</TableCell>
                    <TableCell>
                      {service.suffix +
                        ' ' +
                        service.doctorFirstName +
                        ' ' +
                        service.doctorLastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Medical Test Name</TableCell>
                    <TableCell>{service.medicalTestName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Tested Body Part</TableCell>
                    <TableCell>{service.testedBodyPart}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>
                      Most Recent Date Test Performed
                    </TableCell>
                    <TableCell>
                      {formatDate(service.mostRecentDateTestPerformed)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={0}>Future Date Test Perform</TableCell>
                    <TableCell>
                      {formatDate(service.futureDateTestPerform)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    // Add logic here for future iterable arrays if needed
    return null // Return null if no matching iterable
  }

  // Helper function to format address object into a string
  const formatAddress = (address: any) => {
    const { streetAddress, city, state, postalCode, country } = address
    return `${streetAddress}, ${city}, ${state}, ${postalCode}, ${country}`
  }
  // Common function to format dates from 'YYYY-MM-DD' to 'DD MMMM YYYY'
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0') // Ensure 2 digits for day
    const month = date.toLocaleString('default', { month: 'long' }) // Get full month name
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
  }

  const renderAnswer = (item: any) => {
    const { answer, iterable } = item
    if (typeof answer === 'boolean') {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === true}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>Yes</Typography>}
            style={{ color: 'black' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={answer === false}
                disabled
                style={{ color: 'black' }}
              />
            }
            label={<Typography style={{ color: 'black' }}>No</Typography>}
            style={{ color: 'black' }}
          />
        </FormGroup>
      )
    }

    if (Array.isArray(answer) && answer.length === 0) {
      return (
        <Typography fontSize={'17px'} fontWeight={500}>
          N/A
        </Typography>
      )
    }

    if (typeof answer === 'object' && answer.feet && answer.inch) {
      return `${answer.feet}' ${answer.inch}"`
    }

    if (Array.isArray(answer) && typeof answer[0] === 'string') {
      return (
        <TableContainer>
          <Table>
            <TableBody>
              {answer.map((item, index) => (
                <TableRow key={index}>
                  {/* <TableCell>{index + 1}</TableCell> */}
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    if (Array.isArray(answer) && iterable) {
      // Handle iterable answers (like MilitaryServiceInformation)
      return renderIterableAnswer(answer, iterable)
    }

    // Check if the answer is a date string in the format YYYY-MM-DD
    const datePattern = /^\d{4}-\d{2}-\d{2}$/
    if (typeof answer === 'string' && datePattern.test(answer)) {
      // Use the common formatDate function to format the date
      return formatDate(answer)
    }

    if (
      typeof answer === 'object' &&
      answer?.streetAddress &&
      answer?.city &&
      answer?.state &&
      answer?.postalCode &&
      answer?.country
    ) {
      // If it's an address object, format it into a string
      return formatAddress(answer)
    }

    if (typeof answer === 'object') {
      return JSON.stringify(answer, null, 2)
    }

    return answer
  }

  const renderTable = (info: ContactInfo[]) => (
    <>
      <div style={{ overflowX: 'auto' }}>
        <table border={1} style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            {info.map((item, index) => (
              <tr key={index}>
                <td style={{ width: '30%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px' }}>
                    {item.question || item.iterable}
                  </span>
                </td>
                <td style={{ width: '70%', padding: '10px 12px' }}>
                  <span style={{ fontSize: '18px', fontWeight: 500 }}>
                    {renderAnswer(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    // <Paper elevation={3} style={{ padding: '16px' }}>
    <Box p={'16px'}>
      <Box mb={4}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <Typography
            fontSize={'22px'}
            textAlign={'center'}
            fontWeight={'bold'}
            gutterBottom
          >
            Medical Details
          </Typography>
        </Grid>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            General Health
          </Typography>
        </Box>
        {renderTable(question?.GeneralHealth)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Vision And Hearing
          </Typography>
        </Box>
        {renderTable(question?.VisionAndHearing)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Illnesses, Injuries, and Conditions
          </Typography>
        </Box>
        {renderTable(question?.IllnessesInjuriesAndConditions)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Medical Professionals
          </Typography>
        </Box>
        {renderTable(question?.MedicalProfessionals)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Hospital or Medical Facility Care
          </Typography>
        </Box>
        {renderTable(question?.Hospital_Or_Medical_Facility_Care)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Medical Test
          </Typography>
        </Box>
        {renderTable(question?.Medical_Test)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Medicine
          </Typography>
        </Box>
        {renderTable(question?.Medicine)}
      </Box>
      <Box mb={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            justifyContent: 'space-between',
            padding: '10px',
            backgroundColor: '#ebeae8',
            border: '1px solid black',
          }}
        >
          <Typography
            variant='h6'
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Additional Information
          </Typography>
        </Box>
        {renderTable(question?.AdditionalInformation)}
      </Box>
    </Box>
    // </Paper>
  )
}

export default MedicalDetailsTable
