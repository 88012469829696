import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
// import { useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import remove from 'src/Assets/Icons/remove.svg'

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

interface benefitAppliedSSNDetail {
  suffix: string
  firstName: string
  middleName: string
  lastName: string
  monthlyBenefitAmount: string
  socialSecurityNumber: string
}

const ProfileBox = ({
  formValues,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
}: {
  formValues: benefitAppliedSSNDetail
  setFormValues: React.Dispatch<React.SetStateAction<benefitAppliedSSNDetail>>
  changeHandler: (data: { name: string; value: string | unknown }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
}) => {
  // const [fullValue, setFullValue] = useState(['', '', ''])

  // useEffect(() => {
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     benefitAppliedSSNDetail: prevState.benefitAppliedSSNDetail.map(
  //       (detail, i: number) => {
  //         if (i === index)
  //           return {
  //             ...detail,
  //             socialSecurityNumber: fullValue.join(''),
  //           }
  //         return detail
  //       },
  //     ),
  //   }))
  // }, [fullValue])

  // useEffect(() => {
  //   setFullValue([
  //     formValues?.socialSecurityNumber?.slice(0, 3),
  //     formValues?.socialSecurityNumber?.slice(3, 5),
  //     formValues?.socialSecurityNumber?.slice(5),
  //   ])
  // }, [formValues])

  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>
            Social Security Number for Benefits {index + 1}
          </Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Suffix<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <StyledSelect
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              className={Style.AnsTxt}
              IconComponent={(props) => (
                <IconButton {...props}>
                  <img src={IconChevronDown} alt='IconChevronDown' />
                </IconButton>
              )}
              displayEmpty
              renderValue={
                formValues?.suffix !== ''
                  ? () => formValues?.suffix
                  : () => 'Select'
              }
              value={formValues?.suffix}
              fullWidth
              id='suffix'
              name='suffix'
              error={!!errors?.suffix}
            >
              <MenuItem value=''>
                <em>Select an Answer</em>
              </MenuItem>
              <MenuItem value='Dr.'>Dr.</MenuItem>
              <MenuItem value='Ms.'>Ms.</MenuItem>
              <MenuItem value='Mrs.'>Mrs.</MenuItem>
              <MenuItem value='Mr.'>Mr.</MenuItem>
            </StyledSelect>
            <FormHelperText sx={{ mx: 1.5 }}>{errors?.suffix}</FormHelperText>
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              First Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='firstName'
              name='firstName'
              placeholder='Enter First Name'
              className={Style.AnsTxt}
              value={formValues?.firstName}
              // onChange={(e) => {
              //   changeHandler({ name: e.target.name, value: e.target.value })
              // }}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.firstName}
              helperText={errors?.firstName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Middle Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='middleName'
              name='middleName'
              placeholder='Enter Middle Name'
              className={Style.AnsTxt}
              value={formValues?.middleName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.middleName}
              helperText={errors?.middleName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Last Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='lastName'
              name='lastName'
              placeholder='Enter Last Name'
              className={Style.AnsTxt}
              value={formValues?.lastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.lastName}
              helperText={errors?.lastName}
            />
          </Grid>
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Social Security Number (SSN)
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Grid gap={1} display={'flex'} flexDirection={'row'}>
              <TextField
                fullWidth
                id={`part1-${index}`}
                name='part1'
                placeholder='###'
                className={Style.AnsTxt}
                value={formValues?.socialSecurityNumber.slice(0, 3)}
                onChange={(e) => {
                  if (e.target.value.match(/^\d*$/)) {
                    // setFullValue([e.target.value, fullValue[1], fullValue[2]])
                    setFormValues((prevState) => ({
                      ...prevState,
                      benefitAppliedSSNDetail:
                        prevState.benefitAppliedSSNDetail.map(
                          (detail, i: number) => {
                            if (i === index)
                              return {
                                ...detail,
                                socialSecurityNumber: `${
                                  e.target.value
                                }${formValues?.socialSecurityNumber.slice(
                                  3,
                                  5,
                                )}${formValues?.socialSecurityNumber.slice(5)}`,
                              }
                            return detail
                          },
                        ),
                    }))
                  }
                }}
                inputProps={{ maxLength: 3 }}
              />
              <TextField
                fullWidth
                id={`part2-${index}`}
                name='part2'
                placeholder='##'
                className={Style.AnsTxt}
                value={formValues?.socialSecurityNumber.slice(3, 5)}
                onChange={(e) => {
                  if (e.target.value.match(/^\d*$/)) {
                    // setFullValue([fullValue[0], e.target.value, fullValue[2]])
                    setFormValues((prevState) => ({
                      ...prevState,
                      benefitAppliedSSNDetail:
                        prevState.benefitAppliedSSNDetail.map(
                          (detail, i: number) => {
                            if (i === index)
                              return {
                                ...detail,
                                socialSecurityNumber: `${formValues?.socialSecurityNumber.slice(
                                  0,
                                  3,
                                )}${e.target.value}${formValues?.socialSecurityNumber.slice(
                                  5,
                                )}`,
                              }
                            return detail
                          },
                        ),
                    }))
                  }
                }}
                inputProps={{ maxLength: 2 }}
              />
              <TextField
                fullWidth
                id={`part3-${index}`}
                name='part3'
                placeholder='####'
                className={Style.AnsTxt}
                value={formValues?.socialSecurityNumber.slice(5, 9)}
                onChange={(e) => {
                  if (e.target.value.match(/^\d*$/)) {
                    // setFullValue([fullValue[0], fullValue[1], e.target.value])
                    setFormValues((prevState) => ({
                      ...prevState,
                      benefitAppliedSSNDetail:
                        prevState.benefitAppliedSSNDetail.map(
                          (detail, i: number) => {
                            if (i === index)
                              return {
                                ...detail,
                                socialSecurityNumber: `${formValues?.socialSecurityNumber.slice(
                                  0,
                                  3,
                                )}${formValues?.socialSecurityNumber.slice(
                                  3,
                                  5,
                                )}${e.target.value}`,
                              }
                            return detail
                          },
                        ),
                    }))
                  }
                }}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.socialSecurityNumber}
            </FormHelperText>
          </Grid>

          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Monthly Benefit Amount
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              id='monthlyBenefitAmount'
              name='monthlyBenefitAmount'
              placeholder='Enter Monthly Benefit Amount'
              className={Style.AnsTxt}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              value={formValues?.monthlyBenefitAmount}
              onChange={(e) => {
                if (e.target.value.match(/^\d*$/)) {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }
              }}
              inputProps={{ maxLength: 12 }}
              error={!!errors?.monthlyBenefitAmount}
              helperText={errors?.monthlyBenefitAmount}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default ProfileBox
