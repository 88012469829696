import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  CommonDropdown,
  Name,
  StarText,
} from 'src/Components/Fields'
import { ParentType } from 'src/Redux/Services/Types/applicationType'
import { AddParents } from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { setParent } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from '../index.module.scss'
import Address from './Address'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const Perants = ({
  handleBack,
  chatForm,
}: {
  chatForm: any
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.Parent,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const [formValues, setFormValues] = useState<ParentType>({
    familyId,
    isParentReceiveOneHalfSupport: undefined,
    firstParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    isSecondParentReceiveOneHalfSupport: undefined,
    secondParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
  })

  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: detailId,
          data: {
            ...formValues,
            isParentReceiveOneHalfSupport:
              formValues?.isParentReceiveOneHalfSupport === undefined
                ? 'Boolean'
                : formValues?.isParentReceiveOneHalfSupport,
            isSecondParentReceiveOneHalfSupport:
              formValues?.isSecondParentReceiveOneHalfSupport === undefined
                ? 'Boolean'
                : formValues?.isSecondParentReceiveOneHalfSupport,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    setFormValues({ ...storeValues, familyId })
  }, [])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFirstParentChange = (data: {
    name: string
    value: string | number
  }) => {
    setErrors({})
    const { name, value } = data
    setFormValues({
      ...formValues,
      firstParent: { ...formValues.firstParent, [name]: value },
    })
  }
  const handleFirstAddressChange = (event: { name: string; value: string }) => {
    setErrors({})
    const { name, value } = event
    setFormValues({
      ...formValues,
      firstParent: {
        ...formValues.firstParent,
        address: { ...formValues.firstParent?.address, [name]: value },
      },
    })
  }
  const handleSecondParentChange = (data: {
    name: string
    value: string | number
  }) => {
    setErrors({})
    const { name, value } = data
    setFormValues({
      ...formValues,
      secondParent: { ...formValues.secondParent, [name]: value },
    })
  }
  const handleSecondAddressChange = (event: {
    name: string
    value: string
  }) => {
    setErrors({})
    const { name, value } = event
    setFormValues({
      ...formValues,
      secondParent: {
        ...formValues.secondParent,
        address: { ...formValues.secondParent?.address, [name]: value },
      },
    })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isParentReceiveOneHalfSupport === undefined) {
      newErrors.isParentReceiveOneHalfSupport = 'Please select an option'
      valid = false
    }
    if (formValues?.isSecondParentReceiveOneHalfSupport === undefined) {
      newErrors.isSecondParentReceiveOneHalfSupport = 'Please select an option'
      valid = false
    }
    if (formValues?.isParentReceiveOneHalfSupport) {
      if (!formValues?.firstParent?.suffix) {
        newErrors.suffix = 'Suffix is required'
        valid = false
      }
      if (!formValues?.firstParent?.firstName) {
        newErrors.firstName = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.firstName)) {
        newErrors.firstName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.lastName) {
        newErrors.lastName = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.lastName)) {
        newErrors.lastName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.middleName) {
        newErrors.middleName = 'Middle Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.middleName)) {
        newErrors.middleName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.address?.streetAddress) {
        newErrors.streetAddress = 'Street Address is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.city) {
        newErrors.city = 'City is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.state) {
        newErrors.state = 'State is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.postalCode) {
        newErrors.postalCode = 'Zip Code is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.country) {
        newErrors.country = 'Country is required'
        valid = false
      }
    }

    if (formValues?.isSecondParentReceiveOneHalfSupport) {
      if (!formValues?.secondParent?.suffix) {
        newErrors.suffix2 = 'Suffix is required'
        valid = false
      }
      if (!formValues?.secondParent?.firstName) {
        newErrors.firstName2 = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.firstName)) {
        newErrors.firstName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.lastName) {
        newErrors.lastName2 = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.lastName)) {
        newErrors.lastName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.middleName) {
        newErrors.middleName2 = 'Middle Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.middleName)) {
        newErrors.middleName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.address?.streetAddress) {
        newErrors.streetAddress2 = 'Street Address is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.city) {
        newErrors.city2 = 'City is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.state) {
        newErrors.state2 = 'State is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.postalCode) {
        newErrors.postalCode2 = 'Zip Code is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.country) {
        newErrors.country2 = 'Country is required'
        valid = false
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.familyId = familyId
      formValuesToSubmit.isParentReceiveOneHalfSupport =
        formValues.isParentReceiveOneHalfSupport
      formValuesToSubmit.isSecondParentReceiveOneHalfSupport =
        formValues.isSecondParentReceiveOneHalfSupport
      if (formValues.isParentReceiveOneHalfSupport) {
        formValuesToSubmit.firstParent = formValues.firstParent
      }
      if (formValues.isSecondParentReceiveOneHalfSupport) {
        formValuesToSubmit.secondParent = formValues.secondParent
      }
      dispatch(setParent(formValues))
      dispatch(AddParents(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            navigate(ROUTES.LEGAL_DETAILS)
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading="Now, let's look at some questions related to perent." />

          <CommonRadioBox
            title='Do you has a parent who receives one-half support from you?'
            commonState={formValues?.isParentReceiveOneHalfSupport}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isParentReceiveOneHalfSupport: value,
              })
            }}
            YesTitle='I have a parent who receives one-half support from me'
            NoTitle='I do not has a parent who receives one-half support from me'
            error={errors?.isParentReceiveOneHalfSupport}
          />
          {formValues?.isParentReceiveOneHalfSupport && (
            <>
              <StarText
                text="Thanks for sharing. Let's gather some details about this
                    parent. If two parents are supported, please provide
                    information about one of them. You'll have the opportunity
                    to add the other in a moment."
              />

              <Box>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <CommonDropdown
                    name='suffix'
                    value={formValues?.firstParent?.suffix}
                    handleChange={({ name, value }) => {
                      setFormValues({
                        ...formValues,
                        firstParent: {
                          ...formValues?.firstParent,
                          [name]: value,
                        },
                      })
                    }}
                    menulist={[
                      { name: 'Dr.', value: 'Dr.' },
                      { name: 'Ms.', value: 'Ms.' },
                      { name: 'Mrs.', value: 'Mrs.' },
                      { name: 'Mr.', value: 'Mr.' },
                    ]}
                    label='Suffix'
                    error={errors?.suffix}
                  />
                  <Name
                    name='firstName'
                    value={formValues?.firstParent?.firstName}
                    title='First Name'
                    handleChange={handleFirstParentChange}
                    error={errors.firstName}
                  />
                  <Name
                    name='middleName'
                    value={formValues?.firstParent?.middleName}
                    title='Middle Name'
                    handleChange={handleFirstParentChange}
                    error={errors.middleName}
                  />
                  <Name
                    name='lastName'
                    value={formValues?.firstParent?.lastName}
                    title='Last Name'
                    handleChange={handleFirstParentChange}
                    error={errors.lastName}
                  />
                </Grid>

                <Address
                  errors={errors}
                  realValues={formValues}
                  formValues={formValues?.firstParent}
                  handleFieldChange={(data) => {
                    handleFirstAddressChange(data)
                  }}
                  whichParent='firstParent'
                  setFormValues={setFormValues}
                />
              </Box>
            </>
          )}

          <CommonRadioBox
            title='Do you have a second parent who receives one-half support from you?'
            commonState={formValues?.isSecondParentReceiveOneHalfSupport}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isSecondParentReceiveOneHalfSupport: value,
              })
            }}
            YesTitle='I have a second parent who receives one-half support from me'
            NoTitle='I do not have a second parent who receives one-half support from me'
            error={errors?.isSecondParentReceiveOneHalfSupport}
          />

          {formValues?.isSecondParentReceiveOneHalfSupport && (
            <>
              <Box>
                <Typography className={Style.label}>
                  We understand. Please share some details about this parent.
                </Typography>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <CommonDropdown
                    name='suffix'
                    value={formValues?.secondParent?.suffix}
                    handleChange={({ name, value }) => {
                      setFormValues({
                        ...formValues,
                        secondParent: {
                          ...formValues.secondParent,
                          [name]: value,
                        },
                      })
                    }}
                    menulist={[
                      { name: 'Dr.', value: 'Dr.' },
                      { name: 'Ms.', value: 'Ms.' },
                      { name: 'Mrs.', value: 'Mrs.' },
                      { name: 'Mr.', value: 'Mr.' },
                    ]}
                    label='Suffix'
                    error={errors?.suffix2}
                  />

                  <Name
                    name='firstName'
                    value={formValues?.secondParent?.firstName}
                    title='First Name'
                    handleChange={handleSecondParentChange}
                    error={errors.firstName2}
                  />
                  <Name
                    name='middleName'
                    value={formValues?.secondParent?.middleName}
                    title='Middle Name'
                    handleChange={handleSecondParentChange}
                    error={errors.middleName2}
                  />
                  <Name
                    name='lastName'
                    value={formValues?.secondParent?.lastName}
                    title='Last Name'
                    handleChange={handleSecondParentChange}
                    error={errors.lastName2}
                  />
                </Grid>

                <Address
                  errors={errors}
                  realValues={formValues}
                  formValues={formValues?.secondParent}
                  handleFieldChange={(data) => {
                    handleSecondAddressChange(data)
                  }}
                  whichParent='secondParent'
                  setFormValues={setFormValues}
                />
              </Box>
            </>
          )}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Perants
