/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Input,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Select,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Filter from 'src/Assets/Icons/Filter.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import SortICon from 'src/Assets/Icons/squareIcon.svg'
import Plus from 'src/Assets/Icons/Plus.svg'
import Search from 'src/Assets/Icons/Search.svg'
import AddEmployee from './AddEmployee/index'
import { EmployeesList } from 'src/Components/Employees/EmployeeList'
import { employeeType } from 'src/Redux/Services/Types/employeeType'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from 'src/Pages/Employees/Admin/Manage/index.module.scss'
import { getOrganizationEmployees } from 'src/Redux/Slices/orgSlice'
import { DynamicObj, FW_BOLD } from 'src/Utils/Constants'
import { EmployeeListRequest } from 'src/Redux/Slices/employeeSlice'
import { ViewRoleRequest } from 'src/Redux/Slices/roleSlice'

const Employees: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [openAdd, setOpenAdd] = useState(false)
  const { selectedOrganization } = useAppSelector((state) => state.organization)

  const orgEmployees = useAppSelector(
    (state) => state.organization.orgEmployees,
  )
  const [employeeList, setEmployeeList] = useState(orgEmployees)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const ActionOpen = Boolean(anchorEl)
  const [q, setQ] = React.useState('')
  const rolelist = useAppSelector((state) => state.roles?.roleList?.data)

  const [filter, setFilter] = React.useState<{
    scope?: string
    roleId?: string
    orgnizationId?: string
    sort?: string
  }>({})
  //   const employeeData = useAppSelector((state) => state.employee?.data)
  useEffect(() => {
    dispatch(ViewRoleRequest())
    if (selectedOrganization?.uuid) {
      setFilter({
        ...filter,
        organizationId: selectedOrganization?.uuid ?? '',
      })
      dispatch(
        getOrganizationEmployees({
          ...filter,
          organizationId: selectedOrganization?.uuid,
        }),
      )
    }
  }, [])

  useEffect(() => {
    setEmployeeList(orgEmployees)
  }, [orgEmployees])

  const handleClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleAddEmployee = () => {
    setOpenAdd(true)
  }

  function find(items: []) {
    return items?.filter((item: DynamicObj) => {
      return [
        'firstName',
        'jobTitle',
        'phoneNumber',
        'emailId',
        'jobType',
      ].some((newItem: string) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }
  const handleFormChange = (event: SelectChangeEvent<string | number>) => {
    event.stopPropagation()
    const { name, value } = event.target
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleFilter = (e: { stopPropagation: () => void }, reset?: string) => {
    handleClose(e)
    if (reset === 'reset') dispatch(getOrganizationEmployees({}))
    else dispatch(getOrganizationEmployees(filter))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          {/* <Box>
            <Typography className={Style.HeadText}>Manage Employee</Typography>
            <Box display='flex'>
              <Typography className={Style.SubText}>Home</Typography>
              <img src={IconChevronRight} alt='IconChevronRight' />
              <Typography className={Style.SubText}>Employees</Typography>
            </Box>
          </Box> */}
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Typography variant='h1' fontWeight={FW_BOLD}>
                Employees
              </Typography>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search employee'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
            <Box className={Style.Right}>
              <Box className={Style.Box1}>
                <Box className={Style.Main2}>
                  <img src={SortICon} alt='sort' />
                  <Typography className={Style.MidText}>Sort</Typography>
                </Box>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </Box>
              <Box className={Style.Box1}>
                <Box
                  className={Style.Main2}
                  onClick={(e) => {
                    handleClick(
                      e as unknown as React.MouseEvent<
                        HTMLButtonElement,
                        MouseEvent
                      >,
                    )
                  }}
                  aria-controls={ActionOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={ActionOpen ? 'true' : undefined}
                >
                  <img src={Filter} alt='Filter' />
                  <Typography className={Style.MidText}>Filter</Typography>
                </Box>
                <Menu
                  className={Style.filterMenu}
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={ActionOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className={Style.titleWrapper}>
                    <Typography className={Style.textFieldWrapper}>
                      Filter Option
                    </Typography>
                  </MenuItem>
                  <MenuItem
                  // onClick={(event) => {
                  //   event.stopPropagation()
                  //   // handleDetails(employeeData.uuid, false)
                  // }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>
                        Scope:
                      </Typography>
                      <Select
                        className={Style.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='scope'
                        value={filter?.scope ?? 0}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        <MenuItem value={0}>Select Scope</MenuItem>
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                        <MenuItem value={'Legal'}>Legal</MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem
                  // onClick={(event) => {
                  //   event.stopPropagation()
                  //   // handleDetails(employeeData.uuid, true)
                  // }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>Role:</Typography>
                      {rolelist?.length && (
                        <Select
                          className={Style.inField}
                          IconComponent={(props) => (
                            <IconButton
                              {...props}
                              style={{ padding: '4px 10px' }}
                            >
                              <img
                                src={IconChevronDown}
                                alt='IconChevronDown'
                              />
                            </IconButton>
                          )}
                          size='small'
                          name='roleId'
                          value={filter?.roleId ?? 0}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={handleFormChange}
                        >
                          <MenuItem value={0}>Select Role</MenuItem>
                          {rolelist?.length &&
                            rolelist?.map(
                              (role: { uuid: string; name: string }) => (
                                <MenuItem key={role?.uuid} value={role?.uuid}>
                                  {role?.name}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                      )}
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>
                        Sorting By:
                      </Typography>
                      <Select
                        className={Style.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='sort'
                        value={filter?.sort ?? 0}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        <MenuItem value={0} selected>
                          Select Option
                        </MenuItem>
                        <MenuItem value='ASC'>Ascending</MenuItem>
                        <MenuItem value='DESC'>Descending</MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation()
                      handleClose(event)
                    }}
                  >
                    <Box className={Style.fullWidth}>
                      <Typography className={Style.titleText}>
                        Organization:
                      </Typography>
                      <Select
                        disabled={true}
                        className={Style.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='organizationId'
                        value={selectedOrganization?.uuid}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        <MenuItem value={0}>Select Organization</MenuItem>

                        <MenuItem
                          key={selectedOrganization?.uuid}
                          value={selectedOrganization?.uuid}
                        >
                          {selectedOrganization?.name}
                        </MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box className={Style.Buttons}>
                      <Box
                        className={Style.Add2}
                        onClick={(e) => {
                          setFilter({})
                          handleFilter(e, 'reset')
                        }}
                      >
                        Reset
                      </Box>
                      <Button
                        className={Style.Add}
                        variant='contained'
                        onClick={handleFilter}
                      >
                        Apply
                      </Button>
                    </Box>
                  </MenuItem>
                </Menu>
                {/* <img src={IconChevronDown} alt='IconChevronDown' /> */}
              </Box>
              <Button
                className={Style.Add}
                variant='contained'
                onClick={handleAddEmployee}
              >
                <img src={Plus} alt='Plus' style={{ marginRight: '8px' }} />
                Add Employee
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid className={Style.ListWrapper}>
          <Grid className={Style.ListGridMain}>
            <Grid className={Style.ListGrid}>
              {employeeList?.length
                ? find(employeeList)?.map((emp: employeeType, i: number) => {
                    return (
                      <Box key={i} className={Style.List}>
                        <EmployeesList employeeData={emp} showActions={false} />
                      </Box>
                    )
                  })
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {openAdd && <AddEmployee admin open={openAdd} setOpen={setOpenAdd} />}
    </>
  )
}

export default Employees
