import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import {
  EditProfileOrganizationRequest,
  ViewProfileRequest,
} from 'src/Redux/Slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'

interface FormType {
  name: string
  description: string
  streetAddress: string
  city: string
  state: string
  postalCode: string
}

const Profile: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewProfileRequest())
  }, [])

  const [disableButton, setdisableButton] = useState<Boolean>(true)

  const viewProfile = useAppSelector((state) => state.profile.data)

  const [formValues, setFormValues] = useState<FormType>({
    name: viewProfile?.organizationName ?? '',
    description: viewProfile?.organizationdescription ?? '',
    streetAddress: viewProfile?.organizationAddress?.streetAddress ?? '',
    city: viewProfile?.organizationAddress?.city ?? '',
    state: viewProfile?.organizationAddress?.state ?? '',
    postalCode: viewProfile?.organizationAddress?.postalCode ?? '',
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  useEffect(() => {
    if (viewProfile) {
      setFormValues({
        name: viewProfile?.organizationName ?? '',
        description: viewProfile?.organizationdescription ?? '',
        streetAddress: viewProfile?.organizationAddress?.streetAddress ?? '',
        city: viewProfile?.organizationAddress?.city ?? '',
        state: viewProfile?.organizationAddress?.state ?? '',
        postalCode: viewProfile?.organizationAddress?.postalCode ?? '',
      })
    }
  }, [viewProfile])

  const onDiscard = () => {
    setdisableButton(true)
    setFormValues({
      name: viewProfile?.organizationName ?? '',
      description: viewProfile?.organizationdescription ?? '',
      streetAddress: viewProfile?.organizationAddress?.streetAddress ?? '',
      city: viewProfile?.organizationAddress?.city ?? '',
      state: viewProfile?.organizationAddress?.state ?? '',
      postalCode: viewProfile?.organizationAddress?.postalCode ?? '',
    })
  }

  const handleFieldChange = (
    e: React.ChangeEvent<
      | HTMLInputElement
      | HTMLTextAreaElement
      | { name?: string | undefined; value: unknown }
    >,
  ) => {
    setdisableButton(false)
    const { name, value } = e.target
    setFormValues((prevState: FormType) => ({
      ...prevState,
      [name!]: value,
    }))
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.name.trim()) {
      newErrors.name = 'Organization name is required'
      valid = false
    }

    if (!formValues?.streetAddress.trim()) {
      newErrors.streetAddress = 'Street Address is required'
      valid = false
    }

    if (!formValues?.postalCode.trim()) {
      newErrors.postalCode = 'Postal Code is required'
      valid = false
    } else if (!/^\d{6}$/.test(formValues?.postalCode.trim())) {
      newErrors.postalCode = 'Postal Code must be 6 digits'
      valid = false
    }

    if (!formValues?.state.trim()) {
      newErrors.state = 'State is required'
      valid = false
    }

    if (!formValues?.city.trim()) {
      newErrors.city = 'City is required'
      valid = false
    }

    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validateForm()) {
      setdisableButton(true)
      dispatch(EditProfileOrganizationRequest(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewProfileRequest())
        },
      )
    }
  }

  return (
    <>
      <Box className={Style.Main}>
        <Grid container direction='column' className={Style.EditContact}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <Grid item className={Style.Form}>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Organization name
                  </Typography>
                </Grid>
                <Grid xs={12} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='name'
                    name='name'
                    className={Style.AnsTxt}
                    placeholder='Organization name'
                    value={formValues.name}
                    onChange={handleFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Street Address
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='streetAddress'
                    name='streetAddress'
                    placeholder='Street Address'
                    className={Style.AnsTxt}
                    onChange={handleFieldChange}
                    error={!!errors.streetAddress}
                    helperText={errors.streetAddress}
                    value={formValues?.streetAddress}
                  />
                </Grid>
              </Box>
              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Zip Code
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='number'
                    id='postalCode'
                    name='postalCode'
                    placeholder='Zip Code'
                    inputProps={{ maxLength: 10 }}
                    className={Style.AnsTxt}
                    value={formValues?.postalCode}
                    onChange={handleFieldChange}
                    error={!!errors.postalCode}
                    helperText={errors.postalCode}
                  />
                </Grid>
              </Box>

              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>City</Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='city'
                    name='city'
                    placeholder='City'
                    className={Style.AnsTxt}
                    onChange={handleFieldChange}
                    error={!!errors.city}
                    helperText={errors.city}
                    value={formValues?.city}
                  />
                </Grid>
              </Box>

              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    State
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='state'
                    name='state'
                    placeholder='State'
                    className={Style.AnsTxt}
                    onChange={handleFieldChange}
                    error={!!errors.state}
                    helperText={errors.state}
                    value={formValues?.state}
                  />
                </Grid>
              </Box>

              <Box component='div' className={Style.Element}>
                <Grid xs={4} component='div' className={Style.Question}>
                  <Typography className={Style.EditContactDiv}>
                    Organization Description
                  </Typography>
                </Grid>
                <Grid xs={8} component='div' className={Style.Ans}>
                  <TextField
                    fullWidth
                    required
                    type='text'
                    id='description'
                    name='description'
                    multiline
                    rows={4}
                    placeholder='Organization Description'
                    value={formValues?.description}
                    onChange={handleFieldChange}
                    className={Style.AnsTxt}
                    error={!!errors.organizationdescription}
                    helperText={errors.organizationdescription}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item className={Style.Action}>
              <Button
                variant='contained'
                className={Style.ActionButton}
                onClick={onDiscard}
                disabled={disableButton}
              >
                <Typography className={Style.TextWrapper4}>Discard</Typography>
              </Button>
              <Button
                type='submit'
                variant='contained'
                className={Style.DivWrapper}
                disabled={disableButton}
              >
                <Typography className={Style.TextWrapper4}>
                  Save Changes
                </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  )
}

export default Profile
