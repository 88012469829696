import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, InputLabel } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  MultiSelectDropDown,
  Name,
} from 'src/Components/Fields'
import { DisabilitySettlementsType } from 'src/Redux/Services/Types/applicationType'
import { AddPayoutsSettlements } from 'src/Redux/Slices/ApplicationSlices/DisabilityDetailsSlice'
import { setPayoutsSettlements } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const PayoutsSettlements = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm?: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const PayoutsSettlementsState = useAppSelector(
    (state) => state?.applicationStore?.DisabilityDetails?.PayoutsSettlements,
  )

  const [formValues, setFormValues] = useState<DisabilitySettlementsType>({
    disabilityId,
    haveReceiveMoneyAfterDisability: undefined,
    typeOfPays: [],
    otherPayExplain: '',
    approxAmountExpected: '',
    isExpectMoneyInFuture: undefined,
    typeOfFuturePay: [],
    futureOtherPayExplain: '',
    approxAmountFuturePayout: '',
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...PayoutsSettlementsState, disabilityId })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            haveReceiveMoneyAfterDisability:
              formValues?.haveReceiveMoneyAfterDisability === undefined
                ? 'Boolean'
                : formValues?.haveReceiveMoneyAfterDisability,
            isExpectMoneyInFuture:
              formValues?.isExpectMoneyInFuture === undefined
                ? 'Boolean'
                : formValues?.isExpectMoneyInFuture,
          },
        }),
      )
  }, [isBotOpen, disabilityId])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  // }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.haveReceiveMoneyAfterDisability === undefined) {
      newErrors.haveReceiveMoneyAfterDisability = 'please select option'
      valid = false
    }
    if (formValues?.isExpectMoneyInFuture === undefined) {
      newErrors.isExpectMoneyInFuture = 'please select option'
      valid = false
    }

    if (formValues?.haveReceiveMoneyAfterDisability) {
      if (formValues.typeOfPays.length === 0) {
        newErrors.typeOfPays = 'please select type of pays'
        valid = false
      }
      if (!formValues.otherPayExplain.trim()) {
        newErrors.otherPayExplain = 'please enter description'
        valid = false
      }
      if (parseInt(formValues.approxAmountExpected) <= 0) {
        newErrors.approxAmountExpected = 'please enter amount'
        valid = false
      }
    }

    if (formValues?.isExpectMoneyInFuture) {
      if (formValues.typeOfFuturePay.length === 0) {
        newErrors.typeOfFuturePay = 'please select type of pays'
        valid = false
      }
      if (!formValues.futureOtherPayExplain.trim()) {
        newErrors.futureOtherPayExplain = 'please enter description'
        valid = false
      }
      if (parseInt(formValues.approxAmountFuturePayout) <= 0) {
        newErrors.approxAmountFuturePayout = 'please enter amount'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.disabilityId = disabilityId
    formValuesToSubmit.haveReceiveMoneyAfterDisability =
      formValues.haveReceiveMoneyAfterDisability
    formValuesToSubmit.isExpectMoneyInFuture = formValues.isExpectMoneyInFuture
    if (formValues?.haveReceiveMoneyAfterDisability) {
      formValuesToSubmit.typeOfPays = formValues.typeOfPays
      formValuesToSubmit.otherPayExplain = formValues.otherPayExplain
      formValuesToSubmit.approxAmountExpected =
        formValues.approxAmountExpected.toString()
    }
    if (formValues?.isExpectMoneyInFuture) {
      formValuesToSubmit.typeOfFuturePay = formValues.typeOfFuturePay
      formValuesToSubmit.futureOtherPayExplain =
        formValues.futureOtherPayExplain
      formValuesToSubmit.approxAmountFuturePayout =
        formValues.approxAmountFuturePayout.toString()
    }
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setPayoutsSettlements(formValues))
      dispatch(AddPayoutsSettlements(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            // handleNext()
            navigate(ROUTES.EDUCATION_DETAILS)
          }
        },
      )
    }
  }

  const selectOptions = [
    { value: 'Unemployment', name: 'Unemployment' },
    { value: 'Pension', name: 'Pension' },
    { value: 'Sick/Vacation Pay', name: 'Sick/Vacation Pay' },
    { value: 'Short-Term Disability', name: 'Short-Term Disability' },
    { value: 'Long-Term Disability', name: 'Long-Term Disability' },
    {
      value: 'Income from Rental Properties',
      name: 'Income from Rental Properties',
    },
    { value: 'Income from Farmland', name: 'Income from Farmland' },
    { value: 'Child Support', name: 'Child Support' },
    { value: 'Earnings from Employer', name: 'Earnings from Employer' },
    {
      value: 'Earnings from Self-Employment',
      name: 'Earnings from Self-Employment',
    },
    { value: 'Workers Compensation', name: 'Workers Compensation' },
    { value: 'Other', name: 'Other' },
  ]

  const selectTypeOfFuturePays = [
    { value: 'Unemployment', name: 'Unemployment' },
    { value: 'Pension', name: 'Pension' },
    { value: 'Sick/Vacation Pay', name: 'Sick/Vacation Pay' },
    { value: 'Short-Term Disability', name: 'Short-Term Disability' },
    { value: 'Long-Term Disability', name: 'Long-Term Disability' },
    {
      value: 'Income from Rental Properties',
      name: 'Income from Rental Properties',
    },
    { value: 'Income from Farmland', name: 'Income from Farmland' },
    { value: 'Child Support', name: 'Child Support' },
    { value: 'Earnings from Employer', name: 'Earnings from Employer' },
    {
      value: 'Earnings from Self-Employment',
      name: 'Earnings from Self-Employment',
    },
    { value: 'Other', name: 'Other' },
  ]

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="Good work! The next topic is employee-based payouts. This helps us
              understand if you've had any settlements with past employers."
          />

          <CommonRadioBox
            error={errors?.haveReceiveMoneyAfterDisability}
            title='Have you received money from your employer on or after the date that you became unable to work?'
            commonState={formValues?.haveReceiveMoneyAfterDisability}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                haveReceiveMoneyAfterDisability: value,
              })
            }}
            YesTitle='I have received money from my employer on or after the date that I became unable to work'
            NoTitle='I have not received money from my employer on or after the date that I became unable to work'
          />

          {formValues.haveReceiveMoneyAfterDisability ? (
            <>
              <InputLabel className={Style.label}>
                What type of income are you receiving?
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <MultiSelectDropDown
                name='typeOfPays'
                label='Type of Pay Expected (check all that apply)'
                value={formValues?.typeOfPays}
                handleSelectChange={(data: string[]) => {
                  setFormValues({
                    ...formValues,
                    typeOfPays: data,
                  })
                }}
                menulist={selectOptions}
                error={errors?.typeOfPays}
              />
              {formValues?.typeOfPays.includes('Other') && (
                <Name
                  name='otherPayExplain'
                  title='If you have other types of pay expected, please explain.'
                  value={formValues?.otherPayExplain}
                  error={errors?.otherPayExplain}
                  handleChange={(data: { value: string | number }) => {
                    setFormValues({
                      ...formValues,
                      otherPayExplain: data.value as string,
                    })
                  }}
                  multiline
                />
              )}

              <Name
                name='approxAmountExpected'
                title='Approximate Amount Expected From Your Employer (in US dollars).'
                value={formValues?.approxAmountExpected}
                error={errors?.approxAmountExpected}
                handleChange={(data: { value: string | number }) => {
                  setFormValues({
                    ...formValues,
                    approxAmountExpected: data.value as string,
                  })
                }}
                number
                preInput='$'
              />
            </>
          ) : (
            <></>
          )}

          <Box>
            <CommonRadioBox
              error={errors?.isExpectMoneyInFuture}
              title='Do you expect to receive any money from your employer in the future?'
              commonState={formValues?.isExpectMoneyInFuture}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, isExpectMoneyInFuture: value })
              }}
              YesTitle='I expect to receive money from my employer in the future'
              NoTitle='I do not expect to receive any money from my employer in the future'
            />
          </Box>

          {formValues.isExpectMoneyInFuture ? (
            <>
              <InputLabel className={Style.label}>
                Got it. Please provide some details.
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>

              <MultiSelectDropDown
                name='typeOfFuturePay'
                label='Type of Pay Expected (check all that apply)'
                value={formValues?.typeOfFuturePay}
                handleSelectChange={(data: string[]) => {
                  setFormValues({
                    ...formValues,
                    typeOfFuturePay: data,
                  })
                }}
                menulist={selectTypeOfFuturePays}
                error={errors?.typeOfFuturePay}
              />

              {formValues?.typeOfFuturePay.includes('Other') && (
                <Name
                  name='futureOtherPayExplain'
                  title='If you have other types of pay expected, please explain.'
                  value={formValues?.futureOtherPayExplain}
                  error={errors?.futureOtherPayExplain}
                  handleChange={(data: { value: string | number }) => {
                    setFormValues({
                      ...formValues,
                      futureOtherPayExplain: data.value as string,
                    })
                  }}
                  multiline
                />
              )}
              <Name
                name='approxAmountFuturePayout'
                title='Approximate Amount Expected From Your Employer (in US dollars).'
                value={formValues?.approxAmountFuturePayout}
                error={errors?.approxAmountFuturePayout}
                handleChange={(data: { value: string | number }) => {
                  setFormValues({
                    ...formValues,
                    approxAmountFuturePayout: data.value as string,
                  })
                }}
                number
                preInput='$'
              />
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default PayoutsSettlements
