import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider, styled } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import EmployerDetails from '../EditForms/EmployerDetails'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'
const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)

  const ErrorImage = styled('img')({
    marginRight: '8px', // Adjust margin as needed
    marginBottom: '-8px', // Adjust margin as needed
    paddingRight: '10px',
    paddingLeft: '10px',
    // position: 'absolute',
  })
  const educationRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    educationRef?.current?.scrollIntoView()
  }

  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.educationFlagData
        ?.EmployerDetails,
  )

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.EmployerDetails,
  )

  const employerDetailsSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Employer Details',
      )?.sectionId,
  )

  return (
    <div id={employerDetailsSectionId} ref={educationRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Employer Details
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Typography variant='h6' className={Styles.SubTitle}>
                    Current and Past Employer
                  </Typography>
                </Box>
                {data?.employerDetail?.map(
                  (
                    item: {
                      companyName:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                      typeOfBusiness:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                      address: {
                        streetAddress:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined
                        city:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined
                        state:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined
                        postalCode:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined
                        country:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined
                      }
                      startDate: string | number | Date
                      endDate: string | number | Date
                      isphysicallyWorkedLastDate: any
                      lastPhysicallyWorkedDate: string | number | Date
                      jobTitle:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                      averageHourPerDay:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                      averageDaysPerWeek:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                      payAmountPerPayPeriod: any
                      payFrequency:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined
                    },
                    index: number,
                  ) => (
                    <>
                      <Box className={Styles.card}>
                        <Box className={Styles.cardDiv}>
                          <Grid className={Styles.cardFrame}>
                            <Grid className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company Name
                                </Typography>
                              </Box>
                              <Box className={Styles.cardDivWrapper}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.companyName
                                      ?.length > 0
                                      ? Styles.flagCardTextWrapper2
                                      : Styles.cardTextWrapper2
                                  }
                                >
                                  {item?.companyName}
                                  {/* <ErrorImage src={error} alt='Error' /> */}

                                  {flagData?.employerDetail[index]?.companyName
                                    ?.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .companyName
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Type Of Business
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]
                                      ?.typeOfBusiness?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.typeOfBusiness}
                                  {flagData?.employerDetail[index]
                                    ?.typeOfBusiness.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .typeOfBusiness
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company Street Address
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.address
                                      ?.streetAddress?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.address?.streetAddress}
                                  {flagData?.employerDetail[index]?.address
                                    ?.streetAddress.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .address?.streetAddress
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company City/town
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.address
                                      ?.city?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.address?.city}

                                  {flagData?.employerDetail[index]?.address
                                    ?.city.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .address?.city
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company State
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.address
                                      ?.state?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.address?.state}

                                  {flagData?.employerDetail[index]?.address
                                    ?.state.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .address?.state
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company Zip Code
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.address
                                      ?.postalCode?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.address?.postalCode}
                                  {flagData?.employerDetail[index]?.address
                                    ?.postalCode.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .address?.postalCode
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Company Country
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.address
                                      ?.country?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.address?.country}
                                  {flagData?.employerDetail[index]?.address
                                    ?.country.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .address?.country
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <div className={Styles.dottedLine} />
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Employment Start Date
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.startDate
                                      ?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {convertISOToDate(item?.startDate)}
                                  {flagData?.employerDetail[index]?.startDate
                                    .length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .startDate
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Employment End Date
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.endDate
                                      ?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.endDate?.toString().length !== 0
                                    ? convertISOToDate(item?.endDate)
                                    : 'Presently Working'}
                                  {flagData?.employerDetail[index]?.endDate
                                    .length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .endDate
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            {item?.isphysicallyWorkedLastDate ? null : (
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Last Physically worked Date
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.employerDetail[index]
                                        ?.lastPhysicallyWorkedDate?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {item?.lastPhysicallyWorkedDate?.toString()
                                      .length !== 0
                                      ? convertISOToDate(
                                          item?.lastPhysicallyWorkedDate,
                                        )
                                      : 'Presently Working'}
                                    {flagData?.employerDetail[index]
                                      ?.lastPhysicallyWorkedDate.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.employerDetail[index]
                                              .lastPhysicallyWorkedDate
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                          <div className={Styles.dottedLine} />
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Job Title
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]?.jobTitle
                                      ?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.jobTitle}
                                  {flagData?.employerDetail[index]?.jobTitle
                                    .length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .jobTitle
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Average Hours Per Day Worked
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]
                                      ?.averageHourPerDay?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.averageHourPerDay}
                                  {flagData?.employerDetail[index]
                                    ?.averageHourPerDay.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .averageHourPerDay
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Average Days Per Week Worked
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]
                                      ?.averageDaysPerWeek?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.averageDaysPerWeek}
                                  {flagData?.employerDetail[index]
                                    ?.averageDaysPerWeek.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .averageDaysPerWeek
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Pay Amount Per Pay Period (US dollars)
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]
                                      ?.payAmountPerPayPeriod?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {`$${item?.payAmountPerPayPeriod}`}
                                  {flagData?.employerDetail[index]
                                    ?.payAmountPerPayPeriod.length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .payAmountPerPayPeriod
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Pay Frequency
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={
                                    flagData?.employerDetail[index]
                                      ?.payFrequency?.length > 0
                                      ? Styles.flagCardTextWrapper3
                                      : Styles.cardTextWrapper3
                                  }
                                >
                                  {item?.payFrequency}
                                  {flagData?.employerDetail[index]?.payFrequency
                                    .length > 0 ? (
                                    <>
                                      <Tooltip
                                        title={
                                          flagData?.employerDetail[index]
                                            .payFrequency
                                        }
                                      >
                                        <ErrorImage src={error} alt='Error' />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={4} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                ></Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper3}
                                ></Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {/* Add other elements similarly */}
                        </Box>
                      </Box>
                    </>
                  ),
                )}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Employer Details
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <EmployerDetails handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
