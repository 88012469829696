import { InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import remove from 'src/Assets/Icons/remove.svg'
import { Calander } from 'src/Components/Fields'
import { PassApplicationType } from 'src/Redux/Services/Types/applicationType'
import Address from '../AddressDetails'
import Style from './index.module.scss'

interface medicalProfessionalsType {
  organizationName: string
  address: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  phoneNumber: string
  contactPersonFirstName: string
  contactPersonLastName: string
  arrivedDate: string
  leftDate: string
}

const OrganizationDetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
  fieldName,
}: {
  formValues: medicalProfessionalsType
  realValue: PassApplicationType
  setFormValues: React.Dispatch<React.SetStateAction<PassApplicationType>>
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
  fieldName: string
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Organization name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='organizationName'
              name='organizationName'
              placeholder='Organization name'
              className={Style.AnsTxt}
              value={formValues?.organizationName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.organizationName}
              helperText={errors?.organizationName}
            />
          </Grid>
        </Grid>

        <Address
          realValues={realValue}
          formValues={formValues}
          handleFieldChange={changeHandler}
          index={index}
          fieldName={fieldName}
          setFormValues={setFormValues}
          errors={errors}
        />

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Contact person's first name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='contactPersonFirstName'
              name='contactPersonFirstName'
              placeholder='Contact person name'
              className={Style.AnsTxt}
              value={formValues?.contactPersonFirstName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.contactPersonFirstName}
              helperText={errors?.contactPersonFirstName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Contact person's last name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='contactPersonLastName'
              name='contactPersonLastName'
              placeholder='Contact person name'
              className={Style.AnsTxt}
              value={formValues?.contactPersonLastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.contactPersonLastName}
              helperText={errors?.contactPersonLastName}
            />
          </Grid>
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Phone number<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              id='phoneNumber'
              name='phoneNumber'
              placeholder='Phone number'
              inputProps={{ maxLength: 10 }}
              className={Style.AnsTxt}
              value={formValues?.phoneNumber}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }
              }}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber}
            />
          </Grid>
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated date arrived or first contact at facility
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.arrivedDate
                    ? dayjs(formValues?.arrivedDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'arrivedDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='arrivedDate'
                    name='arrivedDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
          <Calander
            title={'Estimated date arrived or first contact at facility'}
            value={formValues.arrivedDate}
            name={'arrivedDate'}
            error={errors?.arrivedDate}
            handleChange={changeHandler}
          />

          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated date left or last contact at facility
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.leftDate
                    ? dayjs(formValues?.leftDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'leftDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='leftDate'
                    name='leftDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
          <Calander
            minDate={formValues?.arrivedDate}
            title={'Estimated date left or last contact at facility'}
            value={formValues.leftDate}
            name={'leftDate'}
            error={errors?.leftDate}
            handleChange={changeHandler}
          />
        </Grid>
      </Box>
    </>
  )
}
export default OrganizationDetailBox
