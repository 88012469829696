import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox } from 'src/Components/Fields'
import {
  AddLegalNames,
  EditLegalNames,
} from 'src/Redux/Slices/ApplicationSlices/LegalDetailsSlice'
import { setLegalId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setLegalName } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import ProfileBox from './ProfileBox'
import Style from './index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
interface legalNamesType {
  legalId?: string
  applicationId: string
  haveUsedOtherName?: boolean
  legalNames: Array<{
    firstName: string
    middleName: string
    lastName: string
  }>
}

const LegalNames = ({
  // setLegalId,
  handleNext,
  handleBack,
  chatForm,
}: {
  // legalId?: string
  // setLegalId: (value: string) => void
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const legalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.legalId,
  )
  const LegalNameState = useAppSelector(
    (state) => state?.applicationStore?.LegalDetails?.LegalName,
  )

  const [formValues, setFormValues] = useState<legalNamesType>({
    applicationId,
    haveUsedOtherName: undefined,
    legalNames: [
      {
        firstName: '',
        middleName: '',
        lastName: '',
      },
    ],
  })

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      applicationId,
      legalNames: formValues.legalNames,
    }))
  }, [applicationId, formValues.legalNames])

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...LegalNameState,
    })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            haveUsedOtherName: formValues.haveUsedOtherName
              ? formValues.haveUsedOtherName
              : 'Boolean',
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const validateForm = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}
    if (formValues?.haveUsedOtherName === undefined) {
      newErrors.haveUsedOtherName = 'Please select an option'
      isValid = false
    }
    formValues.legalNames.forEach((legalName, index) => {
      const { firstName, middleName, lastName } = legalName

      const validationRules = [
        {
          field: `firstName[${index}]`,
          label: 'First Name',
          value: firstName.trim(),
        },
        {
          field: `middleName[${index}]`,
          label: 'Middle Name',
          value: middleName.trim(),
        },
        {
          field: `lastName[${index}]`,
          label: 'Last Name',
          value: lastName.trim(),
        },
      ]

      if (formValues?.haveUsedOtherName) {
        validationRules.forEach((rule) => {
          if (!rule.value) {
            newErrors[rule.field] = `${rule.label} is required`
            isValid = false
          } else if (!/^[A-Za-z ]+$/.test(rule.value)) {
            newErrors[rule.field] = `${rule.label} must contain only letters`
            isValid = false
          } else {
            // Update error message to an empty string if value is valid
            newErrors[rule.field] = ''
          }
        })
      }
    })

    setErrors(newErrors)
    return isValid
  }

  const handleValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms: Array<{
      firstName: string
      middleName: string
      lastName: string
    }> = [...formValues.legalNames]
    newProfileForms = newProfileForms.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({ ...formValues, legalNames: newProfileForms })

    setErrors({})
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.legalNames]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, legalNames: newProfileForms })
  }

  const addProfileBox = () => {
    setFormValues({
      ...formValues,
      legalNames: [
        ...formValues.legalNames,
        {
          firstName: '',
          middleName: '',
          lastName: '',
        },
      ],
    })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Conditionally construct the object to be passed based on haveUsedOtherName value
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.applicationId = formValues.applicationId
    formValuesToSubmit.haveUsedOtherName = formValues.haveUsedOtherName
    if (formValues.haveUsedOtherName) {
      formValuesToSubmit.legalNames = formValues.legalNames
    }
    // const formValuesToSubmit =
    //   formValues.haveUsedOtherName === true
    //     ? formValues
    //     : {
    //         applicationId: formValues.applicationId,
    //         legalNames: formValues.legalNames,
    //       }

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setLegalName(formValues))
      if (!legalId) {
        dispatch(AddLegalNames(formValuesToSubmit)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              dispatch(setLegalId(data?.legalId))
              handleNext()
            }
          },
        )
      } else {
        dispatch(EditLegalNames({ ...formValuesToSubmit, legalId })).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              handleNext()
            }
          },
        )
      }
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="Hi there! In this section, we'll work through questions related to
              names, social security numbers, and bank information. This
              information helps us best understand past background information
              and where disability benefits payments should be deposited once
              approved."
          />

          <CommonRadioBox
            title='Have you used any other names? Examples may include maiden name(s) or past name adjustments'
            commonState={formValues.haveUsedOtherName}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                haveUsedOtherName: value,
              })
            }}
            YesTitle='I have used other names'
            NoTitle='I have not used any other names'
          />

          {formValues?.haveUsedOtherName ? (
            <>
              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  Please provide these names:
                </Typography>

                {formValues?.legalNames?.map((form, index) => (
                  <ProfileBox
                    errors={errors}
                    key={index}
                    handleRemove={() => {
                      handleRemove(index)
                    }}
                    index={index}
                    formValues={form}
                    changeHandler={(data: { name: string; value: string }) => {
                      handleValuesChange(index, data)
                    }}
                  />
                ))}

                <AddButton
                  title='Provide more information for each of  Legal Names'
                  subTitle='For comprehensive representation of your situation, It is important that all
                      service be shown below'
                  button='Add Names'
                  onClick={addProfileBox}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              sx={{ visibility: 'hidden' }}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default LegalNames
