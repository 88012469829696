import { Icons } from 'src/Utils/Constants'

// Define header and footer templates
const HeaderFooter = ({
  applicationId,
  clintName,
  pageNum,
  totalPages,
  representative,
}: {
  applicationId: string
  clintName: any
  pageNum: number
  totalPages: number
  representative: any
}) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // marginTop: '10px',
        }}
      >
        <div
          style={{
            width: '90%',
            borderBottom: '4px solid #c7c7c7',
            padding: '10px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <img
              style={{ width: '250px', height: '72px' }}
              src={Icons.headerLogo}
              alt='header'
            />
          </div>
          <div
            className='header-info'
            style={{
              textAlign: 'left',
              color: '#190482',
              fontSize: '16px',
              lineHeight: '10px',
            }}
          >
            <p>
              Client’s Name: {clintName.firstName + ' ' + clintName.lastName}
            </p>
            <p>
              Representative Name:{' '}
              {representative.firstName + ' ' + representative.lastName}
            </p>
            <p>Date: {new Date().toLocaleDateString()}</p>
            <p>UID or Re-entry Number: {applicationId}</p>
          </div>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            right: '10px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <div
            style={{
              padding: '10px 0px 0px 0px',
              fontSize: '16px',
              color: '#190482',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textWrap: 'balance',
              width: '95%',
            }}
          >
            This document contains sensitive, confidential, and proprietary
            information intended exclusively for the use of authorized legal
            professionals in connection with the preparation and submission of
            Social Security disability benefits applications to the Social
            Security Administration (SSA). The contents herein are strictly
            confidential and shall not be disclosed, distributed, reproduced, or
            used for any purpose other than that expressly related to the SSA
            application process. Unauthorized use, dissemination, or
            reproduction of this document, in whole or in part, is prohibited.
            By accessing or using this document, the recipient acknowledges and
            agrees to maintain the confidentiality of all personally
            identifiable information (PII) and to comply with all applicable
            laws, regulations, and ethical obligations governing the protection
            of such information.
          </div>
          <div
            style={{
              textAlign: 'right',
              padding: '9px 0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              width: '95%',
            }}
          >
            {pageNum}/{totalPages}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderFooter
