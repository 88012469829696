import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import Delete from 'src/Assets/Icons/delete.svg'
import Edit from 'src/Assets/Icons/edit.svg'
import { employeeType } from 'src/Redux/Services/Types/employeeType'
import {
  AddEmployeeRequest,
  EmployeeListRequest,
  OrganizationListRequest,
} from 'src/Redux/Slices/employeeSlice'
import { ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { IOSSwitch } from 'src/Theme'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { ViewTotalEmployee } from 'src/Redux/Slices/DashboardSlice'
import { ROUTES } from 'src/Utils/Routes'
import { useLocation } from 'react-router-dom'

const AddEmployee: React.FunctionComponent<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  admin?: boolean
  // handleOpen: () => void
}> = ({ open, setOpen, admin }) => {
  //  , handleOpen
  const dispatch = useAppDispatch()
  const rolelist = useAppSelector((state) => state.roles?.roleList?.data)
  const profileData = useAppSelector((state) => state.profile?.data)
  const organizationList = useAppSelector(
    (state) => state.employee?.organizationList?.data,
  )
  const [selectedFile, setSelectedFile] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [formValues, setFormValues] = useState<Record<string, string>>({})
  const [errors, setErrors] = useState<Record<string, string>>()

  const handleClose = () => {
    setOpen(false)
  }

  const location = useLocation()
  // Function to handle file selection
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (file) {
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  // Function to handle removing the selected photo
  const handleRemovePhoto = () => {
    setSelectedFile('')
    setPreviewUrl('')
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues?.firstName?.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    }
    if (!formValues?.lastName?.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    }
    if (!formValues?.emailId?.trim()) {
      newErrors.emailId = 'Email Id is required'
      valid = false
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formValues?.emailId?.trim())) {
      newErrors.emailId = 'Invalid Email Id'
      valid = false
    }
    if (!formValues?.phoneNumber?.trim()) {
      newErrors.phoneNumber = 'Phone number is required'
      valid = false
    }
    const phoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    if (!phoneNumberRegex.test(formValues?.phoneNumber?.trim())) {
      newErrors.phoneNumber = 'Invalid Phone number'
      valid = false
    }
    if (!formValues?.roleUUID?.trim()) {
      newErrors.roleUUID = 'Role Name is required'
      valid = false
    }
    if (!formValues?.jobTitle) {
      newErrors.jobTitle = 'Job Title is required'
      valid = false
    }
    if (!formValues?.jobType) {
      newErrors.jobType = 'Job Type is required'
      valid = false
    }

    // Validation logic for Profile Photo
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      newErrors.selectedFile = 'Please select a file under 5MB in size'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }
  const handleFormChange = (event: {
    target: { name: string; value: string }
  }) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (validateForm()) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('firstName', formValues?.firstName)
      formData.append('lastName', formValues?.lastName)
      formData.append('emailId', formValues?.emailId)
      formData.append('phoneNumber', formValues?.phoneNumber)
      formData.append('roleId', formValues?.roleUUID)
      admin && formData.append('organizationId', formValues?.organizationUUID)
      !admin && formData.append('organizationId', profileData?.organizationUUID)
      formData.append('jobTitle', formValues?.jobTitle)
      formData.append('jobType', formValues?.jobType)
      formData.append('isActive', `${isActive}`)
      dispatch(AddEmployeeRequest(formData as unknown as employeeType)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res.payload?.success) {
            setOpen(false)
            if (ROUTES.DASHBOARD === location.pathname) {
              dispatch(ViewTotalEmployee())
            }
          }
          dispatch(EmployeeListRequest({}))
        },
      )
    }
  }

  useEffect(() => {
    if (admin) dispatch(OrganizationListRequest())
    dispatch(ViewRoleRequest())
  }, [])

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.topTitleText}>
                Add Employee
                <img onClick={handleClose} src={Icons.Cancel} alt='Cancel' />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent className={Style.DialogContentBox}>
            <Box component='div'>
              <Typography className={Style.textFieldWrapper}>Image</Typography>
              <Box component='div' className={Style.EditContactImage}>
                <Box component='div'>
                  <Box component='div'>
                    <Box component='div' className={Style.IconEditWrapper}>
                      <input
                        onChange={handleFileSelect}
                        id='profile-photo-upload'
                        type='file'
                        accept='image/jpeg, image/png'
                        style={{ cursor: 'pointer' }}
                        className={Style.inputFile}
                      />
                      <img
                        src={Edit}
                        className={Style.EditIcon}
                        alt='Rectangle'
                      />
                    </Box>
                    <Avatar
                      sx={{
                        height: '160px',
                        width: '160px',
                        mt: '13px',
                        borderRadius: '15px',
                      }}
                      src={previewUrl}
                      alt='photoURL'
                    />
                    {previewUrl ? (
                      <Box component='div' className={Style.IconDeleteWrapper}>
                        <img
                          src={Delete}
                          onClick={handleRemovePhoto}
                          alt='Rectangle'
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
                <Box component='div' className={Style.Description}>
                  <Typography className={Style.DescriptionP}>
                    Allowed file types: png, jpg, jpeg.
                  </Typography>
                  <Typography sx={{ color: 'red', fontSize: '16px' }}>
                    {errors?.selectedFile}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={Style.flexSpaceBox}>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  First Name
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={formValues?.firstName}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='firstName'
                  error={!!errors?.firstName}
                  helperText={errors?.firstName}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Last Name
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={formValues?.lastName}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='lastName'
                  error={!!errors?.lastName}
                  helperText={errors?.lastName}
                />
              </Box>
            </Box>
            <Box className={Style.flexSpaceBox}>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Employee Phone number
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={
                    formValues?.phoneNumber
                    // ? `(${formValues?.phoneNumber.slice(0, 3)}) ${formValues?.phoneNumber.slice(3, 6)}-${formValues?.phoneNumber.slice(6)}`
                    // : '-'
                  }
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 10 &&
                      /^\d*$/.test(e.target.value)
                    )
                      handleFormChange(e)
                  }}
                  name='phoneNumber'
                  error={!!errors?.phoneNumber}
                  helperText={errors?.phoneNumber}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  E- mail
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={formValues?.emailId}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='emailId'
                  error={!!errors?.emailId}
                  helperText={errors?.emailId}
                />
              </Box>
            </Box>
            <Box className={Style.toggleWrapper}>
              <Box className={Style.toggleText}>
                <Typography className={Style.textFieldWrapper}>
                  Active
                </Typography>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={isActive}
                      onChange={() => {
                        setIsActive(!isActive)
                      }}
                    />
                  }
                  label=''
                />
              </Box>
            </Box>
            <div className={Style.dottedLine} />
            <Box component='div' className={Style.titleText}>
              Job Details
            </Box>
            <Box className={Style.flexSpaceBox}>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Job Title
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={formValues?.jobTitle}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='jobTitle'
                  error={!!errors?.jobTitle}
                  helperText={errors?.jobTitle}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Job Type
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <Select
                  className={Style.inField}
                  IconComponent={(props) => (
                    <IconButton {...props} style={{ padding: '4px 10px' }}>
                      <img src={IconChevronDown} alt='IconChevronDown' />
                    </IconButton>
                  )}
                  defaultValue={formValues?.jobType ?? ''}
                  size='small'
                  name='jobType'
                  value={formValues?.jobType ?? ''}
                  error={!!errors?.jobType}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                >
                  <MenuItem value={'Part-Time'}>Part-Time</MenuItem>
                  <MenuItem value={'Permanent'}>Permanent</MenuItem>
                </Select>
                {!!errors?.jobType && (
                  <FormHelperText error>{errors?.jobType}</FormHelperText>
                )}
              </Box>
            </Box>
            <div className={Style.dottedLine} />
            <Box component='div' className={Style.titleText}>
              Role & Permission
            </Box>
            <Box className={Style.flexSpaceBox}>
              <Box className={Style.halfWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Role Name
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                {rolelist?.length && (
                  <Select
                    className={Style.inField}
                    IconComponent={(props) => (
                      <IconButton {...props} style={{ padding: '4px 10px' }}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    defaultValue={formValues?.roleUUID ?? ''}
                    size='small'
                    name='roleUUID'
                    value={formValues?.roleUUID ?? ''}
                    error={!!errors?.roleUUID}
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                  >
                    <MenuItem value={''}>Select Role</MenuItem>
                    {rolelist?.length &&
                      rolelist
                        ?.filter(
                          (value: { isDefault: boolean }) => !value?.isDefault,
                        )
                        ?.map((role: { uuid: string; name: string }) => (
                          <MenuItem key={role?.uuid} value={role?.uuid}>
                            {role?.name}
                          </MenuItem>
                        ))}
                  </Select>
                )}
                {!!errors?.roleUUID && (
                  <FormHelperText error>{errors?.roleUUID}</FormHelperText>
                )}
              </Box>
            </Box>
            <Typography className={Style.textFieldWrapper}>
              Assigned Permission to this Role
            </Typography>
            <Box display={'flex'} flexWrap={'wrap'} gap={1}>
              {rolelist?.length &&
                rolelist
                  ?.find(
                    (i: { uuid: string }) => i.uuid === formValues?.roleUUID,
                  )
                  ?.permission?.map((i: string, index: number) => {
                    return (
                      <Box key={index} className={Style.smallGrayBox}>
                        {i}
                      </Box>
                    )
                  })}
            </Box>
            {admin && (
              <>
                <div className={Style.dottedLine} />
                <Box component='div' className={Style.titleText}>
                  Organization Details
                </Box>
                <Box className={Style.flexSpaceBox}>
                  <Box className={Style.fullWidth}>
                    <Typography className={Style.textFieldWrapper}>
                      Organization name
                      <Typography className={Style.FF002E}>*</Typography>
                    </Typography>
                    <Select
                      className={Style.inField}
                      IconComponent={(props) => (
                        <IconButton {...props} style={{ padding: '4px 10px' }}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      defaultValue={formValues?.organizationUUID ?? ''}
                      size='small'
                      name='organizationUUID'
                      value={formValues?.organizationUUID ?? ''}
                      error={!!errors?.organizationUUID}
                      onChange={(e) => {
                        handleFormChange(e)
                      }}
                    >
                      <MenuItem value={''}>Select Organization</MenuItem>
                      {organizationList?.length &&
                        organizationList?.map(
                          (organization: { uuid: string; name: string }) => (
                            <MenuItem
                              key={organization?.uuid}
                              value={organization?.uuid}
                            >
                              {organization?.name}
                            </MenuItem>
                          ),
                        )}
                    </Select>
                    {!!errors?.jobType && (
                      <FormHelperText error>{errors?.jobType}</FormHelperText>
                    )}
                  </Box>
                </Box>
              </>
            )}
            <Grid item className={Style.Action}>
              <Button
                onClick={handleSubmit}
                variant='contained'
                className={Style.DivWrapper}
              >
                <Typography className={Style.TextWrapper4}>Add</Typography>
              </Button>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default AddEmployee
