import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { DisabilityEmploymentType } from 'src/Redux/Services/Types/applicationType'
import { AddDisabilityEmployment } from 'src/Redux/Slices/ApplicationSlices/DisabilityDetailsSlice'
import { setDisabilityEmployment } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import {
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { currentYear, nextYear, previousYear } from 'src/Utils/Constants'
import { ApplicationIntroBox, StarText } from 'src/Components/Fields'

interface OptionsType {
  value: string
  label: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
})

const DisabilityEmployment = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )

  const DisabilityEmploymentState = useAppSelector(
    (state) => state?.applicationStore?.DisabilityDetails?.DisabilityEmployment,
  )

  const [formValues, setFormValues] = useState<DisabilityEmploymentType>({
    disabilityId,
    intendToWorkNextYear: undefined, // aa tanay ma aavse
    affectingEmploymentDetail: '', // 1st aa tanay ma )**
    employedDuringLast2YearDetail: [], // 2nd
    reasonToStoppedWorking: '', // 3rd
    explainStoppedWorking: '', // 4rd, jo 3rd filed ma other hase to j 4th aavse
    dateOfStoppedWorking: '', // 5th date
    isRequireWorkChanges: undefined, // 6th
    workChangeStartDate: '', // 7th jo 6th yes hoi toj batavani
    haveMadeMoreMoneyInAnyMonth: undefined, // 8th aa defalut
    disabilityKeepFromWorkingDate: '', // if i have never work aa akj filed 3rd scenario ma(1st and new add) **
  })

  useEffect(() => {
    if (formValues?.haveMadeMoreMoneyInAnyMonth !== undefined) {
      dispatch(
        setDisqualification({
          haveMadeMoreMoneyInAnyMonth: formValues?.haveMadeMoreMoneyInAnyMonth,
        }),
      )
      dispatch(setIsDisqualified(formValues?.haveMadeMoreMoneyInAnyMonth))
    }
  }, [formValues?.haveMadeMoreMoneyInAnyMonth])

  useEffect(() => {
    setFormValues({ ...DisabilityEmploymentState, disabilityId })
  }, [])
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // useEffect(() => {
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     wereBornInUSA: formValues.wereBornInUSA,
  //   }))
  // }, [formValues.wereBornInUSA])
  const NotNever =
    formValues.affectingEmploymentDetail !== 'I have never worked'
  const bothWorking =
    formValues.affectingEmploymentDetail === 'I am currently working' ||
    formValues.affectingEmploymentDetail === 'I have stopped working'
  const Stop = formValues.affectingEmploymentDetail === 'I have stopped working'
  const otherReasons =
    formValues.reasonToStoppedWorking === 'Because of other reasons'
  const injeryRadio = formValues.isRequireWorkChanges === true

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.intendToWorkNextYear === undefined) {
      newErrors.intendToWorkNextYear = 'please select option'
      valid = false
    }

    if (NotNever) {
      if (formValues?.isRequireWorkChanges === undefined) {
        newErrors.isRequireWorkChanges = 'please select option'
        valid = false
      }
    }

    if (!formValues.affectingEmploymentDetail.trim()) {
      newErrors.affectingEmploymentDetail = 'please select option'
      valid = false
    }

    if (NotNever) {
      if (formValues.employedDuringLast2YearDetail.length === 0) {
        newErrors.employedDuringLast2YearDetail = 'please select option'
        valid = false
      }
    }

    if (injeryRadio) {
      if (formValues?.haveMadeMoreMoneyInAnyMonth === undefined) {
        newErrors.haveMadeMoreMoneyInAnyMonth = 'please select option'
        valid = false
      }

      if (formValues.workChangeStartDate === '') {
        newErrors.workChangeStartDate = 'please select date'
        valid = false
      }

      if (formValues?.workChangeStartDate > currentDate) {
        newErrors.workChangeStartDate = 'Please select valid Date'
        valid = false
      }
    }

    if (Stop) {
      if (!formValues.reasonToStoppedWorking.trim()) {
        newErrors.reasonToStoppedWorking = 'please select option'
        valid = false
      }

      if (formValues.dateOfStoppedWorking === '') {
        newErrors.dateOfStoppedWorking = 'please select date'
        valid = false
      }
      if (formValues?.dateOfStoppedWorking > currentDate) {
        newErrors.dateOfStoppedWorking = 'Please select valid Date'
        valid = false
      }
    }

    if (!NotNever) {
      if (formValues.disabilityKeepFromWorkingDate === '') {
        newErrors.disabilityKeepFromWorkingDate = 'please select date'
        valid = false
      }
      if (formValues?.disabilityKeepFromWorkingDate > currentDate) {
        newErrors.disabilityKeepFromWorkingDate = 'Please select valid Date'
        valid = false
      }
    }

    if (otherReasons) {
      if (!formValues.explainStoppedWorking.trim()) {
        newErrors.explainStoppedWorking = 'please select option'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.disabilityId = disabilityId
    formValuesToSubmit.intendToWorkNextYear = formValues.intendToWorkNextYear
    if (
      formValues.intendToWorkNextYear === false &&
      !formValues.intendToWorkNextYear
    ) {
      formValuesToSubmit.disabilityId = disabilityId
      formValuesToSubmit.intendToWorkNextYear = formValues.intendToWorkNextYear
    }
    // if (formValues.intendToWorkNextYear) {
    //   formValuesToSubmit.affectingEmploymentDetail =
    //     formValues.affectingEmploymentDetail
    if (bothWorking) {
      formValuesToSubmit.isRequireWorkChanges = formValues.isRequireWorkChanges
      formValuesToSubmit.haveMadeMoreMoneyInAnyMonth =
        formValues.haveMadeMoreMoneyInAnyMonth
      formValuesToSubmit.employedDuringLast2YearDetail =
        formValues.employedDuringLast2YearDetail
      formValuesToSubmit.workChangeStartDate = formValues.workChangeStartDate
    }
    // }
    if (!NotNever) {
      formValuesToSubmit.intendToWorkNextYear = formValues.intendToWorkNextYear
      formValuesToSubmit.affectingEmploymentDetail =
        formValues.affectingEmploymentDetail
      formValuesToSubmit.disabilityKeepFromWorkingDate =
        formValues.disabilityKeepFromWorkingDate
      if (formValues.affectingEmploymentDetail === 'I am currently working') {
        formValuesToSubmit.workChangeStartDate = formValues.workChangeStartDate
      }
    }
    if (Stop) {
      formValuesToSubmit.affectingEmploymentDetail =
        formValues.affectingEmploymentDetail
      formValuesToSubmit.employedDuringLast2YearDetail =
        formValues.employedDuringLast2YearDetail
      formValuesToSubmit.reasonToStoppedWorking =
        formValues.reasonToStoppedWorking
      if (formValues.reasonToStoppedWorking === 'Because of other reasons') {
        formValuesToSubmit.explainStoppedWorking =
          formValues.explainStoppedWorking
      }
      formValuesToSubmit.dateOfStoppedWorking = formValues.dateOfStoppedWorking
      if (formValues.affectingEmploymentDetail === 'I have stopped working') {
        formValuesToSubmit.workChangeStartDate = formValues.workChangeStartDate
      }
    }

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(AddDisabilityEmployment(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setDisabilityEmployment(formValues))

            handleEditToggle()
          }
        },
      )
    }
  }

  // const handleSelectChange = (name: string) => {
  //   setFormValues({
  //     ...formValues,
  //     citizenshipCountryName: [...formValues.citizenshipCountryName, name],
  //   })
  // }

  const handleSelectChange = (name: string, type: string) => {
    // Check if the item is already selected
    const typeOfPays = formValues[type].includes(name)

    if (typeOfPays) {
      // Remove the item from the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: prevValues[type].filter((item: string) => item !== name),
      }))
    } else {
      // Add the item to the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: [...prevValues[type], name],
      }))
    }
  }

  const selectOptions = [
    {
      value: `I was employed for any part of ${currentYear}`,
      label: `I was employed for any part of ${currentYear}`,
    },
    {
      value: `I was employed for any part of ${previousYear}`,
      label: `I was employed for any part of ${previousYear}`,
    },
  ]

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading='In this section, tell us about your previous(or current) employment.' />
          <Box>
            <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
              <Grid width={'100%'}>
                <InputLabel className={Style.label} htmlFor='BillingName'>
                  Now, let's dig into how your disability is affecting your
                  employment. Please select an answer.
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <StyledSelect
                  onChange={(e) => {
                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>)
                  }}
                  className={Style.AnsTxt}
                  IconComponent={(props) => (
                    <IconButton {...props}>
                      <img src={IconChevronDown} alt='IconChevronDown' />
                    </IconButton>
                  )}
                  renderValue={
                    formValues.affectingEmploymentDetail !== ''
                      ? () => formValues.affectingEmploymentDetail
                      : () => 'Select'
                  }
                  value={formValues.affectingEmploymentDetail}
                  fullWidth
                  placeholder='Select'
                  id='affectingEmploymentDetail'
                  name='affectingEmploymentDetail'
                  error={!!errors.affectingEmploymentDetail}
                >
                  <MenuItem value=''>
                    <em>Select an Answer</em>
                  </MenuItem>
                  <MenuItem value='I am currently working'>
                    I am currently working
                  </MenuItem>
                  <MenuItem value='I have stopped working'>
                    I have stopped working
                  </MenuItem>
                  <MenuItem value='I have never worked'>
                    I have never worked
                  </MenuItem>
                </StyledSelect>
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors.affectingEmploymentDetail}
                </FormHelperText>
              </Grid>
            </Grid>
          </Box>

          {!NotNever ? (
            <>
              <Box width={'100%'} mt={3}>
                <InputLabel className={Style.label}>
                  When do you believe that your conditions became severe enough
                  to prevent you from working?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    value={
                      formValues.disabilityKeepFromWorkingDate
                        ? dayjs(formValues.disabilityKeepFromWorkingDate)
                        : null
                    } // Convert to Dayjs object
                    onChange={(date) => {
                      setFormValues({
                        ...formValues,
                        disabilityKeepFromWorkingDate:
                          date?.format('YYYY-MM-DD'),
                      })
                    }}
                    maxDate={dayjs()} // Use Dayjs for maxDate as well
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        style={{ width: '100%' }}
                        id='disabilityKeepFromWorkingDate'
                        name='disabilityKeepFromWorkingDate'
                        placeholder='mm-DD-YYYY'
                        className={Style.AnsTxt}
                        InputProps={{
                          inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors.disabilityKeepFromWorkingDate}
                </FormHelperText>
              </Box>
              {/* <Box sx={{ width: '100%' }} height={'10px'} /> */}
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'flex-start'}
                sx={{
                  p: 2,
                  // my: 4,
                  backgroundColor: '#ECF6FF',
                  borderRadius: '10px',
                }}
              >
                <img src={star} className={Style.star} alt='' />
                <Typography>
                  <i>
                    Please do not count sick leave, vacation, or disability pay.
                  </i>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Help us understand when you were employed during the last
                      two years. Check all that apply.
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <StyledSelect
                        id='employedDuringLast2YearDetail'
                        name='employedDuringLast2YearDetail'
                        // multiple
                        className={Style.AnsTxt}
                        value={formValues?.employedDuringLast2YearDetail}
                        renderValue={() => (
                          <Box display='flex' flexWrap='wrap' gap={1}>
                            {formValues?.employedDuringLast2YearDetail?.map(
                              (value, index) => (
                                <Chip
                                  key={index}
                                  className={Style.ChipItem}
                                  label={value}
                                  onMouseDown={(event) => {
                                    event.stopPropagation()
                                  }}
                                  onDelete={(e) => {
                                    e.stopPropagation()
                                    handleSelectChange(
                                      value,
                                      'employedDuringLast2YearDetail',
                                    )
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      employedDuringLast2YearDetail:
                                        prevState.employedDuringLast2YearDetail.filter(
                                          (item) => item !== value,
                                        ),
                                    }))
                                  }}
                                  deleteIcon={
                                    <CloseIcon
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleSelectChange(
                                          value,
                                          'employedDuringLast2YearDetail',
                                        )
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          employedDuringLast2YearDetail:
                                            prevState.employedDuringLast2YearDetail.filter(
                                              (item) => item !== value,
                                            ),
                                        }))
                                      }}
                                    />
                                  }
                                />
                              ),
                            )}
                          </Box>
                        )}
                      >
                        {selectOptions?.map(
                          (item: OptionsType, index: number) => (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                handleSelectChange(
                                  item.value,
                                  'employedDuringLast2YearDetail',
                                )
                              }}
                              value={item.value}
                            >
                              {item.value}
                            </MenuItem>
                          ),
                        )}
                      </StyledSelect>
                      <FormHelperText sx={{ mx: 1.5 }}>
                        {errors.employedDuringLast2YearDetail}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              {formValues.affectingEmploymentDetail ===
              'I have stopped working' ? (
                <>
                  <Box my={'30px'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Why did you stop working?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                      <Grid width={'100%'}>
                        <InputLabel
                          className={Style.label}
                          htmlFor='BillingName'
                        >
                          I stopped working:
                          <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <StyledSelect
                          onChange={(e) => {
                            handleFieldChange(
                              e as React.ChangeEvent<HTMLInputElement>,
                            )
                          }}
                          className={Style.AnsTxt}
                          IconComponent={(props) => (
                            <IconButton {...props}>
                              <img
                                src={IconChevronDown}
                                alt='IconChevronDown'
                              />
                            </IconButton>
                          )}
                          renderValue={
                            formValues.reasonToStoppedWorking !== ''
                              ? () => formValues.reasonToStoppedWorking
                              : () => 'Select'
                          }
                          value={formValues.reasonToStoppedWorking}
                          fullWidth
                          placeholder='Select'
                          id='reasonToStoppedWorking'
                          name='reasonToStoppedWorking'
                          error={!!errors.reasonToStoppedWorking}
                        >
                          <MenuItem value=''>
                            <em>Select an Answer</em>
                          </MenuItem>
                          <MenuItem value='Due to my condition(s)'>
                            Due to my condition(s)
                          </MenuItem>
                          <MenuItem value='Because of other reasons'>
                            Because of other reasons
                          </MenuItem>
                        </StyledSelect>
                        <FormHelperText sx={{ mx: 1.5 }}>
                          {errors.reasonToStoppedWorking}
                        </FormHelperText>
                      </Grid>
                    </Grid>

                    {formValues.reasonToStoppedWorking ===
                    'Because of other reasons' ? (
                      <>
                        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                          <Grid width={'100%'}>
                            <InputLabel className={Style.label}>
                              Please explain why you stopped working
                              <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <TextField
                              fullWidth
                              type='text'
                              id='explainStoppedWorking'
                              name='explainStoppedWorking'
                              multiline
                              rows={4}
                              className={Style.AnsTxt}
                              placeholder='Other'
                              value={formValues?.explainStoppedWorking}
                              onChange={handleFieldChange}
                              error={!!errors.explainStoppedWorking}
                              helperText={errors.explainStoppedWorking}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>

                  <Box width={'100%'}>
                    <Box sx={{ width: '100%' }} height={'20px'} />

                    <InputLabel className={Style.label}>
                      Got it! When did your condition(s) become severe enough to
                      keep you from working?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        value={
                          formValues.dateOfStoppedWorking
                            ? dayjs(formValues.dateOfStoppedWorking)
                            : null
                        } // Convert to Dayjs object
                        onChange={(date) => {
                          setFormValues({
                            ...formValues,
                            dateOfStoppedWorking: date?.format('YYYY-MM-DD'),
                          })
                        }}
                        maxDate={dayjs()} // Use Dayjs for maxDate as well
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            id='dateOfStoppedWorking'
                            name='dateOfStoppedWorking'
                            placeholder='mm-DD-YYYY'
                            className={Style.AnsTxt}
                            InputProps={{
                              inputProps: {
                                max: dayjs().format('YYYY-MM-DD'),
                              }, // Use Dayjs to format the max date
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.dateOfStoppedWorking}
                    </FormHelperText>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {/* Common radio */}
              <Box>
                <Box sx={{ width: '100%' }} height={'20px'} />
                <Box
                  width={'100%'}
                  display={'flex'}
                  alignItems={'flex-start'}
                  sx={{
                    p: 2,
                    // my: 4,
                    backgroundColor: '#ECF6FF',
                    borderRadius: '10px',
                  }}
                >
                  <img src={star} className={Style.star} alt='' />
                  <Typography>
                    <i>
                      We're trying to understand if you've had to adjust your
                      work responsibilities while you were working due to your
                      situation.
                    </i>
                  </Typography>
                </Box>
                <CommonRadioBox
                  title='Did your condition, injury, or illness require changes to your work activity while you were working?'
                  commonState={formValues?.isRequireWorkChanges}
                  setcommonState={(value: boolean) => {
                    setFormValues({
                      ...formValues,
                      isRequireWorkChanges: value,
                    })
                  }}
                  YesTitle='My condition, injury, or illness did require changes to my work activity while I was working'
                  NoTitle='My condition, injury, or illness did not require changes to my work activity while I was working'
                />
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors?.isRequireWorkChanges}
                </FormHelperText>
              </Box>

              {formValues?.isRequireWorkChanges && (
                <>
                  <StarText
                    text={
                      'Got it. We’re sorry to hear that adjustments were necessary.'
                    }
                  />
                  <Box width={'100%'} mt={3}>
                    <InputLabel className={Style.label}>
                      When did these changes begin?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        value={
                          formValues.workChangeStartDate
                            ? dayjs(formValues.workChangeStartDate)
                            : null
                        } // Convert to Dayjs object
                        onChange={(date) => {
                          setFormValues({
                            ...formValues,
                            workChangeStartDate: date?.format('YYYY-MM-DD'),
                          })
                        }}
                        maxDate={dayjs()} // Use Dayjs for maxDate as well
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            id='workChangeStartDate'
                            name='workChangeStartDate'
                            placeholder='mm-DD-YYYY'
                            className={Style.AnsTxt}
                            InputProps={{
                              inputProps: {
                                max: dayjs().format('YYYY-MM-DD'),
                              }, // Use Dayjs to format the max date
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.workChangeStartDate}
                    </FormHelperText>
                  </Box>
                </>
              )}
            </>
          )}

          {bothWorking ? (
            <>
              <Box>
                <Box sx={{ width: '100%' }} height={'20px'} />
                <CommonRadioBox
                  title={`Have you made more than $1,470 during any month in ${currentYear} since the date(s) you provided above?* Please do not count sick leave, vacation, or disability pay.`}
                  commonState={formValues?.haveMadeMoreMoneyInAnyMonth}
                  setcommonState={(value: boolean) => {
                    setFormValues({
                      ...formValues,
                      haveMadeMoreMoneyInAnyMonth: value,
                    })
                  }}
                  YesTitle={`I have made more than $1,470 during some months in ${currentYear} since the date(s) I provided above`}
                  NoTitle={`I have not made more than $1,470 during some months in ${currentYear} since the date(s) I provided above`}
                />
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors?.haveMadeMoreMoneyInAnyMonth}
                </FormHelperText>
              </Box>
            </>
          ) : (
            <></>
          )}
          <Box>
            <Box sx={{ width: '100%' }} height={'20px'} />
            <CommonRadioBox
              title={`Do you intend to work in ${nextYear}?`}
              commonState={formValues?.intendToWorkNextYear}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, intendToWorkNextYear: value })
              }}
              YesTitle='Yes I am'
              NoTitle='No I am not'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.intendToWorkNextYear}
            </FormHelperText>
          </Box>

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              disabled={!!formValues?.haveMadeMoreMoneyInAnyMonth}
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default DisabilityEmployment
