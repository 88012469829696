import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import FilterIcon from 'src/Assets/Icons/Filter.svg'
import { useState, type FunctionComponent, useEffect } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
// import { useNavigate } from 'react-router-dom'
import PlusIcon from 'src/Assets/Icons/plusIcon.svg'
import SearchIcon from 'src/Assets/Icons/searchLg.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import { ROUTES } from 'src/Utils/Routes'
import styles from './index.module.scss'
import GenAppDialogue from '../../GenerateApplication'
import { MileStoneFour, Roles } from 'src/Utils/Constants'
import { getAllApplicationList } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  setQ: (label: string) => void
}

const Head: FunctionComponent<Props> = ({ setQ }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { filterParam } = location.state || {}
  const [openDialog, setOpenDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const ActionOpen = Boolean(anchorEl)
  const [filter, setFilter] = useState<{
    status?: string
    sort?: string
    assignee?: boolean
  }>({})

  useEffect(() => {
    setFilter(filterParam)
    dispatch(getAllApplicationList(filterParam))
  }, [filterParam])

  const { data } = useAppSelector(
    (state: RootState) => state?.applicationStore?.AllApplications,
  )
  const userdata = useAppSelector((state) => state.auth?.data)

  const calculatedValue =
    parseInt(userdata?.SubscrioptionData?.numberOfApplication) - data?.length ??
    0

  // Calculate the percentage

  const totalApplications =
    parseInt(userdata.SubscrioptionData?.numberOfApplication) ?? 0
  const usedApplications = data?.length ?? 0
  const unusedApplications = totalApplications - usedApplications ?? 0

  // Calculate the percentage of unused applications
  const unusedPercentage = (unusedApplications / totalApplications) * 100

  const actualPercentage = 100 - unusedPercentage
  const dataSize = data?.length
  const handleFormChange = (event: SelectChangeEvent<string | number>) => {
    event.stopPropagation()
    const { name, value } = event.target
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const dispatch = useAppDispatch()
  const handleFilter = (e: { stopPropagation: () => void }, reset?: string) => {
    handleClose(e)
    if (reset === 'reset') dispatch(getAllApplicationList({}))
    else dispatch(getAllApplicationList(filter))
  }

  return (
    <Grid className={styles.head}>
      <Grid container>
        <Grid item lg={6}>
          <Breadcrumb
            title='Manage Applications'
            home={{ name: 'Applications', route: ROUTES.APPLICATIONS }}
            Menus={[]}
          />
        </Grid>
        {userdata?.roleScope === Roles.LEGAL && (
          <>
            <Grid sx={{ marginLeft: 'auto' }}>
              <Grid item lg={12}>
                <Box
                  sx={{
                    borderRadius: '8px',
                    borderColor: 'rgba(241, 241, 244, 1)',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    '@media (max-width: 991px)': {
                      maxWidth: '100%',
                    },
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      gap: '100px',
                      fontSize: '12px',
                      lineHeight: '133%',
                      '@media (max-width: 991px)': {
                        maxWidth: '100%',
                        flexWrap: 'wrap',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#1a202c',

                        fontWeight: 600,
                      }}
                    >
                      {calculatedValue} Applications Remaining
                    </Typography>
                    <Typography
                      sx={{
                        color: '#6f737a',

                        fontWeight: 400,
                      }}
                    >
                      {data?.length} of{' '}
                      {userdata.SubscrioptionData?.numberOfApplication} Used
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: '30px',
                      backgroundColor: 'var(--Neutral-Gray-300, #f1f1f4)',
                      display: 'flex',
                      marginTop: '10px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      '@media (max-width: 991px)': {
                        maxWidth: '100%',
                      },
                    }}
                  >
                    <LinearProgress
                      variant='determinate'
                      value={actualPercentage}
                      sx={{
                        borderRadius: '30px',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#7752FE', // Change the background color as needed
                        },
                        height: '5px',
                        '@media (max-width: 991px)': {
                          maxWidth: '100%',
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid container>
        <Grid lg={8} container>
          <Grid mr={1} alignItems={'center'} display='flex'>
            <Typography
              variant='h1'
              sx={{ color: 'black', fontWeight: 'bolder', textWrap: 'nowrap' }}
            >
              Applications ({dataSize})
            </Typography>
          </Grid>
          <Grid lg={4}>
            <TextField
              className={styles.search}
              placeholder='Search application'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <img width='16px' height='16px' src={SearchIcon} />
                ),
              }}
              onChange={(e: { target: { value: string } }) => {
                setQ(e.target.value)
              }}
              sx={{
                '& fieldset': { borderColor: '#c8c9cc' },
                '& .MuiInputBase-root': {
                  height: '40px',
                  backgroundColor: '#f2f4f8',
                  paddingLeft: '15px',
                  borderRadius: '10px',
                },
                '& .MuiInputBase-input': {
                  paddingLeft: '6px',
                  color: '#9d9fa4',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid lg={4} container justifyContent={'flex-end'}>
          {/* <Grid xs={'auto'} mr={2}>
            <Button
              className={styles.add}
              startIcon={<img width='16px' height='16px' src={PlusIcon} />}
              disableElevation={true}
              // endIcon={<img width='16px' height='16px' src={IconChevronDown} />}
              variant='contained'
              sx={{
                textTransform: 'none',
                color: '#6f737a',
                fontSize: '16',
                background: '#f8f9fb',
                borderRadius: '10px',
                '&:hover': { background: '#f8f9fb' },
                width: 115,
              }}
            >
              Sort
            </Button>
          </Grid> */}
          {userdata?.roleScope === Roles.ADMIN && (
            <Grid xs={'auto'} mr={2}>
              <Box className={styles.Box1}>
                <Box
                  className={styles.Main2}
                  onClick={(e) => {
                    handleClick(
                      e as unknown as React.MouseEvent<
                        HTMLButtonElement,
                        MouseEvent
                      >,
                    )
                  }}
                  aria-controls={ActionOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={ActionOpen ? 'true' : undefined}
                >
                  <img src={FilterIcon} alt='Filter' />
                  <Typography className={styles.MidText}>Filter</Typography>
                </Box>
                <Menu
                  className={styles.filterMenu}
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={ActionOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className={styles.titleWrapper}>
                    <Typography className={styles.textFieldWrapper}>
                      Filter Option
                    </Typography>
                  </MenuItem>
                  <MenuItem
                  // onClick={(event) => {
                  //   event.stopPropagation()
                  //   // handleDetails(employeeData.uuid, false)
                  // }}
                  >
                    <Box className={styles.fullWidth}>
                      <Typography className={styles.titleText}>
                        Status:
                      </Typography>
                      <Select
                        className={styles.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='status'
                        value={filter?.status ?? 0}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        value={filter?.status ?? 0}
                        <MenuItem value={0} selected>
                          Select Status
                        </MenuItem>
                        <MenuItem value='Pending'>Pending</MenuItem>
                        <MenuItem value='Draft'>Draft</MenuItem>
                        <MenuItem value='In Progress'>In Progress</MenuItem>
                        <MenuItem value='Submitted'>Submitted</MenuItem>
                        <MenuItem value='Approved'>Approved</MenuItem>
                        <MenuItem value='Rejected'>Rejected</MenuItem>
                        <MenuItem value='Cancelled'>Cancelled</MenuItem>
                        <MenuItem value='Completed'>Completed</MenuItem>
                        <MenuItem value='Flagged'>Flagged</MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box className={styles.fullWidth}>
                      <Typography className={styles.titleText}>
                        Sorting By:
                      </Typography>
                      <Select
                        className={styles.inField}
                        IconComponent={(props) => (
                          <IconButton
                            {...props}
                            style={{ padding: '4px 10px' }}
                          >
                            <img src={IconChevronDown} alt='IconChevronDown' />
                          </IconButton>
                        )}
                        size='small'
                        name='sort'
                        value={filter?.sort ?? 0}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={handleFormChange}
                      >
                        <MenuItem value={0} selected>
                          Select Option
                        </MenuItem>
                        <MenuItem value='ASC'>Ascending</MenuItem>
                        <MenuItem value='DESC'>Descending</MenuItem>
                      </Select>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation()
                      handleClose(event)
                      // dispatch(
                      //   DeleteEmployeeRequest({
                      //     uuid: employeeData?.uuid,
                      //     data: { isActive: !employeeData?.isActive },
                      //   }),
                      // ).then(() => {
                      //   dispatch(EmployeeListRequest())
                      // })
                    }}
                  ></MenuItem>
                  <MenuItem>
                    <Box className={styles.Buttons}>
                      <Box
                        className={styles.Add2}
                        onClick={(e) => {
                          setFilter({})
                          handleFilter(e, 'reset')
                        }}
                      >
                        Reset
                      </Box>
                      <Button
                        className={styles.Add}
                        variant='contained'
                        onClick={handleFilter}
                      >
                        Apply
                      </Button>
                    </Box>
                  </MenuItem>
                </Menu>
                {/* <img src={IconChevronDown} alt='IconChevronDown' /> */}
              </Box>
            </Grid>
          )}

          <Box className={styles.AddButton}>
            {process.env.REACT_APP_MILESTONE_4 ? (
              <>
                <Button
                  variant='contained'
                  className={styles.ButtonTwo}
                  onClick={() => {
                    setOpenDialog(true)
                  }}
                >
                  Generate Application
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant='contained'
                  onClick={() => {
                    navigate(ROUTES.WELCOME_PAGE)
                  }}
                  startIcon={<img width='16px' height='16px' src={PlusIcon} />}
                  className={styles.ButtonTwo}
                >
                  Add Application
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      {openDialog && (
        <GenAppDialogue openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
    </Grid>
  )
}

export default Head
