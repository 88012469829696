import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApplicationIntroBox } from 'src/Components/Fields'
// import { UploadDocumentType } from 'src/Redux/Services/Types/uploadDocumentType'
// import {
//   AddUploadDocument,
//   EditUploadDocument,
//   GetUploadDocument,
// } from 'src/Redux/Slices/ApplicationSlices/uploadDocumentSlice'
import {
  AddUploadDocument,
  EditUploadDocument,
  GetUploadDocument,
} from 'src/Redux/Slices/ApplicationSlices/SignConsentFormsSlice'
import { setSignConsentId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import UploadField from './UploadField'
import Style from './index.module.scss'

const UploadDocuments = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()
  const titles = [
    'Upload SSA-1696  Or Other Representation Signed Consent Forms',
    'Upload Signed Medical Consent Forms',
    'Upload Any Other Relevant Signed Consent Forms',
  ]
  const signConsentId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.signConsentId,
  )

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<any>({
    ssa: [],
    medical: [],
    other: [],
  })

  const [getData, setGetData] = useState<{
    consentUrls?: Array<{
      url: string
      name: string
      isUpdated: boolean
    }>
  }>({})

  const [errors, setErrors] = useState<Record<string, string>>({
    representationSignedConsent: '',
  })

  const validate = () => {
    const newErrors: Record<string, string> = {}
    let valid = true
    if (
      formValues?.ssa.length === 0
      // ||
      // formValues?.medical.length === 0 ||
      // formValues?.other.length === 0
    ) {
      newErrors.representationSignedConsent = 'document is required'
      valid = false
    }
    setErrors(newErrors)
    return valid
  }

  useEffect(() => {
    const keys = Object.keys(formValues)
    const newData = { ...formValues }
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index] as keyof typeof formValues
      newData[key] =
        getData.consentUrls
          ?.filter((item) => item.name === key)
          .map((item) => {
            return item.url
          }) ?? []
    }
    setFormValues(newData)
  }, [getData])

  useEffect(() => {
    if (signConsentId)
      dispatch(GetUploadDocument(signConsentId)).then((res) => {
        if (res.payload?.success) {
          setGetData(res.payload?.data)
        }
      })
  }, [])

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const formData = new FormData()
    const urls = getData.consentUrls
    const notRemoved: typeof urls = []
    for (let index = 0; index < Object.keys(formValues).length; index++) {
      const element: any = Object.keys(formValues)[
        index
      ] as keyof typeof formValues
      for (let index = 0; index < formValues[element].length; index++) {
        typeof formValues[element][index] === 'string'
          ? notRemoved.push(
              urls?.find(
                (item) => item.url === formValues[element][index],
              ) as any,
            )
          : formData.append(element, formValues[element][index])
      }
    }

    const toBeRemoved = urls?.filter((item) => !notRemoved.includes(item))
    if (toBeRemoved?.length) {
      formData.append('removedDocuments', JSON.stringify(toBeRemoved))
    }
    if (validate()) {
      if (signConsentId) {
        formData.append('consentId', signConsentId)
        dispatch(EditUploadDocument(formData)).then((res) => {
          if (res.payload?.success) {
            handleEditToggle()
          }
        })
      } else {
        formData.append('applicationId', applicationId)
        dispatch(AddUploadDocument(formData)).then((res) => {
          if (res.payload?.success) {
            dispatch(setSignConsentId(res.payload?.data?.consentId))
            handleEditToggle()
          }
        })
      }
    }
    // setUpload([])
    // setUploadPreview([])
    // setMarriage([])
    // setMarriagePreview([])
  }

  return (
    <>
      <Box className={Style.ContactBox}>
        <Grid className={Style.ContactnGrid}>
          <Typography className={Style.ContactTitle}>
            Signed Consent Forms
          </Typography>
        </Grid>
      </Box>
      {process.env.REACT_APP_MILESTONE_4 ? (
        <Box className={Style.DialogContentBox}>
          <Box className={Style.GridBox}>
            <form className={Style.Form} onSubmit={handleSubmit}>
              <ApplicationIntroBox heading='Use this section to upload any signed consent forms or other supporting documents. These materials will be kept in the client’s file for easy access for submission.' />

              {/* <InputLabel className={Style.label}>
                Required Documents
              </InputLabel> */}
              {Object.keys(formValues).map((key, index) => {
                return (
                  <>
                    <UploadField
                      key={index}
                      title={titles[index]}
                      setUpload={(upload: Array<File | string>) => {
                        setFormValues({ ...formValues, [key]: upload })
                        setErrors({ ...errors, [key]: '' })
                      }}
                      upload={formValues[key as keyof typeof formValues]}
                      error={index ? '' : errors.representationSignedConsent}
                    />
                    {/* {index === 0 && (
                      <>
                        <Box width={'100%'} height={'20px'} />
                        <InputLabel className={Style.label}>
                          Additional Documents
                        </InputLabel>
                      </>
                    )} */}
                  </>
                )
              })}
              <Box className={Style.AddButton}>
                <Button
                  variant='contained'
                  className={Style.ButtonOne}
                  onClick={() => {
                    handleEditToggle()
                  }}
                >
                  Discard
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  className={Style.ButtonTwo}
                >
                  Save & Continue
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default UploadDocuments
